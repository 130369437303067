// import EmployeePersonalInfoForm from "@/components/Forms/EmployeePersonalInfoForm";
// import EmployeeRegistrationInfoForm from "@/components/Forms/EmployeeRegistrationInfoForm";
// import EmployeeRoleInfoForm from "@/components/Forms/EmployeeRoleInfoForm";
// import UploadDocumentModal from "@/components/Modals/UploadDocumentModal";
// import EmployeeDocuments from "@/components/Tables/EmployeeDocuments";
// import EmployeeNotes from "@/components/Tables/EmployeeNotes";
// import { useDispatch, useSelector } from "@/stores";
// import { employeeActions } from "@/stores/employee";
// import { IDocument, IEmployee } from "@/types/app";
// import { Button, Form, Space, Spin, Tabs, TabsProps } from "antd";
// import dayjs from "dayjs";
// import { useEffect, useState } from "react";
// import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
// import { LeftOutlined } from "@ant-design/icons";
// import EmployeeDetailTabs from "./EmployeeDetailTabs";
// import { ArrowLeftIcon } from "@/components/common/IconComponents";
// import EmployeeCreateTabs from "./EmployeeCreateTabs";
// import { ROUTE_PATH } from "@/common/constants/routes";

// const EmployeeUpdate = (): JSX.Element => {
//   const {
//     detail,
//     loading,
//     modalUploadDocumentVisibility,
//     uploadDocumentType,
//   } = useSelector((state) => state.employee);

//   const { id } = useParams();

//   const { countries } = useSelector((state) => state.app);
//   const { userInfo } = useSelector((state) => state.auth);

//   const [form] = Form.useForm();
//   const location = useLocation();

//   const search = new URLSearchParams(location?.search);
//   const defaultActiveTab = search?.get('tab') || '1';

//   const [activeKey, setActiveKey] = useState<number>(Number(defaultActiveTab) || 1);
//   const [showSaveBtn, setShowSaveBtn] = useState<boolean>(true);

//   useEffect(
//     function () {
//       dispatch(employeeActions.getEmployee({ id: id, role: userInfo?.role }));
//       dispatch(
//         employeeActions.getEmployeeTimesheets({
//           id: id,
//           role: userInfo?.role,
//         })
//       );
//       dispatch(
//         employeeActions.getEmployeePayslips({ id: id, role: userInfo?.role })
//       );
//       dispatch(
//         employeeActions.getEmployeeOthers({ id: id, role: userInfo?.role })
//       );
//       dispatch(
//         employeeActions.getEmployeeGuides({ id: id, role: userInfo?.role })
//       );
//     },
//     [id]
//   );


//   const handleSubmitDocument = (value: IDocument) => {
//     const newValues = { ...value, employeeID: id, id, role: userInfo?.role };
//     if (uploadDocumentType === "timesheet") {
//       dispatch(employeeActions.createTimesheetDocument(newValues as IDocument))
//         .unwrap()
//         .then(() => {
//           dispatch(employeeActions.onCloseModalUploadDocument());
//           dispatch(
//             employeeActions.getEmployeeTimesheets({ id, role: userInfo?.role })
//           );
//         });
//     } else if (uploadDocumentType === "payslip") {
//       dispatch(employeeActions.createPayslipDocument(newValues as IDocument))
//         .unwrap()
//         .then(() => {
//           dispatch(employeeActions.onCloseModalUploadDocument());
//           dispatch(
//             employeeActions.getEmployeePayslips({ id, role: userInfo?.role })
//           );
//         });
//     } else if (uploadDocumentType === "others") {
//       dispatch(employeeActions.createOthersDocument(newValues as IDocument))
//         .unwrap()
//         .then(() => {
//           dispatch(employeeActions.onCloseModalUploadDocument());
//           dispatch(
//             employeeActions.getEmployeeOthers({ id, role: userInfo?.role })
//           );
//         });
//     }
//   };

//   const dispatch = useDispatch();
//   const navigate = useNavigate();


//   const navigateBack = () => {
//     const pathNames = location?.pathname.split("/").filter((val) => val !== "");
//     navigate(`${ROUTE_PATH.EMPLOYEE_LIST}${id?"/"+id:''}` || "-1");
//   };

//   useEffect(() => {
//     if (activeKey > 3) setShowSaveBtn(false);
//     else setShowSaveBtn(true);
//   }, [activeKey]);


//   return (
//     <div className="container-fluid">
//       <Spin tip="Loading" size="large" spinning={loading[employeeActions.getEmployee.typePrefix]}>
//         <div className="d-flex justify-content-between align-items-center py-3">
//           <div className="d-flex justify-content-between align-items-center page-listing-title mb-0">
//             <ArrowLeftIcon className="mr-2" onClick={navigateBack} />
//             <span>Update Employee</span>
//           </div>
//           {showSaveBtn ? (
//             <div className="text-right">
//               <Button type="primary" htmlType="submit">
//                 Save
//               </Button>
//             </div>
//           ) : null}
//         </div>
//         <EmployeeCreateTabs form={form} activeKey={activeKey} setActiveKey={setActiveKey} />

//         {modalUploadDocumentVisibility && (
//           <UploadDocumentModal
//             open={modalUploadDocumentVisibility}
//             destroyOnClose={true}
//             onCancel={() => dispatch(employeeActions.onCloseModalUploadDocument())}
//             onSubmit={handleSubmitDocument}
//             confirmLoading={
//               loading[employeeActions?.createTimesheetDocument.typePrefix] ||
//               loading[employeeActions?.createPayslipDocument.typePrefix] ||
//               loading[employeeActions?.createOthersDocument.typePrefix]
//             }
//             countries={countries}
//             hideCountryCode={true}
//             hideDocumentType={true}
//           />
//         )}
//       </Spin>
//     </div>
//   );
// };

// export default EmployeeUpdate;
import NewHireForm from "@/components/Forms/NewHire/NewHireForm";

const NewHireDetail = (): JSX.Element => {
  return <NewHireForm />;
};

export default NewHireDetail;