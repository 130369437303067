import api from '@/common/api';
import { SLICE_NAME } from '@/common/constants/stores';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  BankPayload,
  DocumentType,
  IBank,
  IDocument,
  IEmployee,
  IEmployeeNote,
  IEmployeeNoteResponse,
  IEmployeeResponse,
  IPagination,
  IResponsePagination,
} from '@/types/app';
import { navigateTo } from '@/utils/auth';
import { openNotification } from '@/utils';
import { ROUTE_PATH } from '@/common/constants/routes';
import { uploadS3 } from '@/common/api/s3';
import { EndpointsParams } from '@/common/api/endpoints';

export interface IEmployeeState {
  data?: IEmployee[];
  loading: { [key: string]: boolean };
  pagination: IPagination;
  detail?: IEmployee;

  modalUploadDocumentVisibility?: boolean;
  uploadDocumentType?: DocumentType;

  banks: IResponsePagination<IBank>;

  timesheetDocuments: IResponsePagination<IDocument>;
  payslipDocuments: IResponsePagination<IDocument>;
  othersDocuments: IResponsePagination<IDocument>;
  guideDocuments: IResponsePagination<IDocument>;
  notes?: IEmployeeNote[];
  notePagination?: IPagination;
}

export const initialState: IEmployeeState = {
  pagination: {
    total: 0,
    page: 0,
    limit: 10,
    totalPage: 0,
  },
  data: [],
  loading: {},
  modalUploadDocumentVisibility: false,
  uploadDocumentType: undefined,
  banks: {
    data: [],
    pagination: {
      total: 0,
      page: 0,
      limit: 10,
      totalPage: 0,
    },
    success: false,
  },
  timesheetDocuments: {
    data: [],
    pagination: {
      total: 0,
      page: 0,
      limit: 10,
      totalPage: 0,
    },
    success: false,
  },
  payslipDocuments: {
    data: [],
    pagination: {
      total: 0,
      page: 0,
      limit: 10,
      totalPage: 0,
    },
    success: false,
  },
  othersDocuments: {
    data: [],
    pagination: {
      total: 0,
      page: 0,
      limit: 10,
      totalPage: 0,
    },
    success: false,
  },
  guideDocuments: {
    data: [],
    pagination: {
      total: 0,
      page: 0,
      limit: 10,
      totalPage: 0,
    },
    success: false,
  },
};

const getEmployees = createAsyncThunk(`${SLICE_NAME.EMPLOYEE}/getEmployees`, async (query: any | undefined, { rejectWithValue }) => {
  try {
    const data = await api.getEmployees<IEmployeeResponse>(query);
    return data;
  } catch (e) {
    return rejectWithValue(e);
  }
});

const getEmployee = createAsyncThunk(`${SLICE_NAME.EMPLOYEE}/getEmployee`, async (query: any | undefined, { rejectWithValue }) => {
  try {
    const data = await api.getEmployee<{ data: IEmployee }>(query);
    return data;
  } catch (e) {
    return rejectWithValue(e);
  }
});

const updateEmployee = createAsyncThunk(`${SLICE_NAME.EMPLOYEE}/updateEmployee`, async (form: IEmployee, { rejectWithValue }) => {
  try {
    const data = await api.updateEmployee<{ data: IEmployee }>(form);
    return data;
  } catch (e) {
    return rejectWithValue(e);
  }
});

const deleteEmployee = createAsyncThunk(`${SLICE_NAME.EMPLOYEE}/deleteEmployee`, async (query: any, { rejectWithValue }) => {
  try {
    const data = await api.deleteEmployee<{ data: IEmployee }>(query);
    return data;
  } catch (e) {
    return rejectWithValue(e);
  }
});

const offboardEmployee = createAsyncThunk(`${SLICE_NAME.EMPLOYEE}/offboardEmployee`, async (query: any, { rejectWithValue }) => {
  try {
    const data = await api.offboardEmployee<{ data: IEmployee }>(query);
    return data;
  } catch (e) {
    return rejectWithValue(e);
  }
});

const requestTerminationEmployee = createAsyncThunk(
  `${SLICE_NAME.EMPLOYEE}/requestTerminationEmployee`,
  async (query: any, { rejectWithValue }) => {
    try {
      const data = await api.requestTerminationEmployee<{ data: IEmployee }>(query);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const getSharedEmployeeBanks = createAsyncThunk(
  `${SLICE_NAME.EMPLOYEE}/getSharedEmployeeBanks`,
  async (query: { id: string }, { rejectWithValue }) => {
    try {
      const data = await api.getSharedEmployeeBanks<IResponsePagination<IBank>>(query);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const createEmployeeBank = createAsyncThunk(
  `${SLICE_NAME.EMPLOYEE}/createEmployeeBank`,
  async (form: BankPayload & EndpointsParams['createEmployeeBank'], { rejectWithValue }) => {
    try {
      const data = await api.createEmployeeBank<{ data: IBank }>(form);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const updateEmployeeBank = createAsyncThunk(
  `${SLICE_NAME.EMPLOYEE}/updateEmployeeBank`,
  async (form: Partial<BankPayload> & EndpointsParams['updateEmployeeBank'], { rejectWithValue }) => {
    try {
      const data = await api.updateEmployeeBank<{ data: IBank }>(form);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const getEmployeeTimesheets = createAsyncThunk(
  `${SLICE_NAME.EMPLOYEE}/getEmployeeTimesheets`,
  async (query: any | undefined, { rejectWithValue }) => {
    try {
      const data = await api.getEmployeeTimesheets<IResponsePagination<IDocument>>(query);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const getEmployeePayslips = createAsyncThunk(
  `${SLICE_NAME.EMPLOYEE}/getEmployeePayslips`,
  async (query: any | undefined, { rejectWithValue }) => {
    try {
      const data = await api.getEmployeePayslips<IResponsePagination<IDocument>>(query);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const getEmployeeOthers = createAsyncThunk(
  `${SLICE_NAME.EMPLOYEE}/getEmployeeOthers`,
  async (query: any | undefined, { rejectWithValue }) => {
    try {
      const data = await api.getEmployeeOthers<IResponsePagination<IDocument>>(query);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const createTimesheetDocument = createAsyncThunk(
  `${SLICE_NAME.DOCUMENT_SUITES}/createTimesheetDocument`,
  async (form: IDocument, { rejectWithValue }) => {
    try {
      if (form?.attachmentUpload && form?.attachmentUpload instanceof File) {
        const { success, data: attachment } = await uploadS3(form?.attachmentUpload, 'timesheet');
        if (success) {
          form = {
            ...form,
            attachment,
          };
        }
      }
      const data = await api.createEmployeeTimesheets<{ data: IDocument }>(form);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const createPayslipDocument = createAsyncThunk(
  `${SLICE_NAME.DOCUMENT_SUITES}/createPayslipDocument`,
  async (form: IDocument, { rejectWithValue }) => {
    try {
      if (form?.attachmentUpload && form?.attachmentUpload instanceof File) {
        const { success, data: attachment } = await uploadS3(form?.attachmentUpload, 'payslip');
        if (success) {
          form = {
            ...form,
            attachment,
          };
        }
      }
      const data = await api.createEmployeePayslips<{ data: IDocument }>(form);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const createOthersDocument = createAsyncThunk(
  `${SLICE_NAME.DOCUMENT_SUITES}/createOthersDocument`,
  async (form: IDocument, { rejectWithValue }) => {
    try {
      if (form?.attachmentUpload && form?.attachmentUpload instanceof File) {
        const { success, data: attachment } = await uploadS3(form?.attachmentUpload, 'others');
        if (success) {
          form = {
            ...form,
            attachment,
          };
        }
      }
      const data = await api.createEmployeeOthers<{ data: IDocument }>(form);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const getEmployeeGuides = createAsyncThunk(
  `${SLICE_NAME.EMPLOYEE}/getEmployeeGuides`,
  async (query: any | undefined, { rejectWithValue }) => {
    try {
      const data = await api.getEmployeeGuides<IResponsePagination<IDocument>>(query);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const getEmployeeNotes = createAsyncThunk(
  `${SLICE_NAME.EMPLOYEE}/getEmployeeNotes`,
  async (query: any | undefined, { rejectWithValue }) => {
    try {
      const data = await api.getEmployeeNotes<IEmployeeNoteResponse>(query);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const createEmployeeNote = createAsyncThunk(
  `${SLICE_NAME.EMPLOYEE}/createEmployeeNote`,
  async (form: IEmployeeNote, { rejectWithValue }) => {
    try {
      if (form?.attachmentUpload && form?.attachmentUpload instanceof File) {
        const { success, data: attachment } = await uploadS3(form?.attachmentUpload, 'onboarding_notes');
        if (success) {
          form = {
            ...form,
            attachment,
          };
        }
      }
      const data = await api.createEmployeeNote<{ data: IEmployee }>(form);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const updateEmployeeNote = createAsyncThunk(
  `${SLICE_NAME.EMPLOYEE}/updateEmployeeNote`,
  async (form: IEmployeeNote, { rejectWithValue }) => {
    try {
      if (form?.attachmentUpload && form?.attachmentUpload instanceof File) {
        const { success, data: attachment } = await uploadS3(form?.attachmentUpload, 'onboarding_notes');
        if (success) {
          form = {
            ...form,
            attachment,
          };
        }
      }
      const data = await api.updateEmployeeNote<{ data: IEmployee }>(form);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const mapFromResponse = (response: IEmployee) => {
  return {
    ...response,
    dateOfBirth: response.dateOfBirth ? response.dateOfBirth * 1000 : 0,
    startDate: response.startDate ? response.startDate * 1000 : 0,
    endDate: response.endDate ? response.endDate * 1000 : 0,
    anticipatedEndDate: response.anticipatedEndDate ? response.anticipatedEndDate * 1000 : 0,
  };
};

const employeeSlice = createSlice({
  name: SLICE_NAME.EMPLOYEE,
  initialState: initialState,
  reducers: {
    onOpenModalUploadDocument(state, { payload }) {
      state.modalUploadDocumentVisibility = true;
      state.uploadDocumentType = payload;
    },
    onCloseModalUploadDocument(state) {
      state.modalUploadDocumentVisibility = false;
      state.uploadDocumentType = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getEmployees.fulfilled, (state, { payload }) => {
      state.loading[getEmployees.typePrefix] = false;
      state.data = payload.data.data.map((item) => mapFromResponse(item));
      state.pagination = payload.data.pagination;
    });
    builder.addCase(getEmployees.pending, (state, { payload }) => {
      state.loading[getEmployees.typePrefix] = true;
    });
    builder.addCase(getEmployees.rejected, (state, { payload }) => {
      state.loading[getEmployees.typePrefix] = false;
    });
    builder.addCase(getEmployee.fulfilled, (state, { payload }) => {
      state.loading[getEmployee.typePrefix] = false;
      state.detail = mapFromResponse(payload.data.data);
    });
    builder.addCase(getEmployee.pending, (state, { payload }) => {
      state.loading[getEmployee.typePrefix] = true;
    });
    builder.addCase(getEmployee.rejected, (state, { payload }) => {
      state.loading[getEmployee.typePrefix] = false;
    });
    builder.addCase(updateEmployee.fulfilled, (state, { payload }) => {
      // navigateTo(ROUTE_PATH.EMPLOYEE);
      openNotification('success', `${payload.data.data?.forename} ${payload.data.data?.surname} updated successfully!`);
    });
    builder.addCase(updateEmployee.pending, (state, { payload }) => {
      state.loading[updateEmployee.typePrefix] = true;
    });
    builder.addCase(updateEmployee.rejected, (state, { payload }) => {
      state.loading[updateEmployee.typePrefix] = false;
    });
    builder.addCase(deleteEmployee.fulfilled, (state, { payload }) => {
      openNotification('success', `${payload.data.data?.forename} ${payload.data.data?.surname} updated successfully!`);
      state.loading[deleteEmployee.typePrefix] = false;
    });
    builder.addCase(deleteEmployee.pending, (state, { payload }) => {
      state.loading[deleteEmployee.typePrefix] = true;
    });
    builder.addCase(deleteEmployee.rejected, (state, { payload }) => {
      openNotification('error', 'Delete failed!');
      state.loading[deleteEmployee.typePrefix] = false;
    });
    builder.addCase(offboardEmployee.fulfilled, (state, { payload }) => {
      openNotification('success', `${payload?.data?.data?.forename} ${payload?.data?.data?.surname} progressed to offboarding!`);
      state.loading[offboardEmployee.typePrefix] = false;
    });
    builder.addCase(offboardEmployee.pending, (state, { payload }) => {
      state.loading[offboardEmployee.typePrefix] = true;
    });
    builder.addCase(offboardEmployee.rejected, (state, { payload }) => {
      openNotification('error', 'Offboard failed!');
      state.loading[offboardEmployee.typePrefix] = false;
    });
    builder.addCase(requestTerminationEmployee.fulfilled, (state, { payload }) => {
      openNotification('success', 'Termination request raised!');
      state.loading[requestTerminationEmployee.typePrefix] = false;
    });
    builder.addCase(requestTerminationEmployee.pending, (state, { payload }) => {
      state.loading[requestTerminationEmployee.typePrefix] = true;
    });
    builder.addCase(requestTerminationEmployee.rejected, (state, { payload }) => {
      openNotification('error', 'Request termination failed!');
      state.loading[requestTerminationEmployee.typePrefix] = false;
    });

    builder.addCase(getEmployeeTimesheets.fulfilled, (state, { payload }) => {
      state.loading[getEmployeeTimesheets.typePrefix] = false;
      console.log('**** test getEmployeeTimesheets payload', payload?.data);
      state.timesheetDocuments.data = payload.data.data;
      state.timesheetDocuments.pagination = payload.data.pagination;
    });
    builder.addCase(getEmployeeTimesheets.pending, (state, { payload }) => {
      state.loading[getEmployeeTimesheets.typePrefix] = true;
    });
    builder.addCase(getEmployeeTimesheets.rejected, (state, { payload }) => {
      state.loading[getEmployeeTimesheets.typePrefix] = false;
    });

    builder.addCase(getSharedEmployeeBanks.fulfilled, (state, { payload }) => {
      state.loading[getSharedEmployeeBanks.typePrefix] = false;
      console.log('**** test getSharedEmployeeBanks payload', payload?.data);
      state.banks.data = payload.data.data;
      state.banks.pagination = payload.data.pagination;
    });
    builder.addCase(getSharedEmployeeBanks.pending, (state, { payload }) => {
      state.loading[getSharedEmployeeBanks.typePrefix] = true;
    });
    builder.addCase(getSharedEmployeeBanks.rejected, (state, { payload }) => {
      state.loading[getSharedEmployeeBanks.typePrefix] = false;
    });

    builder.addCase(createEmployeeBank.fulfilled, (state, { payload }) => {
      state.loading[createEmployeeBank.typePrefix] = false;
      openNotification('success', 'create bank successfully!');
    });
    builder.addCase(createEmployeeBank.pending, (state, { payload }) => {
      state.loading[createEmployeeBank.typePrefix] = true;
    });
    builder.addCase(createEmployeeBank.rejected, (state, { payload }) => {
      openNotification('error', 'Create bank failed!');
      state.loading[createEmployeeBank.typePrefix] = false;
    });

    builder.addCase(updateEmployeeBank.fulfilled, (state, { payload }) => {
      state.loading[updateEmployeeBank.typePrefix] = false;
      openNotification('success', 'create bank successfully!');
    });
    builder.addCase(updateEmployeeBank.pending, (state, { payload }) => {
      state.loading[updateEmployeeBank.typePrefix] = true;
    });
    builder.addCase(updateEmployeeBank.rejected, (state, { payload }) => {
      openNotification('error', 'Create bank failed!');
      state.loading[updateEmployeeBank.typePrefix] = false;
    });

    builder.addCase(getEmployeePayslips.fulfilled, (state, { payload }) => {
      state.loading[getEmployeePayslips.typePrefix] = false;
      state.payslipDocuments.data = payload.data.data;
      state.payslipDocuments.pagination = payload.data.pagination;
    });
    builder.addCase(getEmployeePayslips.pending, (state, { payload }) => {
      state.loading[getEmployeePayslips.typePrefix] = true;
    });
    builder.addCase(getEmployeePayslips.rejected, (state, { payload }) => {
      state.loading[getEmployeePayslips.typePrefix] = false;
    });
    builder.addCase(getEmployeeOthers.fulfilled, (state, { payload }) => {
      state.loading[getEmployeeOthers.typePrefix] = false;
      state.othersDocuments.data = payload.data.data;
      state.othersDocuments.pagination = payload.data.pagination;
    });
    builder.addCase(getEmployeeOthers.pending, (state, { payload }) => {
      state.loading[getEmployeeOthers.typePrefix] = true;
    });
    builder.addCase(getEmployeeOthers.rejected, (state, { payload }) => {
      state.loading[getEmployeeOthers.typePrefix] = false;
    });

    builder.addCase(createTimesheetDocument.fulfilled, (state, { payload }) => {
      state.loading[createTimesheetDocument.typePrefix] = false;
      openNotification('success', 'Uploaded timesheet successfully!');
    });
    builder.addCase(createTimesheetDocument.pending, (state, { payload }) => {
      state.loading[createTimesheetDocument.typePrefix] = true;
    });
    builder.addCase(createTimesheetDocument.rejected, (state, { payload }) => {
      openNotification('error', 'Upload of timesheet failed!');
      state.loading[createTimesheetDocument.typePrefix] = false;
    });

    builder.addCase(createPayslipDocument.fulfilled, (state, { payload }) => {
      state.loading[createPayslipDocument.typePrefix] = false;
      openNotification('success', 'Uploaded payslip successfully!');
    });
    builder.addCase(createPayslipDocument.pending, (state, { payload }) => {
      state.loading[createPayslipDocument.typePrefix] = true;
    });
    builder.addCase(createPayslipDocument.rejected, (state, { payload }) => {
      openNotification('error', 'Upload of payslip failed!');
      state.loading[createPayslipDocument.typePrefix] = false;
    });

    builder.addCase(createOthersDocument.fulfilled, (state, { payload }) => {
      state.loading[createOthersDocument.typePrefix] = false;
      openNotification('success', 'Uploaded other successfully!');
    });
    builder.addCase(createOthersDocument.pending, (state, { payload }) => {
      state.loading[createOthersDocument.typePrefix] = true;
    });
    builder.addCase(createOthersDocument.rejected, (state, { payload }) => {
      openNotification('error', 'Upload of other failed!');
      state.loading[createOthersDocument.typePrefix] = false;
    });

    builder.addCase(getEmployeeGuides.fulfilled, (state, { payload }) => {
      state.loading[getEmployeeGuides.typePrefix] = false;
      state.guideDocuments.data = payload.data.data;
      state.guideDocuments.pagination = payload.data.pagination;
    });
    builder.addCase(getEmployeeGuides.pending, (state, { payload }) => {
      state.loading[getEmployeeGuides.typePrefix] = true;
    });
    builder.addCase(getEmployeeGuides.rejected, (state, { payload }) => {
      state.loading[getEmployeeGuides.typePrefix] = false;
    });
    builder.addCase(getEmployeeNotes.fulfilled, (state, { payload }) => {
      state.loading[getEmployeeNotes.typePrefix] = false;
      state.notes = payload.data.data;
      state.notePagination = payload.data.pagination;
    });
    builder.addCase(getEmployeeNotes.pending, (state, { payload }) => {
      state.loading[getEmployeeNotes.typePrefix] = true;
    });
    builder.addCase(getEmployeeNotes.rejected, (state, { payload }) => {
      state.loading[getEmployeeNotes.typePrefix] = false;
    });
    builder.addCase(createEmployeeNote.fulfilled, (state, { payload }) => {
      state.loading[createEmployeeNote.typePrefix] = false;
      openNotification('success', 'Note created successfully!');
    });
    builder.addCase(createEmployeeNote.pending, (state, { payload }) => {
      state.loading[createEmployeeNote.typePrefix] = true;
    });
    builder.addCase(createEmployeeNote.rejected, (state, { payload }) => {
      state.loading[createEmployeeNote.typePrefix] = false;
      openNotification('error', 'Creation note failed!');
    });
    builder.addCase(updateEmployeeNote.fulfilled, (state, { payload }) => {
      openNotification('success', 'Update successfully!');
      state.loading[updateEmployeeNote.typePrefix] = false;
    });
    builder.addCase(updateEmployeeNote.pending, (state, { payload }) => {
      state.loading[updateEmployeeNote.typePrefix] = true;
    });
    builder.addCase(updateEmployeeNote.rejected, (state, { payload }) => {
      state.loading[updateEmployeeNote.typePrefix] = false;
    });
  },
});

export const employeeReducer = employeeSlice.reducer;
export const employeeCaseReducers = employeeSlice.caseReducers;
export const employeeActions = {
  ...employeeSlice.actions,
  getEmployees,
  getEmployee,
  updateEmployee,
  deleteEmployee,
  offboardEmployee,
  requestTerminationEmployee,

  getSharedEmployeeBanks,
  createEmployeeBank,
  updateEmployeeBank,

  getEmployeeTimesheets,
  getEmployeePayslips,
  getEmployeeOthers,
  getEmployeeGuides,

  createTimesheetDocument,
  createPayslipDocument,
  createOthersDocument,

  getEmployeeNotes,
  createEmployeeNote,
  updateEmployeeNote,
};
