import { IPaginationQuery } from "@/types/app";

export enum SLICE_NAME {
  APP = "APP",
  DASHBOARD = "DASHBOARD",
  AUTH = "AUTH",
  PROFILE = "PROFILE",
  SETTINGS = "SETTINGS",
  NEW_HIRE = "NEW_HIRE",
  USER = "USER",
  PAYROLLS = "PAYROLLS",
  EMPLOYEE = "EMPLOYEE",
  ONBOARDING = "ONBOARDING",
  OFFBOARDING = "OFFBOARDING",
  ACTIVITY_LOG = "ACTIVITY_LOG",
  NOTIFICATION = "NOTIFICATION",
  REPORTS = "REPORTS",
  CLIENT_USERS = "CLIENT_USERS",
  SMTPS = "SMTPS",
  INSIGHT = "INSIGHT",


  DOCUMENT_SUITES = "DOCUMENT_SUITES",
  IMPORTS = "IMPORTS",
  INVOICES = "INVOICES",
  MESSAGES = "MESSAGES",
  MESSAGES_ROOM = "MESSAGES_ROOM",

  GROUP_PERMISSIONS = "GROUP_PERMISSIONS",
  USER_PERMISSIONS = "USER_PERMISSIONS",

  EMPLOYEE_PORTAL = "EMPLOYEE_PORTAL",

}

export const FILTER_DEFAULT: IPaginationQuery = {
  page: 1,
  limit: 10,
};
