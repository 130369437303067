import Axios from "axios";
import api, { IApiResponse } from ".";
import { IAttachment, IS3Signature } from "@/types/app";
import config from "@/config";

async function uploadImage(
  url: string,
  formData: FormData
): Promise<IApiResponse<IAttachment>> {
  const amzCredential = formData.get("x-amz-credential")?.toString() as string;
  const file_name = formData.get("file_name")?.toString() || "";
  const file_size = formData.get("file_size")?.toString() || "0";
  formData.delete("file_name");
  formData.delete("file_size");

  return new Promise((resolve, reject) => {
    Axios.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        "X-Amz-Credential": amzCredential,
      },
    })
      .then(() => {
        const fileKey = formData.get("key")?.toString() || "";
        const fileURL = `${config.STORE_URL}/${fileKey}`;

        const contentType = formData.get("content-type")?.toString() || "";
        const data: IAttachment = {
          contentType: contentType,
          fileKey,
          fileURL,
          metadata: {
            name: file_name,
            size: parseInt(file_size),
          },
        };
        resolve({
          success: true,
          message: "Upload success",
          statusCode: 200,
          data: data,
        });
      })
      .catch((error) => {
        console.log("*** error", error);
        reject({
          success: false,
          statusCode: 400,
          message: error,
          data: {},
        });
      });
  });
}

export async function uploadS3(file: File, prefix: string) {
  const { data: respSignature } = await api.getS3Signature<
    IApiResponse<IS3Signature>
  >({
    contentType: file.type,
    resource: prefix,
  });
  if (!respSignature.success) {
    throw respSignature.data;
  }

  const formData = new FormData();
  const { url, key, ...other } = respSignature?.data;

  console.log("***** test respSignature", respSignature);

  // @ts-ignore
  Object.keys(other).forEach((k) => formData.append(k, other[k]));

  formData.append("key", key);
  formData.append("file_name", file.name);
  formData.append("file_size", `${file.size}`);
  formData.append("file", file as File);

  const { success, data } = await uploadImage(url, formData);
  if (!success) {
    throw data;
  }
  return { success, data };
}
