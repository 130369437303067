import { IEmployee, IEmployeeListTable } from "@/types/app";
import {
  Button,
  Form,
  Input,
  Popconfirm,
  Space,
  Spin,
  Tag,
  Tooltip,
} from "antd";
import { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { Link, useLocation } from "react-router-dom";
import { EyeTwoTone, DeleteTwoTone, EditTwoTone } from "@ant-design/icons";
import { RegistrationStatus, UserRole } from "@/common/constants/enums";
import { dateFormat, pageSizeOptions } from "@/common/constants/format";
import TableWithScrollButtons from "@/components/Shared/TableWithScrollButtons";
import { useDispatch, useSelector } from "@/stores";
import { useEffect, useState } from "react";
import axios from "axios";
import { onboardingActions } from "@/stores/onboarding";
import { generateClientRef, matchValueToLabel } from "@/utils";
import dayjs from "dayjs";
import { isRootAdmin } from "@/utils/auth";
import { DeleteIcon, EyeIcon, GrayEditIcon, SearchIcon } from "@/components/common/IconComponents";
import { SorterResult } from "antd/es/table/interface";

const OffboardingList = () => {
  const dispatch = useDispatch();
  const { data, loading, pagination } = useSelector(
    (state) => state.onboarding
  );
  const { constants } = useSelector((state) => state.app);

  const { userInfo } = useSelector((state) => state.auth);

  const location = useLocation();
  const search = new URLSearchParams(location?.search);
  const countryOfHireID = search?.get("countryOfHireID") || "";
  const registrationStatuses = search?.getAll("registrationStatuses") || "";

  const [searchText, setSearchText] = useState('');


  // useEffect(function () {
  //   dispatch(onboardingActions.getOnboardings({ role: userInfo?.role }));
  // }, []);

  const columns: ColumnsType<IEmployee> = [
    {
      title: 'Employee',
      dataIndex: 'name',
      key: 'name',
      width: 250,
      fixed: 'left',
      sorter: true,
      render: (name, record) => (
        <div className="general-information-table">
          <p className="title">{name}</p>
          <p className="">{record.email ? record.email : ''}</p>
        </div>
      ),
    },
    {
      title: "Job Title",
      dataIndex: "jobTitle",
      key: "address",
      width: 200,
      sorter: true,
    },
    {
      title: "Client Name",
      key: "client",
      width: 200,
      sorter: true,
      render: (_, record) => (
        <Space>
          {[record.client?.forename, record?.client?.surname].join(" ")}
        </Space>
      ),
    },
    {
      title: "Client Email",
      key: "client",
      width: 200,
      sorter: true,
      render: (_, record) => <Space>{record.client?.email}</Space>,
    },
    {
      title: 'Nationality',
      dataIndex: 'employeeNationality',
      key: 'employeeNationality',
      width: 200,
      sorter: true,
      render: (employeeNationality) =>
        employeeNationality?.id ? (
          <Space>
            <img src={employeeNationality?.flag} width="16" height="12" alt={`${employeeNationality?.name}`} className="mr-2" />
            {employeeNationality?.name}
          </Space>
        ) : null,
    },
    {
      title: 'Country of Hire',
      dataIndex: 'countryOfHire',
      key: 'countryOfHire',
      width: 200,
      sorter: true,
      render: (countryOfHire) =>
        countryOfHire?.id ? (
          <Space>
            <img src={countryOfHire?.flag} width="16" height="12" alt={`${countryOfHire?.name}`} className="mr-2" />
            {countryOfHire?.name}
          </Space>
        ) : null,
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      width: 200,
      sorter: true,
      render: (val) => dayjs(val).format(dateFormat),
    },
    {
      title: "Status",
      dataIndex: "registrationStatus",
      key: "registrationStatus",
      fixed: "right",
      width: 190,
      render: (value) => {
        const result = matchValueToLabel(
          constants?.registrationStatuses || [],
          value
        );
        // return (<b style={{color: result.color}}>{result.label}</b>)
        if (result)
          return (
            <Tag
              className="rounded text-uppercase"
              color={
                value === RegistrationStatus.inReview
                  ? "volcano"
                  : value === RegistrationStatus.completed
                  ? "green"
                  : "gold"
              }
            >
              {result}
            </Tag>
          );
      },
    },
    {
      title: "Actions",
      key: "action",
      fixed: "right",
      width: 150,
      render: (_, record) => (
        <Space>
          <Link to={_.id}>
            <Tooltip title="View Detail">
              <Button
                type="text"
                size="small"
              >
                <EyeIcon />
              </Button>
            </Tooltip>
          </Link>
          <Link to={`${record.id}/update`}>
            <Tooltip title="Edit">
              <Button
                type="text"
                size="small"
              >
                <GrayEditIcon />
              </Button>
            </Tooltip>
          </Link>
          {userInfo?.role === UserRole.company ? (
            <Popconfirm
              title="Delete this hire"
              description="Hire deleted successfully!"
              onConfirm={async function () {
                await dispatch(
                  onboardingActions.deleteOnboarding({
                    id: record.id,
                    role: userInfo?.role,
                  })
                );
                await dispatch(
                  onboardingActions.getOnboardings({ role: userInfo?.role })
                );
              }}
              okText="Yes"
              cancelText="No"
            >
              <Tooltip title="Delete">
                <Button
                type="text"
                size="small"
              >
                <DeleteIcon />
              </Button>
              </Tooltip>
            </Popconfirm>
          ) : null}
        </Space>
      ),
    },
  ];

  const handleSearchSubmit = (e: string) => {
    const keyword = e.trim();
    setSearchText(keyword);
    dispatch(onboardingActions.getOnboardings({ keyword, role: userInfo?.role, registrationStatuses, countryOfHireID, }));
  };

  const handlePaginationChange = (page: number, pageSize: number) => {
    dispatch(
      onboardingActions.getOnboardings({
        page,
        limit: pageSize,
        role: userInfo?.role,
        registrationStatuses,
        countryOfHireID,
      })
    );
  };

  const handleSortAndPaginationChange = (
    pagination: TablePaginationConfig,
    sorter: SorterResult<IEmployee> | SorterResult<IEmployee>[],
  ) => {
      dispatch(
        onboardingActions.getOnboardings({
          page: pagination.current,
          limit: pagination.pageSize,
          keyword: searchText,
          sortBy: !Array.isArray(sorter) && sorter.order ? `${sorter.field} ${sorter.order === 'ascend' ? 'ASC' : 'DESC'}` : undefined,
          role: userInfo?.role,
          registrationStatuses,
          countryOfHireID,
        }),
      );
  };

  useEffect(() => {
    // if (location.search.includes("?keyword=")) {
    //   setSearchText(keyword || "");
    //   handleSearchSubmit(keyword || "");
    // } else
    if (userInfo?.role !== UserRole.admin) {
      if (location.search && (countryOfHireID || registrationStatuses.length)) {
        dispatch(
          onboardingActions.getOnboardings({
            countryOfHireID,
            registrationStatuses: [registrationStatuses],
            role: userInfo?.role,
          })
        );
      } else
        dispatch(onboardingActions.getOnboardings({ role: userInfo?.role }));
    }
  }, [location.search]);

  return (
    <div className="container-fluid pt-4">
      <Spin tip="Loading" size="large" spinning={loading[onboardingActions.getOnboardings.typePrefix]}>
        <p className="page-listing-title">Onboarding</p>
        <div className="bg-white rounded">
          <div className="card-header">
            <Input
              prefix={<SearchIcon className="mr-0" />}
              placeholder="Search"
              onPressEnter={(e: any) => handleSearchSubmit(e?.target?.value)}
              className="w-100 mr-2 page-listing-search-input"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <TableWithScrollButtons
                columns={columns}
                dataSource={data}
                size="small"
                scroll={{ x: 1200 }}
                onChange={(pagination, filter, sorter) => {
                  handleSortAndPaginationChange(pagination, sorter);
                }}
                pagination={{
                  // onChange: (page: number, pageSize: number) => handlePaginationChange(page, pageSize),
                  showSizeChanger: true,
                  defaultCurrent: 1,
                  total: pagination.total,
                  position: ['bottomLeft'],
                  pageSizeOptions: pageSizeOptions,
                  showTotal: (total: number, range: number[]) => 'Showing ' + range[0] + ' to ' + range[1] + ' of ' + total + ' entries',
                }}
              />
            </div>
          </div>
        </div>
      </Spin>
    </div>
  );
};

export default OffboardingList;
