import { dateFormat } from "@/common/constants/format";
import { useDispatch, useSelector } from "@/stores";
import { employeeActions } from "@/stores/employee";
import { IEmployeeRequestTermination } from "@/types/app";
import { Alert, DatePicker, Form, Input, Modal, Popconfirm } from "antd";
import dayjs from "dayjs";

const RequestTerminationModal = (props: any) => {
  const { employeeId, isOpen, setIsOpen } = props;
  const { userInfo } = useSelector((state) => state.auth);
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const initialState: IEmployeeRequestTermination = {
    id: employeeId,
    anticipatedEndDate: "",
    reasonForTermination: "",
  };

  const handleCloseModal = () => {
    form.resetFields();
    setIsOpen(false);
  };

  const transformBeforeSubmit = (e: IEmployeeRequestTermination) => {
    return {
      id: employeeId,
      role: userInfo?.role,
      anticipatedEndDate: e.anticipatedEndDate
        ? dayjs(e.anticipatedEndDate).unix()
        : 0,
      reasonForTermination: e.reasonForTermination,
    };
  };

  const handleSubmitForm = async (e: IEmployeeRequestTermination) => {
    const result = transformBeforeSubmit(e);
    await dispatch(employeeActions.requestTerminationEmployee(result));
    await dispatch(employeeActions.getEmployees(result));
    form.resetFields();
    handleCloseModal();
  };

  return (
    <Modal
      title="Request Termination"
      open={isOpen}
      okText={
        <Popconfirm
          title="Request Termination"
          description={
            <>
              <Alert
                message={
                  <>
                    <p className="mb-0">
                      The termination involves legislation in the country
                      needing to be adhered
                    </p>
                    <p className="mb-0">
                      to and any termination costs will be charged once
                      processed.
                    </p>
                  </>
                }
                type="warning"
              />
              <p>Do you want to request a termination for this employee?</p>
            </>
          }
          onConfirm={async function () {
            form.submit();
          }}
          okText="Yes"
          cancelText="No"
        >
          Save
        </Popconfirm>
      }
      onCancel={() => handleCloseModal()}
      centered
    >
      <Form
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        layout="vertical"
        initialValues={initialState}
        onFinish={handleSubmitForm}
        form={form}
      >
        <div className="row">
          {/* <div className="col-md-12">
            <Alert
              message="Legislation in the country needing to be 
              adhered to and any termination costs will be charged once processed"
              type="warning"
              showIcon
              closable
              className="my-2"
            />
          </div> */}
          <div className="col-md-12">
            <Form.Item
              label="Anticipated End Date"
              name="anticipatedEndDate"
              getValueProps={(i) => ({ value: i ? dayjs(i) : "" })}
              rules={[{ required: true, message: "This field is required!" }]}
            >
              <DatePicker format={dateFormat} className="w-100 p-12" />
            </Form.Item>
          </div>
          <div className="col-md-12">
            <Form.Item
              label="Reason For Termination"
              name="reasonForTermination"
              rules={[{ required: true, message: "This field is required!" }]}
            >
              <Input.TextArea
                placeholder="Enter reason for termination"
                maxLength={1000}
              />
            </Form.Item>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default RequestTerminationModal;
