import api from "@/common/api";
import { SLICE_NAME } from "@/common/constants/stores";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { INotification, INotificationResponse, IPagination } from "@/types/app";
import { navigateTo } from "@/utils/auth";
import { openNotification } from "@/utils";
import { ROUTE_PATH } from "@/common/constants/routes";

export interface INotificationState {
  data?: INotification[];
  loading: { [key: string]: boolean };
  pagination: IPagination;
  detail?: INotification;
}

export const initialState: INotificationState = {
  pagination: {
    total: 0,
    page: 0,
    limit: 10,
    totalPage: 0,
  },
  data: [],
  loading: {},
};

const getNotifications = createAsyncThunk(
  `${SLICE_NAME.NOTIFICATION}/getNotifications`,
  async (query: any | undefined, { rejectWithValue }) => {
    try {
      const data = await api.getNotifications<INotificationResponse>(query);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const getMoreNotifications = createAsyncThunk(
  `${SLICE_NAME.NOTIFICATION}/getMoreNotifications`,
  async (query: any | undefined, { rejectWithValue }) => {
    try {
      const data = await api.getNotifications<INotificationResponse>(query);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const getNotification = createAsyncThunk(
  `${SLICE_NAME.NOTIFICATION}/getNotification`,
  async (query: any | undefined, { rejectWithValue }) => {
    try {
      const data = await api.getNotification<{ data: INotification }>(query);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const createNotification = createAsyncThunk(
  `${SLICE_NAME.NOTIFICATION}/createNotification`,
  async (form: INotification, { rejectWithValue }) => {
    try {
      const data = await api.createNotification<{ data: INotification }>(form);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const updateNotification = createAsyncThunk(
  `${SLICE_NAME.NOTIFICATION}/updateNotification`,
  async (form: INotification, { rejectWithValue }) => {
    try {
      const data = await api.updateNotification<{ data: INotification }>(form);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const deleteNotification = createAsyncThunk(
  `${SLICE_NAME.NOTIFICATION}/deleteNotification`,
  async (query: any, { rejectWithValue }) => {
    try {
      const data = await api.deleteNotification<{ data: INotification }>(query);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const markAsSeenNotification = createAsyncThunk(
  `${SLICE_NAME.NOTIFICATION}/markAsSeenNotification`,
  async (form: { id: string }, { rejectWithValue }) => {
    try {
      const data = await api.markAsSeenNotification<{ data: INotification }>(
        form
      );
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const mapFromResponse = (response: INotification) => {
  return {
    ...response,
    seenAt: response.seenAt ? response.seenAt * 1000 : 0,
  };
};

const notificationSlice = createSlice({
  name: SLICE_NAME.NOTIFICATION,
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getNotifications.fulfilled, (state, { payload }) => {
      state.loading[getNotifications.typePrefix] = false;
      state.data = payload.data.data.map((item) => mapFromResponse(item));
      state.pagination = payload.data.pagination;
    });
    builder.addCase(getNotifications.pending, (state, { payload }) => {
      state.loading[getNotifications.typePrefix] = true;
    });
    builder.addCase(getNotifications.rejected, (state, { payload }) => {
      state.loading[getNotifications.typePrefix] = false;
    });
    builder.addCase(getNotification.fulfilled, (state, { payload }) => {
      state.loading[getNotification.typePrefix] = false;
      state.detail = mapFromResponse(payload.data.data);
    });
    builder.addCase(getNotification.pending, (state, { payload }) => {
      state.loading[getNotification.typePrefix] = true;
    });
    builder.addCase(getNotification.rejected, (state, { payload }) => {
      state.loading[getNotification.typePrefix] = false;
    });
    builder.addCase(createNotification.fulfilled, (state, { payload }) => {
      openNotification("error", "Create notification successfully!");
      state.loading[createNotification.typePrefix] = false;
    });
    builder.addCase(createNotification.pending, (state, { payload }) => {
      state.loading[createNotification.typePrefix] = true;
    });
    builder.addCase(createNotification.rejected, (state, { payload }) => {
      openNotification("error", "Create notification failed!");
      state.loading[createNotification.typePrefix] = false;
    });
    builder.addCase(updateNotification.fulfilled, (state, { payload }) => {
      navigateTo(ROUTE_PATH.NOTIFICATION);
      openNotification("success", "Update successfully!");
    });
    builder.addCase(updateNotification.pending, (state, { payload }) => {
      state.loading[updateNotification.typePrefix] = true;
    });
    builder.addCase(updateNotification.rejected, (state, { payload }) => {
      state.loading[updateNotification.typePrefix] = false;
    });
    builder.addCase(deleteNotification.fulfilled, (state, { payload }) => {
      openNotification("success", "Delete successfully!");
      state.loading[deleteNotification.typePrefix] = false;
    });
    builder.addCase(deleteNotification.pending, (state, { payload }) => {
      state.loading[deleteNotification.typePrefix] = true;
    });
    builder.addCase(deleteNotification.rejected, (state, { payload }) => {
      openNotification("error", "Delete failed!");
      state.loading[deleteNotification.typePrefix] = false;
    });

    builder.addCase(markAsSeenNotification.fulfilled, (state, { payload }) => {
      const newData = state.data?.map((item) => {
        if (item?.id === payload?.data?.data?.id) {
          return payload?.data?.data;
        }
        return item;
      });

      const totalNotificationNotSeen =
        (state.pagination.totalNotificationNotSeen || 0) <= 0
          ? 0
          : state.pagination.totalNotificationNotSeen || 0 - 1;

      state.data = newData;
      state.pagination.totalNotificationNotSeen = totalNotificationNotSeen;
    });
    builder.addCase(markAsSeenNotification.pending, (state, { payload }) => {
      state.loading[markAsSeenNotification.typePrefix] = true;
    });
    builder.addCase(markAsSeenNotification.rejected, (state, { payload }) => {
      state.loading[markAsSeenNotification.typePrefix] = false;
    });

    builder.addCase(getMoreNotifications.fulfilled, (state, { payload }) => {
      state.loading[getMoreNotifications.typePrefix] = false;

      if (payload.data.pagination.page !== 1) {
        state.data?.push(
          ...payload.data.data.map((item) => mapFromResponse(item))
        );
      } else {
        state.data = payload.data.data.map((item) => mapFromResponse(item));
      }

      state.pagination = payload.data.pagination;
    });
    builder.addCase(getMoreNotifications.pending, (state, { payload }) => {
      state.loading[getMoreNotifications.typePrefix] = true;
    });
    builder.addCase(getMoreNotifications.rejected, (state, { payload }) => {
      state.loading[getMoreNotifications.typePrefix] = false;
    });
  },
});

export const notificationReducer = notificationSlice.reducer;
export const notificationCaseReducers = notificationSlice.caseReducers;
export const notificationActions = {
  ...notificationSlice.actions,
  getNotifications,
  getNotification,
  createNotification,
  updateNotification,
  deleteNotification,
  markAsSeenNotification,
  getMoreNotifications,
};
