import { enumTypeToOptions } from "@/utils";

export enum UserRole {
  admin = "admin",
  client = "client",
  company = "company",
  employee = "employee",
}

export enum PayFrequency {
  hourly = "Hourly",
  daily = "Daily",
  weekly = "Weekly",
  biWeekly = "Bi-Weekly",
  monthly = "Monthly",
}

export enum Title {
  mr = "Mr",
  mrs = "Mrs",
  ms = "Ms",
  other = "Other",
}

export enum EmployeeStatus {
  inReview = "in_review",
  onBoarding = "on_boarding",
  offBoarding = "off_boarding",
  registrationOngoing = "registration_ongoing",
  registrationComplete = "registration_complete",
  requestedTermination = "requested_termination",
  active = "active",
  leaver = "leaver",
}

export enum RegistrationStatus {
  inReview = "in_review",
  awaitingDocuments = "awaiting_documents",
  awaitingScreening = "awaiting_screening",
  awaitingContract = "awaiting_contract",
  inProgress = "in_progress",
  completed = "completed",
}

export enum OffboardingStatus {
  inReview = "in_review",
  offboardingCommenced = "off_boarding_commenced",
  offboardingComplete = "off_boarding_completed",
}

export enum AuthoriseExpensesStatus {
  rejected = "Rejected",
  awaitingApproval = "Awaiting Approval",
  approved = "Approved",
}

export enum ExpenseType {
  mileage = "Mileage",
  travel = "Travel",
  subsistence = "Subsistence",
}

export enum Department {
  payroll = "payroll",
  customerService = "customer_service",
  sales = "sales",
  compliance = "compliance",
}

export enum DocumentType {
  invoice = "invoice",
  timesheet = "timesheet",
  guide = "guide",
  payslip = "payslip",
  others = "others",
}

// TODO: Remove later
export const enumOptions = {
  UserRole: enumTypeToOptions(UserRole),
  PayFrequency: enumTypeToOptions(PayFrequency),
  Title: enumTypeToOptions(Title),
  EmployeeStatus: enumTypeToOptions(EmployeeStatus),
  RegistrationStatus: enumTypeToOptions(RegistrationStatus),
  OffboardingStatus: enumTypeToOptions(OffboardingStatus),
  AuthoriseExpensesStatus: enumTypeToOptions(AuthoriseExpensesStatus),
  ExpenseType: enumTypeToOptions(ExpenseType),
  Departments: enumTypeToOptions(Department),
  DocumentTypes: enumTypeToOptions(DocumentType),
};
