import api from "@/common/api";
import { RED_BASE } from "@/common/constants/ThemeConstant";
import { BriefCaseIcon, GlobeIcon, PlusIcon, TrendingDownIcon, TrendingUpIcon, Users3Icon } from "@/components/common/IconComponents";
import TableWithScrollButtons from "@/components/Shared/TableWithScrollButtons";
import { useDispatch, useSelector } from "@/stores";
import { dashboardActions } from "@/stores/dashboard";
import {
  IConstants,
  ICountryConstant,
  IDashboardStatistic,
  IDashboardTopEmployee,
  IEmployee,
  IObject,
} from "@/types/app";
import {
  matchCountryCodeToFlag,
  matchCountryCodeToLabel,
  matchValueToLabel,
  sortedByCountryName,
} from "@/utils";
import { Col, Row, Space, Tag } from "antd";
import { ColumnsType, TableProps } from "antd/es/table";
import axios from "axios";
import dayjs from "dayjs";
import { isArray } from "lodash";
import { useEffect, useState } from "react";


const DashboardStatistics= () => {
  const {dashboardStatistics} = useSelector(state=>state.dashboard);
  const dispatch = useDispatch();

  const [startDate, setStartDate] = useState<number | undefined>();
  const [startDateLast, setStartDateLast] = useState<number | undefined>();
  const [endDateLast, setEndDateLast] = useState<number | undefined>();
  // const [endDate, setEndDate] = useState();
  const [endDate, setEndDate] = useState<number | undefined>();
  const [dataDashboardStatisticLast, setDataDashboardStatisticLast] = useState<any>(null);
  const [dataDashboardStatistic, setDataDashboardStatistic] = useState<any>(dashboardStatistics);
  const handleDataDashboardLastWeekly = () => {
    setStartDateLast(dayjs().subtract(1, 'week').startOf('week').valueOf());
    setEndDateLast(dayjs().subtract(1, 'week').endOf('week').valueOf());
  };

  const handleDataDashboardWeekly = () => {
    setStartDate(dayjs(Date.now()).startOf('week').valueOf());
    setEndDate(dayjs(Date.now()).endOf('week').valueOf());
  };

  const getDataBookingStatistic = async () => {
    try {
      if (!startDate || !endDate) return;
      const startEpoch = startDate !== undefined ? Math.floor(startDate / 1000) : 0;
      const endEpoch = endDate !== undefined ? Math.floor(endDate / 1000) : 0;

      dispatch(dashboardActions.getDashboardStatistic({ fromDate: startEpoch, toDate: endEpoch }))
        .then((res: any) => {
          console.log('res', res);
          setDataDashboardStatistic(res?.payload?.data?.data);
        })
        .catch((err: any) => console.log);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const getLastDataBookingStatistic = async () => {
    try {
      if (!startDateLast || !startDateLast) return;
      const startEpoch = startDateLast !== undefined ? Math.floor(startDateLast / 1000) : 0;
      const endEpoch = endDateLast !== undefined ? Math.floor(endDateLast / 1000) : 0;

      const responseSheet = await api.getDashboardStatistic<any>({
        fromDate: startEpoch,
        toDate: endEpoch,
      });
      setDataDashboardStatisticLast(responseSheet?.data?.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    getDataBookingStatistic();
  }, [startDate, endDate]);

  useEffect(() => {
    getLastDataBookingStatistic();
  }, [startDateLast, endDateLast]);


  useEffect(() => {
    handleDataDashboardWeekly();
    handleDataDashboardLastWeekly();
  }, []);

  const calculateTotalActiveEmployeesPercent =(dataLastWeek: IDashboardStatistic, dataThisWeek: IDashboardStatistic)=>{
    if(dataLastWeek?.totalActiveEmployees){
      const result = (dataThisWeek?.totalActiveEmployees - dataLastWeek?.totalActiveEmployees) / dataLastWeek?.totalActiveEmployees

      return (
        <Tag className="d-flex align-items-center" style={{ height: 20 }} color={result>=0?'green':'red'}>
          {result>=0?(<><TrendingUpIcon className="mr-1"/> + {result}</>):<><TrendingDownIcon className="mr-1"/> {result}</>}%
        </Tag>
      );
    } return <Tag className="d-flex align-items-center" style={{ height: 20 }} color="green">+0%</Tag>
  }

  const calculateTotalNewEmployeesPercent =(dataLastWeek: IDashboardStatistic, dataThisWeek: IDashboardStatistic)=>{
    if(dataLastWeek?.totalNewEmployees){
      const result = (dataThisWeek?.totalNewEmployees - dataLastWeek?.totalNewEmployees) / dataLastWeek?.totalNewEmployees
      return (
        <Tag className="d-flex align-items-center" style={{ height: 20 }} color={result >= 0 ? 'green' : 'red'}>
          {result>=0?(<><TrendingUpIcon className="mr-1"/> + {result}</>):<><TrendingDownIcon className="mr-1"/> {result}</>}%
        </Tag>
      );
    } return <Tag className="d-flex align-items-center" style={{ height: 20 }} color="green">+0%</Tag>
  }

  console.log('dataDashboardStatisticLast, dataDashboardStatistic', dataDashboardStatisticLast, dataDashboardStatistic)



  return (
    <Row gutter={0}>
      <Col xs={12} lg={12} className="dashboard-statistics-item border-right border-bottom p-3 pt-4">
        <GlobeIcon className="icon mb-2" />
        <p className="value mb-0">{dashboardStatistics?.totalActiveCountries}</p>
        <p className="label mb-0">Active Countries</p>
      </Col>
      <Col xs={12} lg={12} className="dashboard-statistics-item border-left border-bottom p-3 pt-4">
        <BriefCaseIcon className="icon mb-2" />
        <p className="value mb-0">{dashboardStatistics?.totalClients}</p>
        <p className="label mb-0">Active Clients</p>
      </Col>
      <Col xs={12} lg={12} className="dashboard-statistics-item border-top border-right p-3 pb-4">
        <Users3Icon className="icon mb-2" />
        <p className="d-flex justify-content-between align-items-center flex-wrap value mb-0">
          {dashboardStatistics?.totalActiveEmployees}
            {calculateTotalActiveEmployeesPercent(dataDashboardStatisticLast, dataDashboardStatistic)}

        </p>
        <p className="label mb-0">Total Active Employees</p>
      </Col>
      <Col xs={12} lg={12} className="dashboard-statistics-item border-top border-left p-3 pb-4">
        <PlusIcon className="icon mb-2" />
        <p className="d-flex justify-content-between align-items-center flex-wrap value mb-0">
          {dashboardStatistics?.totalNewEmployees}
          {calculateTotalNewEmployeesPercent(dataDashboardStatisticLast, dataDashboardStatistic)}
        </p>
        <p className="label mb-0">New Employees</p>
      </Col>
    </Row>
  );
};

export default DashboardStatistics;
