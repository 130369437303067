import { useEffect, useState } from "react";
import { Col, DatePicker, Form, FormInstance, Input, Row, Select } from "antd";
import dayjs from "dayjs";
import { dateFormat } from "@/common/constants/format";
import { useSelector } from "@/stores";
import axios from "axios";
import { constantsToOptions, settingsToOptions } from "@/utils";
import { UserRole } from "@/common/constants/enums";
import { RangePickerProps } from "antd/es/date-picker";
import SearchPayrolls from "../SearchPayrolls";

const EmployeeRegistrationInfoForm = (props: {
  form?: FormInstance;
  onboarding?: boolean;
  offboarding?: boolean;
  employee?: boolean;
}) => {
  const { constants, countries, settings } = useSelector((state) => state.app);
  const { userInfo } = useSelector((state) => state.auth);
  const { form, onboarding, offboarding, employee } = props;

  const [startDate, setStartDate] = useState<number>(
    form?.getFieldValue("startDate")
  );
  const [endDate, setEndDate] = useState<number>(
    form?.getFieldValue("endDate")
  );

  const disabledStartDate: RangePickerProps["disabledDate"] = (current) => {
    if (endDate) {
      return current && current > dayjs(endDate).startOf("day");
    }
    return false;
  };

  const disabledEndDate: RangePickerProps["disabledDate"] = (current) => {
    if (startDate) {
      return current && current < dayjs(startDate).endOf("day");
    }
    return false;
  };

  return (
    <Row gutter={16} className="p-2">
      {employee ? (
        <Col sm={12}>
          <Form.Item label="Status" name="status">
            <Select
              placeholder="Select status"
              options={constantsToOptions(constants?.statuses || [])}
              disabled={userInfo?.role !== UserRole.company}
            />
          </Form.Item>
        </Col>
      ) : null}
      {!offboarding ? (
        <Col sm={12}>
          <Form.Item label="Registration Status" name="registrationStatus">
            <Select
              placeholder="Select registration status"
              options={constantsToOptions(constants?.registrationStatuses || [])}
              // disabled={employee}
              disabled={userInfo?.role !== UserRole.company}
            />
          </Form.Item>
        </Col>
      ) : null}
      {offboarding ? (
        <Col sm={12}>
          <Form.Item label="Offboarding Status" name="offBoardingStatus">
            <Select placeholder="Select offboarding status" options={constantsToOptions(constants?.offBoardingStatuses || [])} />
          </Form.Item>
        </Col>
      ) : null}
      <Col sm={12}>
        <Form.Item
          label="Start date"
          name="startDate"
          getValueProps={(i) => ({
            value: i ? dayjs(i) : '',
          })}
          rules={[{ required: true, message: 'This field is required!' }]}
        >
          <DatePicker
            format={dateFormat}
            className="w-100 p-12"
            disabledDate={disabledStartDate}
            onChange={(value) => setStartDate(dayjs(value).valueOf())}
          />
        </Form.Item>
      </Col>
      <Col sm={12}>
        <Form.Item
          label="End date"
          name="endDate"
          getValueProps={(i) => ({
            value: i ? dayjs(i) : '',
          })}
        >
          <DatePicker
            name="endDate"
            format={dateFormat}
            className="w-100 p-12"
            disabledDate={disabledEndDate}
            onChange={(value) => setEndDate(dayjs(value).valueOf())}
          />
        </Form.Item>
      </Col>
      <Col sm={12}>
        <Form.Item label="Country" name="countryOfHireID">
          <Select
            placeholder="Select country"
            filterOption={(input: string, option: any) => {
              return option?.name?.toLowerCase?.()?.indexOf(input?.toLowerCase()) >= 0;
            }}
            showSearch
          >
            {countries?.map((item: any, index: number) => {
              return (
                <Select.Option key={item?.id} value={item?.id} name={item?.name}>
                  <img src={item?.flag} width="16" height="12" alt={`${item}`} className="mr-2" />
                  {item?.name}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
      </Col>
      <Col sm={12}>
        <Form.Item label="Payroll System" name="payrollID">
          <Select placeholder="Select payroll system " options={settingsToOptions(settings?.payrolls || [])} />
        </Form.Item>
      </Col>
      {/* <Col sm={12}>
                <Form.Item label="Note" name="notes">
                  <Input.TextArea className="w-100" placeholder="Enter note" />
                </Form.Item>
              </Col> */}
    </Row>
  );
};

export default EmployeeRegistrationInfoForm;
