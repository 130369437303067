import { combineReducers } from '@reduxjs/toolkit';
import { appReducer } from './app';
import { profileReducer } from './profile';
import { settingsReducer } from './settings';
import { authReducer } from './auth';
import { newHireReducer } from './newHire';
import { userReducer } from './user';
import { employeeReducer } from './employee';
import { onboardingReducer } from './onboarding';
import { notificationReducer } from './notification';
import { documentSuitesReducer } from './documentSuites';
import { importsReducer } from './imports';
import { invoicesReducer } from './invoices';
import { offboardingReducer } from './offboarding';
import { messageReducer } from './messages';
import { messageRoomReducer } from './messageRooms';
import { dashboardReducer } from './dashboard';
import { payrollsReducer } from './payrolls';
import { reportsReducer } from './reports';
import { clientUsersReducer } from './clientUsers';
import { smtpsReducer } from './smtps';
import { insightReducer } from './insight';
import { activityLogReducer } from './activityLogs';
import { groupPermissionReducer } from './groupPermission';
import { userPermissionReducer } from './userPermission';
import { employeePortalReducer } from './employeePortal';
import { endClientsReducer } from './endClients';
import { companyUsersReducer } from './companyUsers';

export const rootReducer = combineReducers({
  app: appReducer,
  auth: authReducer,
  dashboard: dashboardReducer,
  profile: profileReducer,
  settings: settingsReducer,
  newHire: newHireReducer,
  user: userReducer,
  payrolls: payrollsReducer,
  employee: employeeReducer,
  onboarding: onboardingReducer,
  offboarding: offboardingReducer,
  activityLog: activityLogReducer,
  notification: notificationReducer,
  reports: reportsReducer,
  clientUsers: clientUsersReducer,
  smtps: smtpsReducer,
  insight: insightReducer,

  documentSuites: documentSuitesReducer,
  imports: importsReducer,
  invoices: invoicesReducer,
  messages: messageReducer,
  messageRooms: messageRoomReducer,

  groupPermission: groupPermissionReducer,
  userPermission: userPermissionReducer,
  employeePortal: employeePortalReducer,
  endClients: endClientsReducer,
  companyUsers: companyUsersReducer,
});
