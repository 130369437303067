import api from "@/common/api";
import { SLICE_NAME } from "@/common/constants/stores";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  IUser,
  IUserResponse,
  IPagination,
  IChangePasswordForm,
} from "@/types/app";
import { navigateTo } from "@/utils/auth";
import { openNotification } from "@/utils";
import { ROUTE_PATH } from "@/common/constants/routes";

export interface IUserState {
  data?: IUser[];
  loading: { [key: string]: boolean };
  pagination: IPagination;
  detail?: IUser;
}

export const initialState: IUserState = {
  pagination: {
    total: 0,
    page: 0,
    limit: 10,
    totalPage: 0,
  },
  data: [],
  loading: {},
};

const getUsers = createAsyncThunk(
  `${SLICE_NAME.USER}/getUsers`,
  async (query: any | undefined, { rejectWithValue }) => {
    try {
      const data = await api.getUsers<IUserResponse>(query);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const getUser = createAsyncThunk(
  `${SLICE_NAME.USER}/getUser`,
  async (query: any | undefined, { rejectWithValue }) => {
    try {
      const data = await api.getUser<{ data: IUser }>(query);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const createUser = createAsyncThunk(
  `${SLICE_NAME.USER}/createUser`,
  async (form: IUser, { rejectWithValue }) => {
    try {
      const data = await api.createUser<IUser>(form);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const updateUser = createAsyncThunk(
  `${SLICE_NAME.USER}/updateUser`,
  async (form: IUser, { rejectWithValue }) => {
    try {
      const data = await api.updateUser<IUser>(form);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const deleteUser = createAsyncThunk(
  `${SLICE_NAME.USER}/deleteUser`,
  async (query: any | undefined, { rejectWithValue }) => {
    try {
      const data = await api.deleteUser<{ data: IUser }>(query);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const changePassword = createAsyncThunk(
  `${SLICE_NAME.USER}/changePassword`,
  async (data: IChangePasswordForm, { rejectWithValue }) => {
    try {
      return api.changePassword<{ data: IUser }>(data);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const mapUserFromResponse = (response: IUser) => {
  return {
    ...response,
    createdAt: response.createdAt ? response.createdAt * 1000 : 0,
  };
};

const userSlice = createSlice({
  name: SLICE_NAME.USER,
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUsers.fulfilled, (state, { payload }) => {
      state.loading[getUsers.typePrefix] = false;
      if (payload?.data && payload?.data?.data) {
        state.data = payload?.data?.data.map((item) =>
          mapUserFromResponse(item)
        );
        state.pagination = payload?.data?.pagination;
      }
    });
    builder.addCase(getUsers.pending, (state, { payload }) => {
      state.loading[getUsers.typePrefix] = true;
    });
    builder.addCase(getUsers.rejected, (state, { payload }) => {
      state.loading[getUsers.typePrefix] = false;
    });
    builder.addCase(getUser.fulfilled, (state, { payload }) => {
      state.loading[getUser.typePrefix] = false;
      state.detail = mapUserFromResponse(payload.data.data);
    });
    builder.addCase(getUser.pending, (state, { payload }) => {
      state.loading[getUser.typePrefix] = true;
    });
    builder.addCase(getUser.rejected, (state, { payload }) => {
      state.loading[getUser.typePrefix] = false;
    });
    builder.addCase(createUser.fulfilled, (state, { payload }) => {
      state.loading[createUser.typePrefix] = false;
      // navigateTo(ROUTE_PATH.USER_MANAGEMENT);
      openNotification("success", "Create new user successfully!");
    });
    builder.addCase(createUser.pending, (state, { payload }) => {
      state.loading[createUser.typePrefix] = true;
    });
    builder.addCase(createUser.rejected, (state, { payload }) => {
      state.loading[createUser.typePrefix] = false;
    });
    builder.addCase(updateUser.fulfilled, (state, { payload }) => {
      state.loading[updateUser.typePrefix] = false;
      openNotification("success", "Update successfully!");
    });
    builder.addCase(updateUser.pending, (state, { payload }) => {
      state.loading[updateUser.typePrefix] = true;
    });
    builder.addCase(updateUser.rejected, (state, { payload }) => {
      state.loading[updateUser.typePrefix] = false;
    });
    builder.addCase(deleteUser.fulfilled, (state, { payload }) => {
      state.loading[deleteUser.typePrefix] = false;
      openNotification(
        "success",
        `${payload.data.data?.forename} ${payload.data.data?.surname} deleted successfully!`
      );
    });
    builder.addCase(deleteUser.pending, (state, { payload }) => {
      state.loading[deleteUser.typePrefix] = true;
    });
    builder.addCase(deleteUser.rejected, (state, { payload }) => {
      state.loading[deleteUser.typePrefix] = false;
    });
    builder.addCase(changePassword.pending, (state, { payload }) => {
      state.loading[changePassword.typePrefix] = true;
    });
    builder.addCase(changePassword.rejected, (state, { payload }) => {
      state.loading[changePassword.typePrefix] = false;
    });
    builder.addCase(changePassword.fulfilled, (state, { payload }) => {
      state.loading[changePassword.typePrefix] = false;
      openNotification("success", "Change password successfully!");
    });
  },
});

export const userReducer = userSlice.reducer;
export const userCaseReducers = userSlice.caseReducers;
export const userActions = {
  ...userSlice.actions,
  getUsers,
  getUser,
  createUser,
  updateUser,
  deleteUser,
  changePassword,
};
