import { Link, useNavigate } from "react-router-dom";
import { IRegisterForm } from "@/types/app";
import { Button, Form, Input, Select } from "antd";
import { ROUTE_PATH } from "@/common/constants/routes";
import { useDispatch } from "@/stores";
import { authActions } from "@/stores/auth";

const Signup = () => {
  const initialState = {
    forename: "",
    surname: "",
    name: "",
    email: "",
    password: "",
    role: "client",
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const transformBeforeSubmit = (e: IRegisterForm) => {
    return {
      ...e,
      forename: e.forename?.trim?.(),
      surname: e.surname?.trim?.(),
    } as IRegisterForm;
  };

  const handleSubmitForm = (e: IRegisterForm) => {
    const result = transformBeforeSubmit(e);
    dispatch(authActions.signup(result));
    navigate("/login");
  };

  return (
    <Form
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      layout="vertical"
      onFinish={handleSubmitForm}
      className="auth bg-white"
      initialValues={initialState}
    >
      <div className="auth-left">
        <div className="card-body">
          <div className="card-title">
            <h4 className="fw-bold mb-1 card-text text-capitalize">
              Welcome to Company
            </h4>
            <h4 className="fw-bold mb-1 card-text text-capitalize">Sign up!</h4>
          </div>
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: "This field is required!" }]}
          >
            <Input placeholder="Enter email" type="email" />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[
              { required: true, message: "This field is required!" },
              {
                pattern: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$/,
                message:
                  "Password must contain at least one number and one uppercase and lowercase letter, and at least 6 characters",
              },
            ]}
          >
            <Input placeholder="Enter password" type="password" />
          </Form.Item>
          <Form.Item
            label="Username"
            name="name"
            rules={[{ required: true, message: "This field is required!" }]}
          >
            <Input placeholder="Enter username" />
          </Form.Item>
          <Form.Item
            label="Forename"
            name="forename"
            rules={[
              { required: true, message: "This field is required!" },
              {
                pattern: /[a-zA-Z ]+$/,
                message: "Please enter on alphabets only.",
              },
            ]}
          >
            <Input placeholder="Enter forename" />
          </Form.Item>
          <Form.Item
            label="Surname"
            name="surname"
            rules={[
              { required: true, message: "This field is required!" },
              {
                pattern: /[a-zA-Z ]+$/,
                message: "Please enter on alphabets only.",
              },
            ]}
          >
            <Input placeholder="Enter surname" />
          </Form.Item>

          {/* <Form.Item label="Department" name="department">
                <Select options={enumOptions.Department} />
            </Form.Item> */}

          <div className="form-footer">
            <Button type="primary" htmlType="submit" className="btn-block">
              Sign up
            </Button>
          </div>
          <div className="text-center text-muted mt-4">
            Already have account? <Link to={ROUTE_PATH.LOGIN}>Sign In</Link>
          </div>
        </div>
      </div>
      <div className="signup-img auth-right"></div>
    </Form>
  );
};

export default Signup;
