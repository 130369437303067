import { IChangePasswordForm } from "@/types/app";
import { Form, Input, Modal } from "antd";
import { useDispatch } from "@/stores";
import { userActions } from "@/stores/user";
import { useParams } from "react-router-dom";
const ChangePasswordModal = (props: any) => {
  const { isOpen, setIsOpen } = props;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { id } = useParams();

  const initialState: IChangePasswordForm = {
    id: "",
    password: "",
  };

  const handleCloseModal = () => {
    form.resetFields();
    setIsOpen(false);
  };

  const handleSubmitForm = (e: IChangePasswordForm) => {
    dispatch(userActions.changePassword({ ...e, id: id }));
    handleCloseModal();
  };

  return (
    <Modal
      title="Change Password"
      open={isOpen}
      onOk={form.submit}
      okText="Change"
      onCancel={() => handleCloseModal()}
      width={400}
      centered
    >
      <Form
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        layout="vertical"
        initialValues={initialState}
        onFinish={handleSubmitForm}
        form={form}
      >
        <div className="row">
          <div className="col-lg-12">
            <Form.Item
              label="Password"
              name="password"
              rules={[
                { required: true, message: "This field is required!" },
                {
                  pattern: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$/,
                  message:
                    "Password must contain at least one number and one uppercase and lowercase letter, and at least 6 characters",
                },
              ]}
            >
              <Input placeholder="Enter password" type="password" />
            </Form.Item>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default ChangePasswordModal;
