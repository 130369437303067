import api from '@/common/api';
import { SLICE_NAME } from '@/common/constants/stores';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { IInsight, IInsightResponse, IPagination, IChangePasswordForm, IAttachment } from '@/types/app';
import { navigateTo } from '@/utils/auth';
import { openNotification } from '@/utils';
import { ROUTE_PATH } from '@/common/constants/routes';
import { uploadS3 } from '@/common/api/s3';

export interface IInsightState {
  data?: IInsight[];
  loading: { [key: string]: boolean };
  pagination: IPagination;
  detail?: IInsight;
}

export const initialState: IInsightState = {
  pagination: {
    total: 0,
    page: 0,
    limit: 10,
    totalPage: 0,
  },
  data: [],
  loading: {},
};

const getInsights = createAsyncThunk(`${SLICE_NAME.INSIGHT}/getInsights`, async (query: any | undefined, { rejectWithValue }) => {
  try {
    const data = await api.getInsights<IInsightResponse>(query);
    return data;
  } catch (e) {
    return rejectWithValue(e);
  }
});

const getInsight = createAsyncThunk(`${SLICE_NAME.INSIGHT}/getInsight`, async (query: any | undefined, { rejectWithValue }) => {
  try {
    const data = await api.getInsight<{ data: IInsight }>(query);
    return data;
  } catch (e) {
    return rejectWithValue(e);
  }
});

const createInsight = createAsyncThunk(`${SLICE_NAME.INSIGHT}/createInsight`, async (form: IInsight, { rejectWithValue }) => {
  try {
    let attachments: IAttachment[] = [];
    if (form?.attachments && Array.isArray(form.attachments) && form.attachments.length > 0) {
      const requests = form.attachments
        .filter((attachment) => attachment instanceof File)
        .map((attachment) => uploadS3(attachment, 'azure_insight'));
      const responses = await Promise.all(requests);
      attachments = responses.filter((response) => response.success).map((response) => response.data);
    }
    form = {
      ...form,
      attachments,
    };
    const data = await api.createInsight<IInsight>(form);
    return data;
  } catch (e) {
    return rejectWithValue(e);
  }
});

const updateInsight = createAsyncThunk(`${SLICE_NAME.INSIGHT}/updateInsight`, async (form: IInsight, { rejectWithValue }) => {
  try {
    let attachments: IAttachment[] = [];
    if (form?.attachments && Array.isArray(form.attachments) && form.attachments.length > 0) {
      const oldAttachments = form.attachments
        .filter((attachment) => !(attachment instanceof File))
        .map((attachment: any) => {
          return {
            contentType: attachment?.type,
            fileKey: attachment?.uid,
            fileURL: attachment?.url,
            metadata: {
              name: attachment?.name,
              size: attachment?.size,
            },
          };
        });
      const requests = form.attachments
        .filter((attachment) => attachment instanceof File)
        .map((attachment) => uploadS3(attachment, 'azure_insight'));
      const responses = await Promise.all(requests);
      attachments = responses.filter((response) => response.success).map((response) => response.data);
      attachments = [...oldAttachments, ...attachments];
    }
    form = {
      ...form,
      attachments,
    };
    const data = await api.updateInsight<IInsight>(form);
    return data;
  } catch (e) {
    return rejectWithValue(e);
  }
});

const deleteInsight = createAsyncThunk(`${SLICE_NAME.INSIGHT}/deleteInsight`, async (query: any | undefined, { rejectWithValue }) => {
  try {
    const data = await api.deleteInsight<{ data: IInsight }>(query);
    return data;
  } catch (e) {
    return rejectWithValue(e);
  }
});

const changePassword = createAsyncThunk(`${SLICE_NAME.INSIGHT}/changePassword`, async (data: IChangePasswordForm, { rejectWithValue }) => {
  try {
    return api.changePassword<{ data: IInsight }>(data);
  } catch (err) {
    return rejectWithValue(err);
  }
});

const markAsSeenInsight = createAsyncThunk(`${SLICE_NAME.INSIGHT}/markAsSeenInsight`, async (form: any, { rejectWithValue }) => {
  try {
    const data = await api.markAsSeenInsight<IInsight>(form);
    return data;
  } catch (e) {
    return rejectWithValue(e);
  }
});

export const mapInsightFromResponse = (response: IInsight) => {
  return {
    ...response,
    createdAt: response.createdAt ? response.createdAt * 1000 : 0,
  };
};

const insightSlice = createSlice({
  name: SLICE_NAME.INSIGHT,
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getInsights.fulfilled, (state, { payload }) => {
      state.loading[getInsights.typePrefix] = false;
      if (payload?.data && payload?.data?.data) {
        state.data = payload?.data?.data.map((item) => mapInsightFromResponse(item));
        state.pagination = payload?.data?.pagination;
      }
    });
    builder.addCase(getInsights.pending, (state, { payload }) => {
      state.loading[getInsights.typePrefix] = true;
    });
    builder.addCase(getInsights.rejected, (state, { payload }) => {
      state.loading[getInsights.typePrefix] = false;
    });
    builder.addCase(getInsight.fulfilled, (state, { payload }) => {
      state.loading[getInsight.typePrefix] = false;
      state.detail = mapInsightFromResponse(payload.data.data);
    });
    builder.addCase(getInsight.pending, (state, { payload }) => {
      state.loading[getInsight.typePrefix] = true;
    });
    builder.addCase(getInsight.rejected, (state, { payload }) => {
      state.loading[getInsight.typePrefix] = false;
    });
    builder.addCase(createInsight.fulfilled, (state, { payload }) => {
      state.loading[createInsight.typePrefix] = false;
      // navigateTo(ROUTE_PATH.INSIGHT_MANAGEMENT);
      openNotification('success', 'Create new insight successfully!');
    });
    builder.addCase(createInsight.pending, (state, { payload }) => {
      state.loading[createInsight.typePrefix] = true;
    });
    builder.addCase(createInsight.rejected, (state, { payload }) => {
      state.loading[createInsight.typePrefix] = false;
    });
    builder.addCase(updateInsight.fulfilled, (state, { payload }) => {
      state.loading[updateInsight.typePrefix] = false;
      openNotification('success', 'Update successfully!');
    });
    builder.addCase(updateInsight.pending, (state, { payload }) => {
      state.loading[updateInsight.typePrefix] = true;
    });
    builder.addCase(updateInsight.rejected, (state, { payload }) => {
      state.loading[updateInsight.typePrefix] = false;
    });
    builder.addCase(deleteInsight.fulfilled, (state, { payload }) => {
      state.loading[deleteInsight.typePrefix] = false;
      openNotification('success', `Delete successfully!`);
    });
    builder.addCase(deleteInsight.pending, (state, { payload }) => {
      state.loading[deleteInsight.typePrefix] = true;
    });
    builder.addCase(deleteInsight.rejected, (state, { payload }) => {
      state.loading[deleteInsight.typePrefix] = false;
    });
    builder.addCase(changePassword.pending, (state, { payload }) => {
      state.loading[changePassword.typePrefix] = true;
    });
    builder.addCase(changePassword.rejected, (state, { payload }) => {
      state.loading[changePassword.typePrefix] = false;
    });
    builder.addCase(changePassword.fulfilled, (state, { payload }) => {
      state.loading[changePassword.typePrefix] = false;
      openNotification('success', 'Change password successfully!');
    });
    builder.addCase(markAsSeenInsight.fulfilled, (state, { payload }) => {
      state.loading[markAsSeenInsight.typePrefix] = false;
    });
    builder.addCase(markAsSeenInsight.pending, (state, { payload }) => {
      state.loading[markAsSeenInsight.typePrefix] = true;
    });
    builder.addCase(markAsSeenInsight.rejected, (state, { payload }) => {
      state.loading[markAsSeenInsight.typePrefix] = false;
    });
  },
});

export const insightReducer = insightSlice.reducer;
export const insightCaseReducers = insightSlice.caseReducers;
export const insightActions = {
  ...insightSlice.actions,
  getInsights,
  getInsight,
  createInsight,
  updateInsight,
  deleteInsight,
  changePassword,
  markAsSeenInsight,
};
