import { dev } from "./dev";
import { local } from "./local";
import { prod } from "./prod";

const env = process.env.REACT_APP_BUILD_ENV;

const envObj = {
  local: { ...local },
  dev: { ...dev },
  prod: { ...prod },
};

/**
 * @type {typeof prod}
 **/
// @ts-ignore
const config = envObj[env || "local"];

export default config;
