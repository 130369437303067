import { IEmployee, IEmployeeListTable } from "@/types/app";
import { Button, Input, Popconfirm, Space, Spin, Tag, Tooltip } from "antd";
import { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { Link, useLocation } from "react-router-dom";
import {
  EyeTwoTone,
  StopTwoTone,
  DeleteTwoTone,
  DownCircleTwoTone,
  DownCircleOutlined,
} from "@ant-design/icons";
import { useEffect, useState } from "react";
import RequestTerminationModal from "@/components/Modals/RequestTerminationModal";
import { dateFormat, pageSizeOptions } from "@/common/constants/format";
import TableWithScrollButtons from "@/components/Shared/TableWithScrollButtons";
import axios from "axios";
import { useDispatch, useSelector } from "@/stores";
import { employeeActions } from "@/stores/employee";
import dayjs from "dayjs";
import {
  EmployeeStatus,
  OffboardingStatus,
  UserRole,
} from "@/common/constants/enums";
import Paragraph from "antd/es/typography/Paragraph";
import { generateClientRef } from "@/utils";
import { isRootAdmin } from "@/utils/auth";
import { DeleteIcon, ExportIcon, EyeIcon, OffboardIcon, SearchIcon } from "@/components/common/IconComponents";
import { EMPLOYEE_COLUMNS_AND_NAMES, IEmployeeColumnAndName } from "@/common/constants/columns";
import { reportsActions } from "@/stores/reports";
import { SorterResult } from "antd/es/table/interface";
const EmployeeList = () => {
  const [selectedId, setSelectedId] = useState(-1);
  const [isRequestTerminationModalOpen, setIsRequestTerminationModalOpen] =
    useState(false);

  const dispatch = useDispatch();
  const { data, loading, pagination } = useSelector((state) => state.employee);
  const { userInfo } = useSelector((state) => state.auth);

  const location = useLocation();
  const search = new URLSearchParams(location?.search);
  const countryOfHireID = search?.get("countryOfHireID") || "";
  const [searchText, setSearchText] = useState('');


  // useEffect(function () {
  //   dispatch(employeeActions.getEmployees({ role: userInfo?.role }));
  // }, []);

  const columns: ColumnsType<IEmployee> = [
    // {
    //   title: "Client Ref",
    //   dataIndex: "referenceID",
    //   key: "referenceID",
    //   width: 200,
    //   render: (_, record) => (
    //     <span>
    //       {generateClientRef({
    //         prefix: record?.client?.prefix,
    //         referenceID: record?.referenceID,
    //       })}
    //     </span>
    //   ),
    // },
    {
      title: 'Employee',
      dataIndex: 'name',
      key: 'name',
      width: 250,
      fixed: 'left',
      sorter: true,
      render: (name, record) => (
        <div className="general-information-table">
          <p className="title">{name}</p>
          <p className="">{record.email ? record.email : ''}</p>
        </div>
      ),
    },
    {
      title: 'Job Title',
      dataIndex: 'jobTitle',
      key: 'jobTitle',
      width: 150,
      sorter: true,
    },
    {
      title: 'Client Name',
      dataIndex: 'clientName',
      key: 'client',
      width: 200,
      sorter: true,
      render: (_, record) => (
        <span>
          {record?.client?.forename} {record?.client?.surname}
        </span>
      ),
    },
    {
      title: 'Nationality',
      dataIndex: 'employeeNationality',
      key: 'employeeNationality',
      width: 200,
      sorter: true,
      render: (employeeNationality) =>
        employeeNationality?.id ? (
          <Space>
            <img src={employeeNationality?.flag} width="16" height="12" alt={`${employeeNationality?.name}`} className="mr-2" />
            {employeeNationality?.name}
          </Space>
        ) : null,
    },
    {
      title: 'Country of Hire',
      dataIndex: 'countryOfHire',
      key: 'countryOfHire',
      width: 200,
      sorter: true,
      render: (countryOfHire) =>
        countryOfHire?.id ? (
          <Space>
            <img src={countryOfHire?.flag} width="16" height="12" alt={`${countryOfHire?.name}`} className="mr-2" />
            {countryOfHire?.name}
          </Space>
        ) : null,
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      key: 'startDate',
      width: 200,
      sorter: true,
      render: (val) => (val ? dayjs(val).format(dateFormat) : null),
    },
    {
      title: 'Contract End Date',
      dataIndex: 'endDate',
      key: 'endDate',
      width: 200,
      sorter: true,
      render: (value) => (value ? dayjs(value).format(dateFormat) : <Tag>Ongoing</Tag>),
    },
    {
      title: 'Anticipated End Date',
      dataIndex: 'anticipatedEndDate',
      key: 'anticipatedEndDate',
      width: 200,
      sorter: true,
      render: (val) => (val ? dayjs(val).format(dateFormat) : ''),
    },
    {
      title: 'Reason For Termination',
      dataIndex: 'reasonForTermination',
      key: 'reasonForTermination',
      width: 200,
      sorter: true,
      render: (value) => {
        return (
          <Paragraph
            ellipsis={{
              rows: 2,
              expandable: false,
              tooltip: value,
            }}
            className="mb-0"
          >
            {value}
          </Paragraph>
        );
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      fixed: 'right',
      width: 120,
      render: (status) => (
        // const result = matchValueToLabel(
        //   constants?.offBoardingStatuses || [],
        //   value
        // );
        // // return (<b style={{color: result.color}}>{result.label}</b>)
        // console.log("result", result);

        // if (result)
        //   return (
        <Tag className="rounded text-uppercase active-tag">{status}</Tag>
        // );
      ),
    },
    {
      title: 'Actions',
      key: 'action',
      fixed: 'right',
      width: 150,
      render: (_, record) => (
        <Space>
          <Link to={_.id}>
            <Tooltip title="View Detail">
              <Button
                type="text"
                size="small"
                onClick={() => {
                  setSelectedId(_.id);
                  setIsRequestTerminationModalOpen(true);
                }}
              >
                <EyeIcon />
              </Button>
            </Tooltip>
          </Link>
          {userInfo?.role === UserRole.client ? (
            <Tooltip title="Request Termination">
              <Button
                type="text"
                onClick={() => {
                  setSelectedId(_.id);
                  setIsRequestTerminationModalOpen(true);
                }}
                size="small"
              >
                <OffboardIcon />
              </Button>
            </Tooltip>
          ) : null}
          <Popconfirm
            title="Offboard this employee"
            description="Are you sure you want to proceed?"
            onConfirm={async function () {
              await dispatch(
                employeeActions.offboardEmployee({
                  id: record.id,
                  role: userInfo?.role,
                }),
              );
              await dispatch(employeeActions.getEmployees({ role: userInfo?.role }));
            }}
            okText="Yes"
            cancelText="No"
          >
            <Tooltip title="Offboard">
              <Button type="text" size="small">
                <DownCircleOutlined />
              </Button>
            </Tooltip>
          </Popconfirm>
          {userInfo?.role === UserRole.company ? (
            <Popconfirm
              title="Delete this employee"
              description="Are you sure you want to delete this employee?"
              onConfirm={async function () {
                await dispatch(
                  employeeActions.deleteEmployee({
                    id: record.id,
                    role: userInfo?.role,
                  }),
                );
                await dispatch(employeeActions.getEmployees({ role: userInfo?.role }));
              }}
              okText="Yes"
              cancelText="No"
            >
              <Tooltip title="Delete">
                <Button type="text" size="small">
                  <DeleteIcon />
                </Button>
              </Tooltip>
            </Popconfirm>
          ) : null}
        </Space>
      ),
    },
  ];

  const handleSearchSubmit = (e: string) => {
    const keyword = e.trim();
    setSearchText(keyword);
    dispatch(employeeActions.getEmployees({ keyword, role: userInfo?.role }));
  };

  const handlePaginationChange = (page: number, pageSize: number) => {
    dispatch(
      employeeActions.getEmployees({
        page,
        limit: pageSize,
        role: userInfo?.role,
        countryOfHireID,
      })
    );
  };

  const handleSortAndPaginationChange = (
    pagination: TablePaginationConfig,
    sorter: SorterResult<IEmployee> | SorterResult<IEmployee>[],
  ) => {
      dispatch(
        employeeActions.getEmployees({
          page: pagination.current,
          limit: pagination.pageSize,
          keyword: searchText,
          sortBy: !Array.isArray(sorter) && sorter.order ? `${sorter.field} ${sorter.order === 'ascend' ? 'ASC' : 'DESC'}` : undefined,
          role: userInfo?.role,
          countryOfHireID,
        }),
      );
  };

  const handleExportCSV = () => {
    const newFields = EMPLOYEE_COLUMNS_AND_NAMES?.map((field: IEmployeeColumnAndName) => {
      return {
        value: field?.value,
        label: field?.name,
      };
    });
    dispatch(
      reportsActions.exportEmployeesCSV({
        role: userInfo?.role,
        fields: newFields,
      })
    )
      .unwrap()
      .then((resp) => {
        const element = document.createElement("a");
        const file = new Blob([resp?.data?.data?.data as any], {
          type: "text/csv",
        });
        const url = URL.createObjectURL(file);
        element.href = url;
        element.download = resp?.data?.data?.fileName || "export_employees.csv";
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
        document.body.removeChild(element);
      });
  };

  useEffect(() => {
    // if (location.search.includes("?keyword=")) {
    //   setSearchText(keyword || "");
    //   handleSearchSubmit(keyword || "");
    // } else
    if (userInfo?.role !== UserRole.admin) {
      if (location.search && countryOfHireID) {
        dispatch(
          employeeActions.getEmployees({
            countryOfHireID,
            role: userInfo?.role,
          })
        );
      } else dispatch(employeeActions.getEmployees({ role: userInfo?.role }));
    }
  }, [location.search]);

  return (
    <div className="container-fluid pt-4">
      <Spin tip="Loading" size="large" spinning={loading[employeeActions.getEmployees.typePrefix]}>
        <p className="page-listing-title">Manage Employee</p>
        <div className="bg-white rounded">
          <div className="card-header d-flex justify-content-between">
            <Input
              prefix={<SearchIcon className="mr-0" />}
              placeholder="Search"
              onPressEnter={(e: any) => handleSearchSubmit(e?.target?.value)}
              className="w-100 mr-2 page-listing-search-input"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
            <Button
              type="primary"
              ghost
              onClick={handleExportCSV}
              className="d-flex align-item-center"
              // disabled={reviewEmployeesReport?.length <= 0}
            >
              <ExportIcon />
              Export
            </Button>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <TableWithScrollButtons
                columns={columns}
                dataSource={data}
                size="small"
                scroll={{ x: 1200 }}
                onChange={(pagination, filter, sorter) => {
                  handleSortAndPaginationChange(pagination, sorter);
                }}
                pagination={{
                  // onChange: (page: number, pageSize: number) => handlePaginationChange(page, pageSize),
                  showSizeChanger: true,
                  defaultCurrent: 1,
                  total: pagination.total,
                  position: ['bottomLeft'],
                  pageSizeOptions: pageSizeOptions,
                  showTotal: (total: number, range: number[]) => 'Showing ' + range[0] + ' to ' + range[1] + ' of ' + total + ' entries',
                }}
              />
            </div>
          </div>
        </div>
        <RequestTerminationModal
          employeeId={selectedId}
          isOpen={isRequestTerminationModalOpen}
          setIsOpen={(val: boolean) => setIsRequestTerminationModalOpen(val)}
        />
      </Spin>
    </div>
  );
};

export default EmployeeList;
