import { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from "apexcharts";
import { Badge, Tag } from 'antd';

const Donutchart = (props: any):JSX.Element => {
    const options : ApexOptions = {
        chart: {
            type: 'donut',
            width: '100%',
            height: '100%',
        },
        labels: props?.label,
        fill: {
            colors: props?.colors,
        },
        legend: {
            show: false,
            position: 'bottom',
            markers: {
                fillColors: props?.colors,
            },
        },
        dataLabels: {
            enabled: false,
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    chart: {
                        width: "100%",
                    },
                    legend: {
                        position: 'bottom',
                    },
                },
            },
        ],
    }

    const [series, setSeries] = useState<any>([]);

    useEffect(function(){
        setSeries(props?.series);
    },[props?.series])

    return (
      <div>
        <div id="chart" className="donutchart">
          <ReactApexChart width={'100%'} height={200} options={options} series={series} type="donut" />
          <div className='total'>
            <p className="number mb-0">{props?.total}</p>
            <p className="text mb-0">Total Emp.</p>
          </div>
          {props?.label?.map((item: string, index: number) => {
            return (
              <p className={`legend ${index < props?.label?.length - 1 ? 'border-bottom' : ''} d-flex justify-content-between py-2 mb-0`}>
                <span>
                  <Badge className="mr-2" color={props?.colors?.[index]}></Badge>
                  {item}
                </span>{' '}
                <span>
                  <span className="percent mr-2">{series?.[index]?(Math.round((series?.[index] / props?.total) * 100 * 100) / 100).toFixed(0):0}%</span>
                  <span>{series?.[index]||0} emp.</span>
                </span>
              </p>
            );
          })}
        </div>
      </div>
    );
}

export default Donutchart;
