import { ExtractParams } from '@/types/url';

const endpoints = {
  // Common
  getConstants: 'GET /api/v1/common/constants',

  getSharedUsers: 'GET /api/v1/users',
  getSharedEmployees: 'GET /api/v1/employees',
  getS3Signature: 'POST /api/v1/common/s3-signature',
  getSharedPayrolls: 'GET /api/v1/payrolls',
  getSettings: 'GET /api/v1/settings',
  getCountries: 'GET /api/v1/countries',

  // Auth
  login: 'POST /api/v1/auth/login',
  refreshToken: 'POST /api/v1/auth/refresh-token',
  forgotPassword: 'POST /api/v1/auth/forgot_password',
  resetPassword: 'POST /api/v1/auth/reset_password',

  // Me
  getMe: 'GET /api/v1/me',
  getCurrentEmployee: 'GET /api/v1/me/current_employee',
  updateMe: 'PUT /api/v1/me',

  // Dashboard
  getDashboardSummaryEmployees: 'GET /api/v1/dashboard/employees/summary',
  getDashboardTopEmployees: 'GET /api/v1/dashboard/employees/top',
  getDashboardStaticsOnboardingEmployees: 'GET /api/v1/dashboard/employees/onboarding/statics',
  getDashboardStaticsOffBoardingEmployees: 'GET /api/v1/dashboard/employees/off-boarding/statics',
  getDashboardOnboardingEmployees: 'GET /api/v1/dashboard/employees/onboarding',
  getDashboardOffBoardingEmployees: 'GET /api/v1/dashboard/employees/off-boarding',
  getDashboardAllCountries: 'GET /api/v1/dashboard/countries',
  getDashboardAllClients: 'GET /api/v1/dashboard/clients',
  getDashboardAllEndClients: 'GET /api/v1/dashboard/end_clients',
  getDashboardStatistic: 'GET /api/v1/dashboard/statistic',

  // Insight
  getInsights: 'GET /api/v1/insights',
  getInsight: 'GET /api/v1/insights/:id',
  createInsight: 'POST /api/v1/insights',
  updateInsight: 'PUT /api/v1/insights/:id',
  deleteInsight: 'DELETE /api/v1/insights/:id',
  markAsSeenInsight: 'PUT /api/v1/insights/:id/mark_as_seen',

  // User
  getUsers: 'GET /api/v1/admin/users',
  getUser: 'GET /api/v1/admin/users/:id',
  createUser: 'POST /api/v1/admin/users',
  updateUser: 'PUT /api/v1/admin/users/:id',
  deleteUser: 'DELETE /api/v1/admin/users/:id',
  changePassword: 'POST /api/v1/admin/users/:id/change_password',
  loginAsUser: 'POST /api/v1/admin/users/:id/login_as',

  // Payrolls
  getPayrolls: 'GET /api/v1/admin/payrolls',
  createPayroll: 'POST /api/v1/admin/payrolls',
  getPayroll: 'GET /api/v1/admin/payrolls/:id',
  updatePayroll: 'PUT /api/v1/admin/payrolls/:id',
  deletePayroll: 'DELETE /api/v1/admin/payrolls/:id',

  // New Hire
  getNewHires: 'GET /api/v1/:role/new-hires',
  getNewHire: 'GET /api/v1/:role/new-hires/:id',
  createNewHire: 'POST /api/v1/:role/new-hires',
  updateNewHire: 'PUT /api/v1/:role/new-hires/:id',
  deleteNewHire: 'DELETE /api/v1/:role/new-hires/:id',
  onboardNewHire: 'POST /api/v1/company/new-hires/:id/onboarding',

  // Employee
  getEmployees: 'GET /api/v1/:role/employees',
  getEmployee: 'GET /api/v1/:role/employees/:id',
  updateEmployee: 'PUT /api/v1/:role/employees/:id',
  deleteEmployee: 'DELETE /api/v1/:role/employees/:id',
  offboardEmployee: 'POST /api/v1/:role/employees/:id/off-boarding',
  requestTerminationEmployee: 'POST /api/v1/client/employees/:id/request-termination',
  sendProfileEmployee: 'POST /api/v1/client/employees/:id/send_profile',

  getSharedEmployeeBanks: 'GET /api/v1/employees/:id/banks',
  getEmployeeTimesheets: 'GET /api/v1/:role/employees/:id/documents/timesheets',
  getEmployeePayslips: 'GET /api/v1/:role/employees/:id/documents/payslips',
  getEmployeeOthers: 'GET /api/v1/:role/employees/:id/documents/others',
  getEmployeeGuides: 'GET /api/v1/:role/employees/:id/documents/guides',

  createEmployeeBank: 'POST /api/v1/employees/:id/banks',
  updateEmployeeBank: 'PUT /api/v1/employees/:id/banks/:bankId',
  createEmployeeTimesheets: 'POST /api/v1/:role/employees/:id/documents/timesheets',
  createEmployeePayslips: 'POST /api/v1/:role/employees/:id/documents/payslips',
  createEmployeeOthers: 'POST /api/v1/:role/employees/:id/documents/others',

  getEmployeeNotes: 'GET /api/v1/:role/employees/:id/notes',
  createEmployeeNote: 'POST /api/v1/:role/employees/:employeeID/notes',
  updateEmployeeNote: 'PUT /api/v1/:role/employees/:employeeID/notes/:noteID',

  // Onboarding
  getOnboardings: 'GET /api/v1/:role/onboarding',
  getOnboarding: 'GET /api/v1/:role/onboarding/:id',
  updateOnboarding: 'PUT /api/v1/:role/onboarding/:id',
  updateOnboardingStatus: 'PUT /api/v1/:role/onboarding/:id/change_status',
  deleteOnboarding: 'DELETE /api/v1/:role/onboarding/:id',
  getOnboardingNotes: 'GET /api/v1/:role/onboarding/:id/notes',
  createOnboardingNote: 'POST /api/v1/:role/onboarding/:employeeID/notes',
  updateOnboardingNote: 'PUT /api/v1/:role/onboarding/:employeeID/notes/:noteID',

  // Offboarding
  getOffboardings: 'GET /api/v1/:role/off-boarding',
  getOffboarding: 'GET /api/v1/:role/off-boarding/:id',
  updateOffboarding: 'PUT /api/v1/:role/off-boarding/:id',
  updateOffboardingStatus: 'PUT /api/v1/:role/off-boarding/:id/change_status',
  deleteOffboarding: 'DELETE /api/v1/:role/off-boarding/:id',
  getOffboardingNotes: 'GET /api/v1/:role/off-boarding/:id/notes',
  createOffboardingNote: 'POST /api/v1/:role/off-boarding/:employeeID/notes',
  updateOffboardingNote: 'PUT /api/v1/:role/off-boarding/:employeeID/notes/:noteID',

  // Group Permissions
  getGroupPermissions: 'GET /api/v1/:role/permission_groups',
  createGroupPermission: 'POST /api/v1/:role/permission_groups',
  getGroupPermission: 'GET /api/v1/:role/permission_groups/:id',
  updateGroupPermission: 'PUT /api/v1/:role/permission_groups/:id',
  deleteGroupPermission: 'DELETE /api/v1/:role/permission_groups/:id',

  // User Permissions
  getUserPermissions: 'GET /api/v1/:role/user_permission/users',
  createUserPermission: 'POST /api/v1/:role/user_permission/users',
  setUserPermission: 'PUT /api/v1/:role/user_permission/users/:userId',
  getUserPermission: 'GET /api/v1/:role/user_permission/users/:userId',
  deleteUserPermission: 'DELETE /api/v1/:role/user_permission/users/:userId',

  // Offboarding
  getActivityLogs: 'GET /api/v1/activity_logs',
  getActivityLog: 'GET /api/v1/activity_logs/:employeeID',

  // Notification
  getNotifications: 'GET /api/v1/notifications',
  getNotification: 'GET /api/v1/notifications/:id',
  createNotification: 'POST /api/v1/notifications',
  updateNotification: 'PUT /api/v1/notifications/:id',
  deleteNotification: 'DELETE /api/v1/notifications/:id',
  markAsSeenNotification: 'POST /api/v1/notifications/:id/mark_as_seen',

  // Documents
  getDocuments: 'GET /api/v1/documents',
  getDocument: 'GET /api/v1/documents/:id',
  createDocument: 'POST /api/v1/documents',
  updateDocument: 'PUT /api/v1/documents/:id',
  deleteDocument: 'DELETE /api/v1/documents/:id',

  // Message Rooms
  getMessageRooms: 'GET /api/v1/chat-rooms',
  getMessageRoom: 'GET /api/v1/chat-rooms/:id',
  createMessageRoom: 'POST /api/v1/chat-rooms',
  updateMessageRoom: 'PUT /api/v1/chat-rooms/:id',
  deleteMessageRoom: 'DELETE /api/v1/chat-rooms/:id',
  getMessageRoomStatistics: 'GET /api/v1/chat-rooms/statics',
  markAsSeenMessageRoom: 'PUT /api/v1/chat-rooms/:id/mark_as_seen',

  // Messages
  getMessages: 'GET /api/v1/chat-rooms/:chatRoomID/messages',
  getMessage: 'GET /api/v1/chat-rooms/:chatRoomID/messages/:id',
  createMessage: 'POST /api/v1/chat-rooms/:chatRoomID/messages',
  updateMessage: 'PUT /api/v1/chat-rooms/:chatRoomID/messages/:id',
  deleteMessage: 'DELETE /api/v1/chat-rooms/:chatRoomID/messages/:id',

  // Report
  previewEmployeesCSV: 'POST /api/v1/:role/employees/preview_csv',
  exportEmployeesCSV: 'POST /api/v1/:role/employees/export_csv',
  getReports: 'GET /api/v1/:role/reports',
  createReport: 'POST /api/v1/:role/reports',

  // client Users
  getClientUsers: 'GET /api/v1/client/users',
  getClientUser: 'GET /api/v1/client/users/:id',
  createClientUser: 'POST /api/v1/client/users',
  updateClientUser: 'PUT /api/v1/client/users/:id',
  deleteClientUser: 'DELETE /api/v1/client/users/:id',
  changePasswordClientUser: 'POST /api/v1/client/users/:id/change_password',
  loginAsClientUser: 'POST /api/v1/client/users/:id/login_as',

  // Smtp
  getSmtps: 'GET /api/v1/admin/smtps',
  createSmtp: 'POST /api/v1/admin/smtps',
  getSmtp: 'GET /api/v1/admin/smtps/:id',
  updateSmtp: 'PUT /api/v1/admin/smtps/:id',
  deleteSmtp: 'DELETE /api/v1/admin/smtps/:id',

  //Employee Portal
  getCurrentEmployeeTimesheets: 'GET /api/v1/employee/current/documents/timesheets',
  getCurrentEmployeePayslips: 'GET /api/v1/employee/current/documents/timesheets',
  getCurrentEmployeeOthers: 'GET /api/v1/employee/current/documents/timesheets',
  getCurrentEmployeeGuides: 'GET /api/v1/employee/current/documents/timesheets',
  getCurrentEmployeeNotes: 'GET /api/v1/employee/current/notes',
  getCurrentEmployeeBanks: 'GET /api/v1/employee/current/banks',

  // Bank names
  getBanksName: 'GET /api/v1/bank_names',
  createBanksName: 'POST /api/v1/bank_names',

  // End Clients
  getEndClients: 'GET /api/v1/end_clients',
  getEndClient: 'GET /api/v1/end_clients/:id',
  createEndClient: 'POST /api/v1/end_clients',
  updateEndClient: 'PUT /api/v1/end_clients/:id',
  deleteEndClient: 'DELETE /api/v1/end_clients/:id',

  // Company Users
  getCompanyUsers: 'GET /api/v1/company/users',
  getCompanyUser: 'GET /api/v1/company/users/:id',
  createCompanyUser: 'POST /api/v1/company/users',
  updateCompanyUser: 'PUT /api/v1/company/users/:id',
  deleteCompanyUser: 'DELETE /api/v1/company/users/:id',
} as const;

export type EndpointsParams = {
  [K in keyof typeof endpoints]: ExtractParams<(typeof endpoints)[K]>;
};

export default endpoints;
