import api from '@/common/api';
import { SLICE_NAME } from '@/common/constants/stores';
import { IConstants, ICountry, ICountryConstant, IEmployee, IObject, IPayroll, ISettings, IUser } from '@/types/app';
import { getToken, isTokenValid, setRefreshToken, setToken } from '@/utils/auth';
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';

export interface IAppState {
  isInitialized: boolean;
  isAuthenticated: boolean;
  token?: string;
  refreshToken?: string;
  constants?: IConstants;
  profile?: IUser;
  currentEmployee?: IEmployee;
  loading: { [x: string]: boolean };
  countries: ICountry[];
  settings?: ISettings;
}

export const initialState: IAppState = {
  isInitialized: false,
  isAuthenticated: false,
  token: undefined,
  refreshToken: undefined,
  constants: undefined,
  profile: undefined,
  loading: {},
  countries: [],
};

const loadApp = createAsyncThunk(`${SLICE_NAME.APP}/loadApp`, async () => {
  return { token: getToken() };
});

const getConstants = createAsyncThunk(`${SLICE_NAME.APP}/getConstants`, async (query) => {
  console.log('query', query);

  const data = await api.getConstants<{ data: IConstants }>(query);
  return data;
});

const getCountries = createAsyncThunk(`${SLICE_NAME.APP}/getCountries`, async (query: any) => {
  const data = await api.getCountries<{ data: ICountry[] }>({ ...query, limit: 500 });
  return data;
});

const getSettings = createAsyncThunk(`${SLICE_NAME.APP}/getSettings`, async (query) => {
  const data = await api.getSettings<{ data: ISettings }>(query);
  return data;
});

const getMe = createAsyncThunk(`${SLICE_NAME.APP}/getMe`, async (query) => {
  console.log('query', query);

  const data = await api.getMe<{ data: IUser }>(query);
  return data;
});

const appSlice = createSlice({
  name: SLICE_NAME.APP,
  initialState: initialState,
  reducers: {
    onSetInitialized: (state, { payload }: PayloadAction<boolean>) => {
      state.isInitialized = payload;
    },
    onLogout: (state) => {
      localStorage.clear();
      state.token = undefined;
      state.refreshToken = undefined;
      state.isAuthenticated = false;
      setToken(state.token);
    },

    githubLoginSuccess: (state, action) => {
      state.token = action.payload?.token;
      if (action.payload?.refresh_token) {
        state.refreshToken = action.payload?.refresh_token;
        setRefreshToken(state.refreshToken);
      }
      state.isAuthenticated = isTokenValid(state.token);
      setToken(state.token);
    },

    googleLoginSuccess: (state, action) => {
      state.token = action.payload?.token;
      if (action.payload?.refresh_token) {
        state.refreshToken = action.payload?.refresh_token;
        setRefreshToken(state.refreshToken);
      }
      state.isAuthenticated = isTokenValid(state.token);
      setToken(state.token);
    },
    saveToken: (state, action) => {
      state.token = action.payload?.token;
      if (action.payload?.refresh_token) {
        state.refreshToken = action.payload?.refresh_token;
        setRefreshToken(state.refreshToken);
      }
      state.isAuthenticated = isTokenValid(state.token);
      setToken(state.token);
    },
    updateToken: (state, action) => {
      state.token = action.payload?.payload?.data?.token;

      if (action.payload?.payload?.data?.refresh_token) {
        state.refreshToken = action.payload?.payload?.data?.refresh_token;
        setRefreshToken(state.refreshToken);
      }
      state.isAuthenticated = isTokenValid(state.token);
      setToken(state.token);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadApp.pending, (state, { payload }) => {
      state.loading[loadApp.typePrefix] = true;
    });
    builder.addCase(loadApp.rejected, (state, { payload }) => {
      state.loading[loadApp.typePrefix] = false;
    });
    builder.addCase(loadApp.fulfilled, (state, { payload }) => {
      state.loading[loadApp.typePrefix] = false;
      state.isAuthenticated = isTokenValid(payload?.token);
    });
    builder.addCase(getConstants.fulfilled, (state, { payload }) => {
      state.constants = payload?.data.data;
    });
    builder.addCase(getSettings.pending, (state, { payload }) => {
      state.loading[getSettings.typePrefix] = true;
    });
    builder.addCase(getSettings.rejected, (state, { payload }) => {
      state.loading[getSettings.typePrefix] = false;
    });
    builder.addCase(getSettings.fulfilled, (state, { payload }) => {
      state.loading[getSettings.typePrefix] = false;
      state.settings = payload.data.data;
    });
    builder.addCase(getMe.pending, (state, { payload }) => {
      state.loading[getMe.typePrefix] = true;
    });
    builder.addCase(getMe.rejected, (state, { payload }) => {
      state.loading[getMe.typePrefix] = false;
    });
    builder.addCase(getMe.fulfilled, (state, { payload }) => {
      state.loading[getMe.typePrefix] = false;
      state.profile = payload.data.data;
    });
    builder.addCase(getCountries.fulfilled, (state, { payload }) => {
      state.loading[getCountries.typePrefix] = false;

      // const newCountries = Object.keys(payload.data)?.map((key) => ({
      //   countryCode: key.toLocaleUpperCase(),
      //   name: payload.data?.[key],
      //   flag: `https://flagcdn.com/16x12/${key}.png`,
      // }));

      state.countries = payload?.data?.data;
    });
  },
});

export const appReducer = appSlice.reducer;
export const appActions = {
  ...appSlice.actions,
  loadApp,
  getConstants,
  getMe,
  getCountries,
  getSettings,
};
