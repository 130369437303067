import { ReactComponent as NotificationIcon } from '@/assets/images/new-icons/notification.svg';
import { ROUTE_PATH } from '@/common/constants/routes';
import { useDispatch, useSelector } from '@/stores';
import { authActions } from '@/stores/auth';
import { employeePortalActions } from '@/stores/employeePortal';
import { notificationActions } from '@/stores/notification';
import { profileActions } from '@/stores/profile';
import { settingsActions } from '@/stores/settings';
import { getBreakPoint } from '@/utils';
import { isEmployee } from '@/utils/auth';
import Icon, {
  MenuOutlined
} from '@ant-design/icons';
import { Badge, Grid, Spin } from 'antd';
import { memo, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link, useNavigate } from 'react-router-dom';
import NavProfile from './NavProfile';
import NotificationLink from './NotificationLink';

const { useBreakpoint } = Grid;

function Header(props: any) {
  const { dataFromSubParent } = props;

  const navigate = useNavigate();
  const { isToggleLeftMenu } = useSelector((state) => state.settings);
  const { user } = useSelector((state) => state.profile);
  const { data, loading, pagination } = useSelector((state) => state.notification);

  const screens = getBreakPoint(useBreakpoint());
  const isMobile = screens.length === 0 ? false : !screens.includes('lg');

  const dispatch = useDispatch();

  useEffect(function () {
    dispatch(profileActions.getMe());

    if (isEmployee()) {
      dispatch(employeePortalActions.getCurrentEmployee());
    } else {
      dispatch(notificationActions.getNotifications({}));
    }
  }, []);

  const handleSignoutClick = () => {
    dispatch(authActions.onLogout());
    navigate('/login');
  };

  const fetchMoreNotificationsRooms = () => {
    if (pagination.page < pagination.totalPage) {
      dispatch(
        notificationActions.getMoreNotifications({
          page: pagination.page + 1,
        }),
      );
    }
  };

  return (
    <div
      id="page_top"
      // className={isFixNavbar ? "sticky-top" : "" + this.props.dataFromParent === 'dark' ? 'container-fluid top_dark' : 'container-fluid'}
    >
      <div className="page-header w-100 h-100 bg-white border-bottom d-flex justify-content-between">
        {/* <Button onClick={()=>dispatch(settingsActions.toggleLeftMenuAction(!isToggleLeftMenu))} className="left-sidebar-close"><MenuUnfoldOutlined /></Button> */}
        <span onClick={() => dispatch(settingsActions.toggleLeftMenuAction(!isToggleLeftMenu))} className="nav-link">
          <h5 className="d-block d-sm-none">
            <MenuOutlined />
          </h5>
          {/* <h5 className="d-none d-sm-block">
            {isToggleLeftMenu ? (
              <Tooltip title="Unfold Menu">\
                <Icon
                  style={{ '-webkit-transform': 'scaleX(-1)', transform: 'scaleX(-1)' } as CSSProperties}
                  component={() => <Expand />}
                />
              </Tooltip>
            ) : null}
          </h5> */}
        </span>

        <div className="d-flex align-items-center">
          {user?.role === 'company' || user?.role === 'client' ? (
            <div className="dropdown d-flex align-items-center">
              <Link className="mr-3" data-bs-toggle="dropdown" to="#">
                <Badge size="small" count={pagination?.totalNotificationNotSeen} overflowCount={9}>
                  <Icon style={{ fontSize: '20px' }} component={() => <NotificationIcon />} />
                </Badge>
              </Link>
              {isMobile ? null : <div style={{ content: '', width: 1, height: 20, backgroundColor: '#E6E6EB' }}></div>}

              <ul className="dropdown-menu dropdown-menu-right dropdown-menu-arrow notification-container">
                <li className="px-3 pb-2 border-bottom mb-0">
                  <Link
                    to={ROUTE_PATH.NOTIFICATION}
                    className=""
                    style={{ fontSize: '18px', lineHeight: '28px', fontWeight: 500, color: '#1E1D1D' }}
                  >
                    Notification
                  </Link>
                </li>
                <InfiniteScroll
                  hasMore={(data?.length || 0) < pagination?.total}
                  dataLength={data?.length || 0}
                  height={300}
                  next={fetchMoreNotificationsRooms}
                  // endMessage={
                  //   <p style={{ textAlign: "center" }}>
                  //     <b>You have seen it all</b>
                  //   </p>
                  // }
                  loader={<Spin tip="Loading" size="small" spinning={loading[notificationActions.getMoreNotifications.typePrefix]}></Spin>}
                >
                  {data?.map((item) => <NotificationLink item={item} />)}
                </InfiniteScroll>
              </ul>
            </div>
          ) : null}
          <NavProfile isMobile={isMobile} />
        </div>
      </div>
    </div>
  );
}

export default memo(Header);
