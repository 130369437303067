import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { IUser } from '@/types/app';
import { Button, Card, Col, DatePicker, Form, Input, Popconfirm, Row, Select, Space, Tag } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from '@/stores';
import { userActions } from '@/stores/user';
import ChangePasswordModal from '../Modals/ChangePasswordModal';
import { constantsToOptions, matchValueToLabel } from '@/utils';
import { profileActions } from '@/stores/profile';
import { dateTimeFormat } from '@/common/constants/format';
import dayjs from 'dayjs';
import { ArrowLeftIcon, DeleteIcon, EditIcon } from '../common/IconComponents';
import { UserRole } from '@/common/constants/enums';
import { authActions } from '@/stores/auth';
const UserManagementForm = (props: { create?: boolean, entity?: string, page?: string }) => {
  const {create, entity, page} = props;
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { constants } = useSelector((state) => state.app);
  const { user } = useSelector((state) => state.profile);
  const { detail } = useSelector((state) => state.user);
  const { userInfo: userInfoStore } = useSelector((state) => state.auth);

  const role = Form.useWatch('role', form);

  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      dispatch(userActions.getUser({ id }));
    } else if (!props.create) {
      if (user) {
        form.setFieldsValue(user);
      }
    }
  }, [id, user]);

  useEffect(() => {
    if (!props.create) {
      if (detail) {
        form.setFieldsValue(detail);
      }
    } else form.resetFields();
  }, [detail]);

  const navigateBack = () => {
    navigate(-1);
  };

  return (
    <>
      <div className="container-fluid">
        {userInfoStore?.role === 'admin' ? (
          <>
            <div className="d-flex justify-content-between align-items-center py-3">
              <div className="d-flex justify-content-between align-items-center page-listing-title mb-0">
                <ArrowLeftIcon className="mr-2" onClick={navigateBack} />
                <span>User Details</span>
              </div>
              {/* {!props.create ? (
                  <Button type="primary" role="button" className="d-flex align-items-center" onClick={() => setIsModalOpen(true)}>
                    Change Password
                  </Button>
                ) : null} */}
              <Space size="small" className="d-flex align-items-center">
                <Popconfirm
                  title="Delete this employee"
                  description="Are you sure to delete?"
                  onConfirm={() => {
                    dispatch(
                      userActions.deleteUser({
                        id: id,
                      }),
                    )
                      ?.unwrap()
                      ?.then(() => {
                        dispatch(userActions.getUsers({}));
                        navigate(-1);
                      });
                  }}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button danger type="text" className="font-weight-bold">
                    Delete
                  </Button>
                </Popconfirm>
                <Button ghost type="primary" onClick={() => dispatch(authActions.loginAsUser(id))}>
                  Log In Account
                </Button>
                <Link to="./update">
                  <Button type="primary" htmlType="submit" className="d-flex align-items-center">
                    <EditIcon /> Edit
                  </Button>
                </Link>
              </Space>
            </div>
          </>
        ) : (
          <div className="d-flex p-4"></div>
        )}
        <Card className="general-information">
          <p className="title border-bottom pb-2">
            {detail?.forename} {detail?.surname}
          </p>
          <Row gutter={[20, 20]} className="mt-3">
            <Col xs={4}>
              <p className="label">Username</p>
            </Col>
            <Col xs={20} className="value">
              {detail?.name ? '@' + detail?.name : <p className="none">none</p>}
            </Col>
            <Col xs={4}>
              <p className="label">Email</p>
            </Col>
            <Col xs={20} className="value">
              {detail?.email || <p className="none">none</p>}
            </Col>
            <Col xs={4}>
              <p className="label">Role</p>
            </Col>
            <Col xs={20} className="value text-capitalize">
              <Tag
                color={detail?.role === UserRole.admin ? 'purple' : detail?.role === UserRole.company ? 'magenta' : 'blue'}
                className="text-uppercase"
              >
                {detail?.role}
              </Tag>
            </Col>
            <Col xs={4}>
              <p className="label">Department</p>
            </Col>
            <Col xs={20} className="value text-capitalize">
              {matchValueToLabel(constants?.departments || [], detail?.department || '') || <p className="none">none</p>}
            </Col>
            <Col xs={4}>
              <p className="label">Country</p>
            </Col>
            <Col xs={20} className="value text-capitalize">
              {detail?.country?.id ? (
                <Space>
                  <img src={detail?.country?.flag} width="16" height="12" alt={`${detail?.country?.name}`} className="mr-2" />
                  {detail?.country?.name}
                </Space>
              ) : (
                <p className="none">none</p>
              )}
            </Col>
            <Col xs={4}>
              <p className="label">Created At</p>
            </Col>
            <Col xs={20} className="value text-capitalize">
              {detail?.createdAt ? dayjs(detail?.createdAt).format(dateTimeFormat) : <p className="none">none</p>}
            </Col>
          </Row>
        </Card>
      </div>
      <ChangePasswordModal isOpen={isModalOpen} setIsOpen={(e: boolean) => setIsModalOpen(e)} />
    </>
  );
};

export default UserManagementForm;
