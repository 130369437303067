import api from "@/common/api";
import { SLICE_NAME } from "@/common/constants/stores";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  IUser,
  IUserResponse,
  IPagination,
  IChangePasswordForm,
} from "@/types/app";
import { navigateTo } from "@/utils/auth";
import { openNotification } from "@/utils";
import { ROUTE_PATH } from "@/common/constants/routes";

export interface IUserState {
  data?: IUser[];
  loading: { [key: string]: boolean };
  pagination: IPagination;
  detail?: IUser;
}

export const initialState: IUserState = {
  pagination: {
    total: 0,
    page: 0,
    limit: 10,
    totalPage: 0,
  },
  data: [],
  loading: {},
};

const getClientUsers = createAsyncThunk(
  `${SLICE_NAME.CLIENT_USERS}/getClientUsers`,
  async (query: any | undefined, { rejectWithValue }) => {
    try {
      const data = await api.getClientUsers<IUserResponse>(query);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const getClientUser = createAsyncThunk(
  `${SLICE_NAME.CLIENT_USERS}/getClientUser`,
  async (query: any | undefined, { rejectWithValue }) => {
    try {
      const data = await api.getClientUser<{ data: IUser }>(query);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const createClientUser = createAsyncThunk(
  `${SLICE_NAME.CLIENT_USERS}/createClientUser`,
  async (form: IUser, { rejectWithValue }) => {
    try {
      const data = await api.createClientUser<IUser>(form);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const updateClientUser = createAsyncThunk(
  `${SLICE_NAME.CLIENT_USERS}/updateClientUser`,
  async (form: IUser, { rejectWithValue }) => {
    try {
      const data = await api.updateClientUser<IUser>(form);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const deleteClientUser = createAsyncThunk(
  `${SLICE_NAME.CLIENT_USERS}/deleteClientUser`,
  async (query: any | undefined, { rejectWithValue }) => {
    try {
      const data = await api.deleteClientUser<{ data: IUser }>(query);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const changePasswordClientUser = createAsyncThunk(
  `${SLICE_NAME.CLIENT_USERS}/changePasswordClientUser`,
  async (data: IChangePasswordForm, { rejectWithValue }) => {
    try {
      return api.changePasswordClientUser<{ data: IUser }>(data);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const mapUserFromResponse = (response: IUser) => {
  return {
    ...response,
    createdAt: response.createdAt ? response.createdAt * 1000 : 0,
  };
};

const clientUsersSlice = createSlice({
  name: SLICE_NAME.CLIENT_USERS,
  initialState: initialState,
  reducers: {
    onResetDetail(state) {
      state.detail = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getClientUsers.fulfilled, (state, { payload }) => {
      state.loading[getClientUsers.typePrefix] = false;
      if (payload?.data && payload?.data?.data) {
        state.data = payload?.data?.data.map((item) =>
          mapUserFromResponse(item)
        );
        state.pagination = payload?.data?.pagination;
      }
    });
    builder.addCase(getClientUsers.pending, (state, { payload }) => {
      state.loading[getClientUsers.typePrefix] = true;
    });
    builder.addCase(getClientUsers.rejected, (state, { payload }) => {
      state.loading[getClientUsers.typePrefix] = false;
    });
    builder.addCase(getClientUser.fulfilled, (state, { payload }) => {
      state.loading[getClientUser.typePrefix] = false;
      state.detail = mapUserFromResponse(payload.data.data);
    });
    builder.addCase(getClientUser.pending, (state, { payload }) => {
      state.loading[getClientUser.typePrefix] = true;
    });
    builder.addCase(getClientUser.rejected, (state, { payload }) => {
      state.loading[getClientUser.typePrefix] = false;
    });
    builder.addCase(createClientUser.fulfilled, (state, { payload }) => {
      state.loading[createClientUser.typePrefix] = false;
      // navigateTo(ROUTE_PATH.CLIENT_USERS_MANAGEMENT);
      openNotification("success", "Create new user successfully!");
    });
    builder.addCase(createClientUser.pending, (state, { payload }) => {
      state.loading[createClientUser.typePrefix] = true;
    });
    builder.addCase(createClientUser.rejected, (state, { payload }) => {
      state.loading[createClientUser.typePrefix] = false;
    });
    builder.addCase(updateClientUser.fulfilled, (state, { payload }) => {
      state.loading[updateClientUser.typePrefix] = false;
      openNotification("success", "Update successfully!");
    });
    builder.addCase(updateClientUser.pending, (state, { payload }) => {
      state.loading[updateClientUser.typePrefix] = true;
    });
    builder.addCase(updateClientUser.rejected, (state, { payload }) => {
      state.loading[updateClientUser.typePrefix] = false;
    });
    builder.addCase(deleteClientUser.fulfilled, (state, { payload }) => {
      state.loading[deleteClientUser.typePrefix] = false;
      openNotification(
        "success",
        `${payload.data.data?.forename} ${payload.data.data?.surname} deleted successfully!`
      );
    });
    builder.addCase(deleteClientUser.pending, (state, { payload }) => {
      state.loading[deleteClientUser.typePrefix] = true;
    });
    builder.addCase(deleteClientUser.rejected, (state, { payload }) => {
      state.loading[deleteClientUser.typePrefix] = false;
    });
    builder.addCase(changePasswordClientUser.pending, (state, { payload }) => {
      state.loading[changePasswordClientUser.typePrefix] = true;
    });
    builder.addCase(changePasswordClientUser.rejected, (state, { payload }) => {
      state.loading[changePasswordClientUser.typePrefix] = false;
    });
    builder.addCase(
      changePasswordClientUser.fulfilled,
      (state, { payload }) => {
        state.loading[changePasswordClientUser.typePrefix] = false;
        openNotification("success", "Change password successfully!");
      }
    );
  },
});

export const clientUsersReducer = clientUsersSlice.reducer;
export const clientUsersCaseReducers = clientUsersSlice.caseReducers;
export const clientUsersActions = {
  ...clientUsersSlice.actions,
  getClientUsers,
  getClientUser,
  createClientUser,
  updateClientUser,
  deleteClientUser,
  changePasswordClientUser,
};
