import api from '@/common/api';
import { SLICE_NAME } from '@/common/constants/stores';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { IAttachment, IMessage, IMessageResponse, IPagination } from '@/types/app';
import { navigateTo } from '@/utils/auth';
import { openNotification } from '@/utils';
import { ROUTE_PATH } from '@/common/constants/routes';
import { uploadS3 } from '@/common/api/s3';
import { messageRoomActions } from './messageRooms';

export interface IMessageState {
  data?: IMessage[];
  loading: { [key: string]: boolean };
  pagination: IPagination;
  detail?: IMessage;
}

export const initialState: IMessageState = {
  pagination: {
    total: 0,
    page: 0,
    limit: 10,
    totalPage: 0,
  },
  data: [],
  loading: {},
};

const getMessages = createAsyncThunk(`${SLICE_NAME.MESSAGES}/getMessages`, async (query: any | undefined, { rejectWithValue }) => {
  try {
    const data = await api.getMessages<IMessageResponse>({
      ...query,
    });
    return data;
  } catch (e) {
    return rejectWithValue(e);
  }
});

const getMoreMessages = createAsyncThunk(`${SLICE_NAME.MESSAGES}/getMoreMessages`, async (query: any | undefined, { rejectWithValue }) => {
  try {
    const data = await api.getMessages<IMessageResponse>({
      ...query,
    });
    return data;
  } catch (e) {
    return rejectWithValue(e);
  }
});

const getMessage = createAsyncThunk(`${SLICE_NAME.MESSAGES}/getMessage`, async (query: any | undefined, { rejectWithValue }) => {
  try {
    const data = await api.getMessage<{ data: IMessage }>(query);
    return data;
  } catch (e) {
    return rejectWithValue(e);
  }
});

const createMessage = createAsyncThunk(`${SLICE_NAME.MESSAGES}/createMessage`, async (form: IMessage, { rejectWithValue }) => {
  try {
    let attachments: IAttachment[] = [];
    if (form?.attachments && Array.isArray(form.attachments) && form.attachments.length > 0) {
      const requests = form.attachments
        .filter((attachment) => attachment instanceof File)
        .map((attachment) => uploadS3(attachment, 'document_suites'));
      const responses = await Promise.all(requests);
      attachments = responses.filter((response) => response.success).map((response) => response.data);
    }
    form = {
      ...form,
      attachments,
    };
    // for (form?.attachments )
    // if (form?.attachments && form?.attachment instanceof File) {
    //   const { success, data: attachment } = await uploadS3(
    //     form?.attachment,
    //     "document_suites"
    //   );
    //   if (success) {
    //     form = {
    //       ...form,
    //       attachment,
    //     };
    //   }

    // }
    const data = await api.createMessage<{ data: IMessage }>(form);
    return data;
  } catch (e) {
    return rejectWithValue(e);
  }
});

const updateMessage = createAsyncThunk(`${SLICE_NAME.MESSAGES}/updateMessage`, async (form: IMessage, { rejectWithValue }) => {
  try {
    const data = await api.updateMessage<{ data: IMessage }>(form);
    return data;
  } catch (e) {
    return rejectWithValue(e);
  }
});

const deleteMessage = createAsyncThunk(`${SLICE_NAME.MESSAGES}/deleteMessage`, async (query: any, { rejectWithValue }) => {
  try {
    const data = await api.deleteMessage<{ data: IMessage }>(query);
    return data;
  } catch (e) {
    return rejectWithValue(e);
  }
});

const mapFromResponse = (response: IMessage) => {
  return {
    ...response,
    createdAt: response.createdAt ? response.createdAt * 1000 : 0,
  };
};

const messageSlice = createSlice({
  name: SLICE_NAME.MESSAGES,
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getMessages.fulfilled, (state, { payload }) => {
      state.loading[getMessages.typePrefix] = false;
      state.data = payload.data.data.map((item) => mapFromResponse(item));
      state.pagination = payload.data.pagination;
    });
    builder.addCase(getMessages.pending, (state, { payload }) => {
      state.loading[getMessages.typePrefix] = true;
    });
    builder.addCase(getMessages.rejected, (state, { payload }) => {
      state.loading[getMessages.typePrefix] = false;
    });
    builder.addCase(getMessage.fulfilled, (state, { payload }) => {
      state.loading[getMessage.typePrefix] = false;
      state.detail = mapFromResponse(payload.data.data);
    });
    builder.addCase(getMessage.pending, (state, { payload }) => {
      state.loading[getMessage.typePrefix] = true;
    });
    builder.addCase(getMessage.rejected, (state, { payload }) => {
      state.loading[getMessage.typePrefix] = false;
    });
    builder.addCase(createMessage.fulfilled, (state, { payload }) => {
      state.loading[createMessage.typePrefix] = false;
    });
    builder.addCase(createMessage.pending, (state, { payload }) => {
      state.loading[createMessage.typePrefix] = true;
    });
    builder.addCase(createMessage.rejected, (state, { payload }) => {
      openNotification('error', 'Send message failed!');
      state.loading[createMessage.typePrefix] = false;
    });
    builder.addCase(updateMessage.fulfilled, (state, { payload }) => {
      state.loading[updateMessage.typePrefix] = false;
      openNotification('success', 'Update successfully!');
    });
    builder.addCase(updateMessage.pending, (state, { payload }) => {
      state.loading[updateMessage.typePrefix] = true;
    });
    builder.addCase(updateMessage.rejected, (state, { payload }) => {
      state.loading[updateMessage.typePrefix] = false;
    });
    builder.addCase(deleteMessage.fulfilled, (state, { payload }) => {
      openNotification('success', 'Delete successfully!');
      state.loading[deleteMessage.typePrefix] = false;
    });
    builder.addCase(deleteMessage.pending, (state, { payload }) => {
      state.loading[deleteMessage.typePrefix] = true;
    });
    builder.addCase(deleteMessage.rejected, (state, { payload }) => {
      openNotification('error', 'Delete failed!');
      state.loading[deleteMessage.typePrefix] = false;
    });

    builder.addCase(getMoreMessages.fulfilled, (state, { payload }) => {
      state.loading[getMoreMessages.typePrefix] = false;

      if (payload.data.pagination.page !== 1) {
        state.data?.push(...payload.data.data.map((item) => mapFromResponse(item)));
      } else {
        state.data = payload.data.data.map((item) => mapFromResponse(item));
      }

      state.pagination = payload.data.pagination;
    });
    builder.addCase(getMoreMessages.pending, (state, { payload }) => {
      state.loading[getMoreMessages.typePrefix] = true;
    });
    builder.addCase(getMoreMessages.rejected, (state, { payload }) => {
      state.loading[getMoreMessages.typePrefix] = false;
    });
  },
});

export const messageReducer = messageSlice.reducer;
export const messageCaseReducers = messageSlice.caseReducers;
export const messageActions = {
  ...messageSlice.actions,
  getMessages,
  getMoreMessages,
  getMessage,
  createMessage,
  updateMessage,
  deleteMessage,
};
