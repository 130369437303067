import { Button, Space, Table, TableProps } from "antd";
import {
  LeftSquareTwoTone,
  LeftOutlined,
  RightSquareTwoTone,
  RightOutlined,
} from "@ant-design/icons";
import { showHideSideScroll, sideScroll } from "@/utils";
import { useEffect, useState } from "react";
import { IDocument } from "@/types/app";

interface IProps extends TableProps<any> {}

const TableWithScrollButtons = (props: IProps): JSX.Element => {
  const [windowWidth, setWindowWidth] = useState(0);

  useEffect(function () {
    window.addEventListener(
      "resize",
      function (event: Event) {
        const target = event.target as Window;
        setWindowWidth(target.innerWidth);
      },
      true
    );
  }, []);

  useEffect(
    function () {
      showHideSideScroll();
    },
    [windowWidth]
  );

  return (
    <>
      {/* <Space
        size="small"
        id="sideScroll"
        className="d-flex justify-content-end"
      >
        <span onClick={() => sideScroll(-10)}>
          <Button id="leftSideScroll" disabled className="border-0 p-0">
            <LeftOutlined style={{ fontSize: "24px" }} />
          </Button>
        </span>
        <span onClick={() => sideScroll()}>
          <Button id="rightSideScroll" className="border-0 p-0">
            <RightOutlined style={{ fontSize: "24px" }} />
          </Button>
        </span>
      </Space> */}
      <Table {...props} showSorterTooltip />
    </>
  );
};

export default TableWithScrollButtons;
