import { useDispatch, useSelector } from "@/stores";
import { messageRoomActions } from "@/stores/messageRooms";
import { IPayroll } from "@/types/app";
import { constantsToOptions } from "@/utils";
import { Form, Input, Modal, ModalProps, Select } from "antd";
import { useEffect } from "react";

interface IProps extends ModalProps {
  data?: IPayroll;
  onSubmit?: (data: IPayroll) => void;
}

const ModalPayroll = (props: IProps) => {
  const { onSubmit, data, ...modalProps } = props;
  const { constants } = useSelector((state) => state.app);

  const [form] = Form.useForm();

  useEffect(() => {
    if (data) {
      form.setFieldsValue(data);
    }
  }, [data]);

  const handleSubmitForm = (e: IPayroll) => {
    let values = { ...e };
    if (data && data?.id && data?.id !== "") {
      values = { ...values, id: data?.id };
    }
    onSubmit && onSubmit(values);
  };

  return (
    <Modal {...modalProps} onOk={form.submit} centered>
      <Form
        colon={false}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        layout="vertical"
        onFinish={handleSubmitForm}
        form={form}
      >
        <div className="row">
          <div className="col-md-12">
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: "This field is required!" }]}
            >
              <Input className="w-100" placeholder="Enter name" required />
            </Form.Item>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <Form.Item
              label="Description"
              name="description"
              rules={[{ required: true, message: "This field is required!" }]}
            >
              <Input.TextArea
                className="w-100"
                placeholder="Enter description"
                required
                maxLength={5000}
                rows={4}
              />
            </Form.Item>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default ModalPayroll;
