import ProfileDetail from "@/components/Details/ProfileDetail";
import UserDetail from "@/components/Details/UserDetail";
import UserManagementForm from "@/components/Forms/UserManagementForm";

const PersonalInformation = () => {
    return (
        <ProfileDetail/>
    )
}

export default PersonalInformation;