import AdditionalInfoDetail from "@/components/Details/AdditionalInfoDetail";
import OffboardingStatusDetail from "@/components/Details/OffboardingStatusDetail";
import OnboardingStatusDetail from "@/components/Details/OnboardingStatusDetail";
import PersonalInfoDetail from "@/components/Details/PersonalInfoDetail"

const RegistrationInfoTab = (props: any): JSX.Element => {
    const {detail} = props;
    return (
        <>
            <OnboardingStatusDetail detail={detail}/>
            <OffboardingStatusDetail detail={detail}/>
        </>
    )
}

export default RegistrationInfoTab;