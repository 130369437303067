import React from 'react';

export default function Footer() {
	return (
		<>
			<div>
				<div className="section-body">
				</div>
			</div>
		</>
	);

}
