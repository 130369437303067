import React from "react";
const NotFound = (props: any) => {
  return (
    <div className="w-100 vh-100 d-flex justify-content-center align-items-center h1">
      404 NOT FOUND
    </div>
  );
};

export default NotFound;
