import { EmployeeStatus } from "@/common/constants/enums";
import { pageSizeOptions } from "@/common/constants/format";
import NotificationLink from "@/components/Shared/NotificationLink";
import TableWithScrollButtons from "@/components/Shared/TableWithScrollButtons";
import { useDispatch, useSelector } from "@/stores";
import { notificationActions } from "@/stores/notification";
import { INotification } from "@/types/app";
import { Input, Pagination, Spin } from "antd";
import { ColumnsType } from "antd/es/table";
import { useEffect } from "react";
import { Link } from "react-router-dom";

const Notification = () => {
  const columns: ColumnsType<INotification> = [
    {
      //   title: "Actions",
      key: "action",
      fixed: "left",
      width: 150,
      align: "center",
    },
  ];
  const { userInfo } = useSelector((state) => state.auth);
  const { data, loading, pagination } = useSelector(
    (state) => state.notification
  );
  const dispatch = useDispatch();

  const handleSearchSubmit = (e: string) => {
    dispatch(notificationActions.getNotifications({ keyword: e }));
  };

  const handlePaginationChange = (page: number, pageSize: number) => {
    dispatch(
      notificationActions.getNotifications({
        page,
        limit: pageSize,
      })
    );
  };

  useEffect(function () {
    dispatch(notificationActions.getNotifications({}));
  }, []);

  return (
    <Spin
      tip="Loading"
      size="large"
      spinning={loading[notificationActions.getNotifications.typePrefix]}
    >
      <div className="container-fluid">
        <div className="d-flex p-4"></div>
        <div className="row clearfix">
          <div className="col-lg-12">
            <div className="bg-white shadow-lg rounded">
              <div className="card-header">
                <h3 className="card-title font-weight-bold">
                  Notification List
                </h3>
                <div className="card-options">
                  <div className="input-group">
                    <Input.Search
                      placeholder="Input search text"
                      onSearch={(e) => handleSearchSubmit(e)}
                      allowClear
                      className="w-100"
                    />
                  </div>
                </div>
              </div>

              <div className="card-body">
                {data && data.length ? (
                  data.map((item) => <NotificationLink item={item} />)
                ) : (
                  <span className="px-3 pb-2">No new notification</span>
                )}
                <Pagination
                  onChange={(page: number, pageSize: number) =>
                    handlePaginationChange(page, pageSize)
                  }
                  className="pt-4"
                  showSizeChanger
                  defaultCurrent={1}
                  total={pagination.total}
                  // position={["bottomLeft"]}
                  pageSizeOptions={pageSizeOptions}
                  //   showTotal={(total: number, range: number[]) =>
                  //     'Showing ' + range[0] + ' to ' + range[1] + ' of ' + total + ' entries'
                  //   }
                  //   onShowSizeChange={onShowSizeChange}
                />
              </div>
              <div className="card-footer col-md-12 text-right"></div>
            </div>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default Notification;
