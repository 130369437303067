import { Col, Form, Input, Row, Select } from "antd";
import { constantsToOptions } from "@/utils";
import { useSelector } from "@/stores";

const EmployeeRoleInfoForm = () => {
  const { constants } = useSelector((state) => state.app);

  return (
    <Row gutter={16} className=" p-2">
      <Col sm={12}>
        <Form.Item
          label="Job Title"
          name="jobTitle"
          rules={[
            {
              required: true,
              message: 'This field is required!',
            },
          ]}
        >
          <Input className="w-100" placeholder="Enter job title" maxLength={128} />
        </Form.Item>
      </Col>
      {/* <Col sm={12}>
                <Form.Item label="End Client Name" name="clientName">
                  <Input className="w-100" placeholder="Enter client name" />
                </Form.Item>
              </Col> */}
      <Col sm={12}>
        <Form.Item label="Site Location" name="siteLocation">
          <Input className="w-100" placeholder="Enter site location" maxLength={128} />
        </Form.Item>
      </Col>
      <Col sm={12}>
        <Form.Item
          label="Currency"
          name="currency"
          rules={[
            {
              required: true,
              message: 'This field is required!',
            },
          ]}
        >
          <Select placeholder="Select currency" options={constantsToOptions(constants?.currencies || [])} />
        </Form.Item>
      </Col>
      <Col sm={12}>
        <Form.Item
          label="Pay Rate"
          name="payRate"
          rules={[
            {
              required: true,
              message: 'This field is required!',
            },
          ]}
        >
          <Input
            type="number"
            placeholder="Enter pay rate"
            className="w-100"
            // prefix="€"
            step="100"
          />
        </Form.Item>
      </Col>
      <Col sm={12}>
        <Form.Item
          label="Pay Frequency"
          name="payFrequency"
          rules={[
            {
              required: true,
              message: 'This field is required!',
            },
          ]}
        >
          <Select placeholder="Select pay frequency" options={constantsToOptions(constants?.payFrequencies || [])} />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default EmployeeRoleInfoForm;
