import { IEmployee } from "@/types/app";
import { Button, Input, Popconfirm, Space, Spin, Tooltip } from "antd";
import { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { Link } from "react-router-dom";
import { EyeTwoTone, DeleteTwoTone, UpCircleTwoTone, PlusOutlined, UpCircleOutlined } from "@ant-design/icons";
import { ROUTE_PATH } from "@/common/constants/routes";
import { dateFormat, pageSizeOptions } from "@/common/constants/format";
import TableWithScrollButtons from "@/components/Shared/TableWithScrollButtons";
import { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "@/stores";
import { newHireActions } from "@/stores/newHire";
import dayjs from "dayjs";
import { UserRole } from "@/common/constants/enums";
import { matchCountryCodeToFlag, matchCountryCodeToLabel } from "@/utils";
import { DeleteIcon, EditIcon, EyeIcon, GrayEditIcon, SearchIcon } from "@/components/common/IconComponents";
import { SorterResult } from "antd/es/table/interface";

const NewHireList = () => {
  const { userInfo } = useSelector((state) => state.auth);
  const { countries } = useSelector((state) => state.app);


  const [searchText, setSearchText] = useState('');


  const columns: ColumnsType<IEmployee> = [
    {
      title: 'Employee',
      dataIndex: 'name',
      key: 'name',
      width: 250,
      fixed: 'left',
      sorter: true,
      render: (name, record) => (
        <div className="general-information-table">
          <p className="title">{name}</p>
          <p className="">{record.email ? record.email : ''}</p>
        </div>
      ),
    },
    {
      title: "Job Title",
      dataIndex: "jobTitle",
      key: "address",
      width: 200,
      sorter: true,
    },
    {
      title: "Client Name",
      key: "client",
      width: 200,
      sorter: true,
      render: (_, record) => (
        <Space>
          {[record.client?.forename, record?.client?.surname].join(" ")}
        </Space>
      ),
    },
    {
      title: "Client Email",
      key: "client",
      width: 200,
      sorter: true,
      render: (_, record) => <Space>{record.client?.email}</Space>,
    },
    {
      title: 'Nationality',
      dataIndex: 'employeeNationality',
      key: 'employeeNationality',
      width: 200,
      sorter: true,
      render: (employeeNationality) =>
        employeeNationality?.id ? (
          <Space>
            <img src={employeeNationality?.flag} width="16" height="12" alt={`${employeeNationality?.name}`} className="mr-2" />
            {employeeNationality?.name}
          </Space>
        ) : null,
    },
    {
      title: 'Country of Hire',
      dataIndex: 'countryOfHire',
      key: 'countryOfHire',
      width: 200,
      sorter: true,
      render: (countryOfHire) =>
        countryOfHire?.id ? (
          <Space>
            <img src={countryOfHire?.flag} width="16" height="12" alt={`${countryOfHire?.name}`} className="mr-2" />
            {countryOfHire?.name}
          </Space>
        ) : null,
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      width: 200,
      sorter: true,
      render: (val) => dayjs(val).format(dateFormat),
    },
    {
      title: "Actions",
      key: "action",
      fixed: "right",
      width: 180,
      render: (_, record) => (
        <Space>
          <Link to={_.id}>
            <Tooltip title="View Detail">
              <Button
                type="text"
                size="small"
              >
                <EyeIcon />
              </Button>
            </Tooltip>
          </Link>
          <Link to={`${record.id}/update`}>
            <Tooltip title="Edit">
              <Button
                type="text"
                size="small"
              >
                <GrayEditIcon />
              </Button>
            </Tooltip>
          </Link>
          {userInfo?.role === UserRole.company ? (
            <Popconfirm
              title="Onboard this employee"
              description="Are you sure you want to proceed?"
              onConfirm={async function () {
                await dispatch(
                  newHireActions.onboardNewHire({
                    id: record.id,
                  })
                );
                await dispatch(
                  newHireActions.getNewHires({ role: userInfo?.role })
                );
              }}
              okText="Yes"
              cancelText="No"
            >
              <Tooltip title="Onboard">
                <Button
                type="text"
                size="small"
              >
                <UpCircleOutlined />
              </Button>
              </Tooltip>
            </Popconfirm>
          ) : null}
          {userInfo?.role === UserRole.company ? (
            <Popconfirm
              title="Delete Request"
              description="Are you sure you want to delete request?"
              onConfirm={async function () {
                await dispatch(
                  newHireActions.deleteNewHire({
                    id: record.id,
                    role: userInfo?.role,
                  })
                );
                await dispatch(
                  newHireActions.getNewHires({ role: userInfo?.role })
                );
              }}
              okText="Delete"
              cancelText="Cancel"
              icon={false}
            >
              <Tooltip title="Delete">
                <Button
                type="text"
                size="small"
              >
                <DeleteIcon />
              </Button>
              </Tooltip>
            </Popconfirm>
          ) : null}
        </Space>
      ),
    },
  ];

  const dispatch = useDispatch();
  const { data, loading, pagination } = useSelector((state) => state.newHire);

  useEffect(function () {
    dispatch(newHireActions.getNewHires({ role: userInfo?.role }));
  }, []);

  const handleSearchSubmit = (e: string) => {
    const keyword = e.trim();
    setSearchText(keyword);
    dispatch(newHireActions.getNewHires({ keyword, role: userInfo?.role }));
  };

  const handlePaginationChange = (page: number, pageSize: number) => {
    dispatch(
      newHireActions.getNewHires({
        page,
        limit: pageSize,
        role: userInfo?.role,
      })
    );
  };
  const handleSortAndPaginationChange = (
    pagination: TablePaginationConfig,
    sorter: SorterResult<IEmployee> | SorterResult<IEmployee>[],
  ) => {
      dispatch(
        newHireActions.getNewHires({
          page: pagination.current,
          limit: pagination.pageSize,
          keyword: searchText,
          sortBy: !Array.isArray(sorter) && sorter.order ? `${sorter.field} ${sorter.order === 'ascend' ? 'ASC' : 'DESC'}` : undefined,
          role: userInfo?.role,
        }),
      );
  };

  return (
    <div className="container-fluid pt-4">
      <Spin tip="Loading" size="large" spinning={loading[newHireActions.getNewHires.typePrefix]}>
        <div className="d-flex justify-content-between">
          <p className="page-listing-title">Request New Hire</p>
          {userInfo?.role === UserRole.company ? null : (
            <div className="d-flex">
              <Link to={ROUTE_PATH.NEW_HIRE_CREATE}>
                <Button type="primary">
                  <PlusOutlined /> Add New
                </Button>
              </Link>
            </div>
          )}
        </div>
        <div className="bg-white rounded">
          <div className="card-header">
            <Input
              prefix={<SearchIcon className="mr-0" />}
              placeholder="Search"
              onPressEnter={(e: any) => handleSearchSubmit(e?.target?.value)}
              className="w-100 mr-2 page-listing-search-input"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <TableWithScrollButtons
                columns={columns}
                dataSource={data}
                size="small"
                scroll={{ x: 1200 }}
                onChange={(pagination, filter, sorter) => {
                  handleSortAndPaginationChange(pagination, sorter);
                }}
                pagination={{
                  // onChange: (page: number, pageSize: number) => handlePaginationChange(page, pageSize),
                  showSizeChanger: true,
                  defaultCurrent: 1,
                  total: pagination.total,
                  position: ['bottomLeft'],
                  pageSizeOptions: pageSizeOptions,
                  showTotal: (total: number, range: number[]) => 'Showing ' + range[0] + ' to ' + range[1] + ' of ' + total + ' entries',
                }}
              />
            </div>
          </div>
        </div>
      </Spin>
    </div>
  );
};

export default NewHireList;
