import EmployeePersonalInfoForm from "@/components/Forms/EmployeePersonalInfoForm";
import EmployeeRegistrationInfoForm from "@/components/Forms/EmployeeRegistrationInfoForm";
import EmployeeRoleInfoForm from "@/components/Forms/EmployeeRoleInfoForm";
import UploadDocumentModal from "@/components/Modals/UploadDocumentModal";
import EmployeeDocuments from "@/components/Tables/EmployeeDocuments";
import EmployeeNotes from "@/components/Tables/EmployeeNotes";
import { useDispatch, useSelector } from "@/stores";
import { employeeActions } from "@/stores/employee";
import { IDocument, IEmployee } from "@/types/app";
import { Avatar, Button, Card, Col, Dropdown, Form, MenuProps, Popconfirm, Row, Space, Spin, Tabs, TabsProps, Tag } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { DownCircleOutlined, DownOutlined, LeftOutlined, UserOutlined } from "@ant-design/icons";
import EmployeeDetailTabs from "./EmployeeDetailTabs";
import { ArrowDownIcon, ArrowLeftIcon, CallIcon, DeleteIcon, EditIcon, GrayEditIcon, LocationIcon, MailIcon, OffboardIcon } from "@/components/common/IconComponents";
import { ROUTE_PATH } from "@/common/constants/routes";
import RequestTerminationModal from "@/components/Modals/RequestTerminationModal";

export const initialState: IEmployee = {
  title: "mr",
  forename: "",
  surname: "",
  email: "",
  dateOfBirth: 0,
  // employeeNationality: "GB",
  // countryOfHire: "GB",
  needVisa: true,
  jobTitle: "",
  jobDescription: "",
  currency: "EUR",
  payFrequency: "hourly",
  startDate: 0,
  contractType: "ongoing",
  requireHealthInsurance: true,
  address: "",
  phoneNumber: "",
  status: "in_review",
  registrationStatus: "in_review",
  siteLocation: "",
};

const EmployeeDetail = (): JSX.Element => {
  const {
    detail,
    loading,
    modalUploadDocumentVisibility,
    uploadDocumentType,
  } = useSelector((state) => state.employee);

  const { id } = useParams();

  const { countries } = useSelector((state) => state.app);
  const { userInfo } = useSelector((state) => state.auth);

  const [employee, setEmployee] = useState<IEmployee>(initialState);
  const [isRequestTerminationModalOpen, setIsRequestTerminationModalOpen] =
    useState(false);
  // const [activeKey, setActiveKey] = useState<number>(1);
  // const [showSaveBtn, setShowSaveBtn] = useState<boolean>(true);

  const [form] = Form.useForm();

  // useEffect(
  //   function () {
  //     dispatch(employeeActions.getEmployee({ id: id, role: userInfo?.role }));
  //     dispatch(
  //       employeeActions.getEmployeeTimesheets({
  //         id: id,
  //         role: userInfo?.role,
  //       })
  //     );
  //     dispatch(
  //       employeeActions.getEmployeePayslips({ id: id, role: userInfo?.role })
  //     );
  //     dispatch(
  //       employeeActions.getEmployeeOthers({ id: id, role: userInfo?.role })
  //     );
  //     dispatch(
  //       employeeActions.getEmployeeGuides({ id: id, role: userInfo?.role })
  //     );
  //   },
  //   [id]
  // );

  useEffect(
    function () {
      if (detail) setEmployee(detail);
      else setEmployee(initialState);
    },
    [detail]
  );

  const handleSubmitDocument = (value: IDocument) => {
    const newValues = { ...value, employeeID: id, id, role: userInfo?.role };
    if (uploadDocumentType === "timesheet") {
      dispatch(employeeActions.createTimesheetDocument(newValues as IDocument))
        .unwrap()
        .then(() => {
          dispatch(employeeActions.onCloseModalUploadDocument());
          dispatch(
            employeeActions.getEmployeeTimesheets({ id, role: userInfo?.role })
          );
        });
    } else if (uploadDocumentType === "payslip") {
      dispatch(employeeActions.createPayslipDocument(newValues as IDocument))
        .unwrap()
        .then(() => {
          dispatch(employeeActions.onCloseModalUploadDocument());
          dispatch(
            employeeActions.getEmployeePayslips({ id, role: userInfo?.role })
          );
        });
    } else if (uploadDocumentType === "others") {
      dispatch(employeeActions.createOthersDocument(newValues as IDocument))
        .unwrap()
        .then(() => {
          dispatch(employeeActions.onCloseModalUploadDocument());
          dispatch(
            employeeActions.getEmployeeOthers({ id, role: userInfo?.role })
          );
        });
    }
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();


  const navigateBack = () => {
    const pathNames = location?.pathname.split("/").filter((val) => val !== "");
    navigate(ROUTE_PATH.EMPLOYEE_LIST || "-1");
  };

  const actionMenu: MenuProps['items'] = [
    {
      key: 'Edit Info',
      label: (
        <Link to="update" className="d-flex align-items-center">
          <GrayEditIcon className="mr-2" />
          Edit Info
        </Link>
      ),
    },
    userInfo?.role === 'client'
      ? {
          key: 'Request Termination',
          label: (
            <Link
              to=""
              className="d-flex align-items-center"
              onClick={() => {
                setIsRequestTerminationModalOpen(true);
              }}
            >
              <OffboardIcon className="mr-2" />
              Request Termination
            </Link>
          ),
        }
      : {
          key: 'Offboard',
          label: (
            
            <Popconfirm
            title="Offboard this employee"
            description="Are you sure you want to proceed?"
            onConfirm={async function () {
              await dispatch(
                employeeActions.offboardEmployee({
                  id: id,
                  role: userInfo?.role,
                }),
              );
              navigateBack();
              // await dispatch(employeeActions.getEmployees({ role: userInfo?.role }));
            }}
            okText="Yes"
            cancelText="No"
            className="d-flex align-items-center"
            arrow={false}
          >
            <DownCircleOutlined style={{fontSize: 18}} className="mr-2"/>Offboard
          </Popconfirm>
          ),
        },
    {
      key: 'Delete',
      label: (
        <Popconfirm
          title="Delete this employee"
          description="Are you sure you want to delete this employee?"
          onConfirm={async function () {
            await dispatch(
              employeeActions.deleteEmployee({
                id: id,
                role: userInfo?.role,
              }),
            );
            navigateBack();
            // await dispatch(employeeActions.getEmployees({ role: userInfo?.role }));
          }}
          okText="Delete"
          cancelText="Cancel"
          className="d-flex align-items-center text-danger"
          arrow={false}
        >
          <DeleteIcon className="mr-2" />
          Delete
        </Popconfirm>
      ),
    },
  ];

  useEffect(() => {
    form.resetFields();
  }, [employee]);


  return (
    <div className="container-fluid">
      <Spin tip="Loading" size="large" spinning={loading[employeeActions.getEmployee.typePrefix]}>
        <div className="d-flex justify-content-between align-items-center py-3">
          <div className="d-flex justify-content-between align-items-center page-listing-title mb-0">
            <ArrowLeftIcon className="mr-2" onClick={navigateBack} />
            <span>Employee Profile</span>
          </div>
        </div>
        <Row gutter={[16, 16]}>
          <Col xs={24} lg={8}>
            <Card className="general-information border-0">
              <div className="d-flex flex-column align-items-center border-bottom pb-3">
                {/* <Avatar size={64} icon={<UserOutlined />}></Avatar> */}
                <p className="mb-0 title">
                  {detail?.forename} {detail?.surname}
                </p>
                <p className="mb-2 subtitle">{detail?.jobTitle}</p>
                <Tag className="rounded text-uppercase mr-0 active-tag">
                  Active
                </Tag>
              </div>
              <div className="pt-3">
                <p className="mb-2 d-flex">
                  <MailIcon className="mr-2" />
                  <span className="text-break">{detail?.email}</span>
                </p>
                <p className="mb-2">
                  <CallIcon className="mr-2" />
                  {detail?.phoneNumber}
                </p>
                <p className="mb-3">
                  <LocationIcon className="mr-2" />
                  {detail?.employeeNationality?.name}
                </p>
                <Dropdown placement="bottom" menu={{ items: actionMenu }} trigger={['click']} overlayClassName="" className="">
                  <div className="button-actions w-100">
                    Actions <ArrowDownIcon />
                  </div>
                </Dropdown>
              </div>
            </Card>
          </Col>
          <Col xs={24} lg={16}>
            <EmployeeDetailTabs/>
          </Col>
        </Row>

        {modalUploadDocumentVisibility && (
          <UploadDocumentModal
            open={modalUploadDocumentVisibility}
            destroyOnClose={true}
            onCancel={() => dispatch(employeeActions.onCloseModalUploadDocument())}
            onSubmit={handleSubmitDocument}
            confirmLoading={
              loading[employeeActions?.createTimesheetDocument.typePrefix] ||
              loading[employeeActions?.createPayslipDocument.typePrefix] ||
              loading[employeeActions?.createOthersDocument.typePrefix]
            }
            hideCountryCode={true}
            hideDocumentType={true}
          />
        )}
        <RequestTerminationModal
          employeeId={id}
          isOpen={isRequestTerminationModalOpen}
          setIsOpen={(val: boolean) => setIsRequestTerminationModalOpen(val)}
        />
      </Spin>
    </div>
  );
};

export default EmployeeDetail;
