import UserManagement from '@/pages/admin/Users';
import UserManagementDetail from '@/pages/admin/Users/id';
import AuthoriseExpenses from '@/pages/authenticated/AuthoriseExpenses';
import Dashboard from '@/pages/authenticated/Dashboard';
import DocumentSuite from '@/pages/authenticated/DocumentSuites';
import EmployeeList from '@/pages/authenticated/Employees';
import EmployeeDetail from '@/pages/authenticated/Employees/id';
import OffboardingList from '@/pages/authenticated/Offboardings';
import OffboardingDetail from '@/pages/authenticated/Offboardings/id';
import OnboardingList from '@/pages/authenticated/Onboardings';
import OnboardingUpdate from '@/pages/authenticated/Onboardings/update';
import NewHire from '@/pages/authenticated/NewHires';
import NewHireDetail from '@/pages/authenticated/NewHires/id';
import SendMessage from '@/pages/authenticated/Messages';
import Invoices from '@/pages/authenticated/Invoices';
import Login from '@/pages/authentication/Login';
import Signup from '@/pages/authentication/Signup';
import PersonalInformation from '@/pages/authentication/PersonalInfomation';
import AuthoriseExpensesDetail from '@/pages/authenticated/AuthoriseExpenses/id';
import NewHireCreate from '@/pages/authenticated/NewHires/create';
import Import from '@/pages/authenticated/Imports';
import UserManagementCreate from '@/pages/admin/Users/create';
import Notification from '@/pages/authenticated/Notifications';
import Payrolls from '@/pages/admin/Settings/Payrolls';
import Reports from '@/pages/authenticated/Reports';
import ClientUsersManagement from '@/pages/authenticated/Users';
import ClientUserManagementDetail from '@/pages/authenticated/Users/id';
import ClientUserManagementForm from '@/pages/authenticated/Users/create';
import Smtps from '@/pages/admin/Settings/Smtps';
import ResetPassword from '@/pages/authentication/ResetPassword';
import ForgotPassword from '@/pages/authentication/ForgotPassword';
import OnboardingDetail from '@/pages/authenticated/Onboardings/id';
import OffboardingUpdate from '@/pages/authenticated/Offboardings/update';
import PersonalInformationUpdate from '@/pages/authentication/PersonalInfomation/update';
import UserManagementUpdate from '@/pages/admin/Users/update';
import DashboardAllCountries from '@/pages/authenticated/Dashboard/tabs/AllCountries';
import DashboardAllClients from '@/pages/authenticated/Dashboard/tabs/clients/AllClients';
import EmployeeCreate from '@/pages/authenticated/Employees/create';
import EmployeeUpdate from '@/pages/authenticated/Employees/update';
import NewHireUpdate from '@/pages/authenticated/NewHires/update';
import ActivityLogs from '@/pages/authenticated/ActivityLogs';
import ActivityLogDetails from '@/pages/authenticated/ActivityLogs/id';
import Permissions from '@/pages/authenticated/Permissions';
import EmployeePortal from '@/pages/authenticated/EmployeePortal';
import  { Navigate } from 'react-router-dom';
import DashboardAllClientsCreate from '@/pages/authenticated/Dashboard/tabs/clients/create';
import { PermissionFeature } from '@/types/app';
import DashboardAllClientsUpdate from '@/pages/authenticated/Dashboard/tabs/clients/update';
import DashboardAllEndClientsCreate from '@/pages/authenticated/Dashboard/tabs/endClients/create';
import DashboardAllEndClients from '@/pages/authenticated/Dashboard/tabs/endClients/AllEndClients';
import DashboardAllEndClientsUpdate from '@/pages/authenticated/Dashboard/tabs/endClients/update';

export enum ROUTE_PATH {
  AUTHENTICATED = '/',
  DASHBOARD = '/dashboard',
  DASHBOARD_LIST = DASHBOARD + '/list',
  DASHBOARD_ALL_COUNTRIES = '/dashboard/all-countries',
  DASHBOARD_ALL_CLIENTS = '/dashboard/all-clients',
  DASHBOARD_ALL_CLIENTS_CREATE = '/dashboard/all-clients/create',
  DASHBOARD_ALL_CLIENTS_UPDATE = '/dashboard/all-clients/:id/update',
  DASHBOARD_ALL_END_CLIENTS = '/dashboard/all-end-clients',
  DASHBOARD_ALL_END_CLIENTS_CREATE = '/dashboard/all-end-clients/create',
  DASHBOARD_ALL_END_CLIENTS_UPDATE = '/dashboard/all-end-clients/:id/update',

  EMPLOYEE = '/employees',
  EMPLOYEE_LIST = EMPLOYEE + '/list',
  EMPLOYEE_CREATE = EMPLOYEE_LIST + '/create',
  EMPLOYEE_DETAIL = EMPLOYEE_LIST + '/:id',
  EMPLOYEE_UPDATE = EMPLOYEE_LIST + '/:id/update',

  NEW_HIRE = EMPLOYEE + '/new-hires',
  NEW_HIRE_CREATE = NEW_HIRE + '/create',
  NEW_HIRE_DETAIL = NEW_HIRE + '/:id',
  NEW_HIRE_UPDATE = NEW_HIRE + '/:id/update',

  EMPLOYEE_ACTIVITY_LOGS = EMPLOYEE + '/activity-logs',
  EMPLOYEE_ACTIVITY_LOG_DETAILS = EMPLOYEE + '/activity-logs/:id',

  ONBOARDING = EMPLOYEE + '/onboardings',
  ONBOARDING_DETAIL = ONBOARDING + '/:id',
  ONBOARDING_UPDATE = ONBOARDING + '/:id/update',

  OFFBOARDING = EMPLOYEE + '/offboardings',
  OFFBOARDING_DETAIL = OFFBOARDING + '/:id',
  OFFBOARDING_UPDATE = OFFBOARDING + '/:id/update',

  AUTHORISE_HOURS = '/authorise-hours',

  AUTHORISE_EXPENSES = '/authorise-expenses',
  AUTHORISE_EXPENSES_CREATE = AUTHORISE_EXPENSES + '/create',

  INVOICES = '/invoices',

  MESSAGES = '/messages',
  MESSAGES_DETAIL = MESSAGES + '/:id',

  DOCUMENT_SUITE = '/documents-guides',

  IMPORT = '/imports',

  USER_MANAGEMENT = '/users',
  USER_MANAGEMENT_CREATE = USER_MANAGEMENT + '/create',
  USER_MANAGEMENT_DETAIL = USER_MANAGEMENT + '/:id',

  ADMIN_USER_MANAGEMENT = '/admin/users',
  ADMIN_USER_MANAGEMENT_CREATE = ADMIN_USER_MANAGEMENT + '/create',
  ADMIN_USER_MANAGEMENT_DETAIL = ADMIN_USER_MANAGEMENT + '/:id',
  ADMIN_USER_MANAGEMENT_UPDATE = ADMIN_USER_MANAGEMENT + '/:id/update',

  REPORTS = '/reports',

  PERMISSIONS = '/permissions',

  SETTINGS = '/settings',
  PAYROLLS = SETTINGS + '/payrolls',
  SMTPS = SETTINGS + '/smtps',

  UN_AUTHENTICATED = '/login',

  HOME = '/home',
  LOGIN = '/login',
  VERIFY_EMAIL = '/verify-email',
  SIGN_IN = '/sign-in',
  SIGN_UP = '/signup',
  PERSONAL_INFO = '/personalInfo',
  PERSONAL_INFO_UPDATE = '/personalInfo/update',
  NOTIFICATION = '/notifications',
  RESET_PASSWORD = '/reset-password',
  FORGOT_PASSWORD = '/forgot-password',

  EMPLOYEE_PORTAL = '/employee-portal',
}

export interface RouteType {
  path: string;
  name: string;
  exact?: boolean;
  pageTitle: string;
  component: React.FC | any;
  permission?: PermissionFeature
}

// Import all components here
// import React, { Component } from 'react'

export const ADMIN_ROUTES: RouteType[] = [
  // Admin
  {
    path: ROUTE_PATH.ADMIN_USER_MANAGEMENT_CREATE,
    name: 'userManagementCreate',
    exact: true,
    pageTitle: 'User Create',
    component: UserManagementCreate,
  },
  {
    path: ROUTE_PATH.ADMIN_USER_MANAGEMENT_DETAIL,
    name: 'userManagementDetail',
    pageTitle: 'User Detail',
    component: UserManagementDetail,
  },
  {
    path: ROUTE_PATH.ADMIN_USER_MANAGEMENT_UPDATE,
    name: 'userManagementUpdate',
    pageTitle: 'User Update',
    component: UserManagementUpdate,
  },
  {
    path: ROUTE_PATH.ADMIN_USER_MANAGEMENT,
    name: 'userManagement',
    exact: true,
    pageTitle: 'User Management',
    component: UserManagement,
  },

  {
    path: ROUTE_PATH.PERSONAL_INFO,
    name: 'personalInfo',
    exact: true,
    pageTitle: 'Personal Information',
    component: PersonalInformation,
  },
  {
    path: ROUTE_PATH.PERSONAL_INFO_UPDATE,
    name: 'personalInfoUpdate',
    exact: true,
    pageTitle: 'Update Personal Information',
    component: PersonalInformationUpdate,
  },

  {
    path: ROUTE_PATH.PAYROLLS,
    name: 'Payrolls',
    exact: true,
    pageTitle: 'Payrolls',
    component: Payrolls,
  },

  {
    path: ROUTE_PATH.SMTPS,
    name: 'SMTP',
    exact: true,
    pageTitle: 'SMTP',
    component: Smtps,
  },
];

export const ROUTES: RouteType[] = [
  {
    path: ROUTE_PATH.DASHBOARD,
    name: 'dashboard',
    exact: true,
    pageTitle: 'Dashboard',
    component: ()=><Navigate to={ROUTE_PATH.DASHBOARD_LIST}/>,
  },
  {
    path: ROUTE_PATH.DASHBOARD_LIST,
    name: 'dashboard',
    exact: true,
    pageTitle: 'Dashboard',
    component: Dashboard,
    permission: 'dashboard',
  },
  {
    path: ROUTE_PATH.DASHBOARD_ALL_COUNTRIES,
    name: 'dashboard-all-countries',
    exact: true,
    pageTitle: 'Dashboard',
    component: DashboardAllCountries,
    permission: 'dashboard',
  },
  {
    path: ROUTE_PATH.DASHBOARD_ALL_CLIENTS,
    name: 'dashboard-all-clients',
    exact: true,
    pageTitle: 'Dashboard',
    component: DashboardAllClients,
    permission: 'dashboard',
  },
  {
    path: ROUTE_PATH.DASHBOARD_ALL_CLIENTS_CREATE,
    name: 'dashboard-all-clients-create',
    exact: true,
    pageTitle: 'Dashboard',
    component: DashboardAllClientsCreate,
  },
  {
    path: ROUTE_PATH.DASHBOARD_ALL_CLIENTS_UPDATE,
    name: 'dashboard-all-clients-update',
    exact: true,
    pageTitle: 'Dashboard',
    component: DashboardAllClientsUpdate,
  },
  {
    path: ROUTE_PATH.DASHBOARD_ALL_END_CLIENTS,
    name: 'dashboard-all-end-clients',
    exact: true,
    pageTitle: 'Dashboard',
    component: DashboardAllEndClients,
    permission: 'dashboard',
  },
  {
    path: ROUTE_PATH.DASHBOARD_ALL_END_CLIENTS_CREATE,
    name: 'dashboard-all-end-clients-create',
    exact: true,
    pageTitle: 'Dashboard',
    component: DashboardAllEndClientsCreate,
  },
  {
    path: ROUTE_PATH.DASHBOARD_ALL_END_CLIENTS_UPDATE,
    name: 'dashboard-all-end-clients-update',
    exact: true,
    pageTitle: 'Dashboard',
    component: DashboardAllEndClientsUpdate,
  },
  {
    path: ROUTE_PATH.LOGIN,
    name: 'login',
    exact: true,
    pageTitle: 'Login',
    component: Login,
  },
  {
    path: ROUTE_PATH.SIGN_UP,
    name: 'signup',
    exact: true,
    pageTitle: 'Signup',
    component: Signup,
  },
  {
    path: ROUTE_PATH.RESET_PASSWORD,
    name: 'reset-password',
    exact: true,
    pageTitle: 'Reset password',
    component: ResetPassword,
  },
  {
    path: ROUTE_PATH.FORGOT_PASSWORD,
    name: 'forgot-password',
    exact: true,
    pageTitle: 'Forgot password',
    component: ForgotPassword,
  },
  {
    path: ROUTE_PATH.PERSONAL_INFO,
    name: 'personalInfo',
    exact: true,
    pageTitle: 'Personal Information',
    component: PersonalInformation,
  },
  {
    path: ROUTE_PATH.PERSONAL_INFO_UPDATE,
    name: 'personalInfoUpdate',
    exact: true,
    pageTitle: 'Update Personal Information',
    component: PersonalInformationUpdate,
  },
  {
    path: ROUTE_PATH.NOTIFICATION,
    name: 'notification',
    exact: true,
    pageTitle: 'Notification',
    component: Notification,
  },
  {
    path: ROUTE_PATH.NEW_HIRE_DETAIL,
    name: 'newHireDetail',
    pageTitle: 'Request Detail',
    component: NewHireDetail,
  },
  {
    path: ROUTE_PATH.NEW_HIRE_UPDATE,
    name: 'newHireUpdate',
    pageTitle: 'Request Update',
    component: NewHireUpdate,
  },
  {
    path: ROUTE_PATH.NEW_HIRE_CREATE,
    name: 'newHireCreate',
    exact: true,
    pageTitle: 'Request A New Hire',
    component: NewHireCreate,
  },
  {
    path: ROUTE_PATH.NEW_HIRE,
    name: 'newHire',
    exact: true,
    pageTitle: 'New Hire',
    component: NewHire,
    permission: 'employees',
  },
  {
    path: ROUTE_PATH.EMPLOYEE_DETAIL,
    name: 'employeeDetail',
    pageTitle: 'Employee Detail',
    component: EmployeeDetail,
  },
  {
    path: ROUTE_PATH.EMPLOYEE_UPDATE,
    name: 'employeeUpdate',
    pageTitle: 'Employee Update',
    component: EmployeeUpdate,
  },
  {
    path: ROUTE_PATH.EMPLOYEE_CREATE,
    name: 'employeeCreate',
    pageTitle: 'Employee Create',
    component: EmployeeCreate,
  },
  {
    path: ROUTE_PATH.EMPLOYEE_LIST,
    name: 'employee',
    exact: true,
    pageTitle: 'Employee',
    component: EmployeeList,
    permission: 'employees',
  },
  {
    path: ROUTE_PATH.EMPLOYEE,
    name: 'employee',
    exact: true,
    pageTitle: 'Employee',
    component: ()=><Navigate to={ROUTE_PATH.EMPLOYEE_LIST}/>,
    permission: 'employees',
  },
  {
    path: ROUTE_PATH.ONBOARDING_DETAIL,
    name: 'onboardingDetail',
    pageTitle: 'Onboarding Detail',
    component: OnboardingDetail,
  },
  {
    path: ROUTE_PATH.ONBOARDING_UPDATE,
    name: 'onboardingUpdate',
    pageTitle: 'Onboarding Update',
    component: OnboardingUpdate,
  },
  {
    path: ROUTE_PATH.ONBOARDING,
    name: 'onboardingList',
    exact: true,
    pageTitle: 'Onboarding Progress',
    component: OnboardingList,
    permission: 'employees',
  },
  {
    path: ROUTE_PATH.OFFBOARDING_DETAIL,
    name: 'offboardingDetail',
    pageTitle: 'Offboarding Detail',
    component: OffboardingDetail,
  },
  {
    path: ROUTE_PATH.OFFBOARDING_UPDATE,
    name: 'offboardingUpdate',
    pageTitle: 'Offboarding Update',
    component: OffboardingUpdate,
  },
  {
    path: ROUTE_PATH.OFFBOARDING,
    name: 'offboardingList',
    exact: true,
    pageTitle: 'Offboarding Progress',
    component: OffboardingList,
    permission: 'employees',
  },
  {
    path: ROUTE_PATH.EMPLOYEE_ACTIVITY_LOGS,
    name: 'employeeActivityLogs',
    exact: true,
    pageTitle: 'Activity Log',
    component: ActivityLogs,
    permission: 'employees'
  },
  {
    path: ROUTE_PATH.EMPLOYEE_ACTIVITY_LOG_DETAILS,
    name: 'employeeActivityLogDetails',
    exact: true,
    pageTitle: 'Log Details',
    component: ActivityLogDetails,
  },
  {
    path: ROUTE_PATH.AUTHORISE_HOURS,
    name: 'authoriseHours',
    exact: true,
    pageTitle: 'Authorise Hours',
    component: Dashboard,
  },
  {
    path: ROUTE_PATH.AUTHORISE_EXPENSES_CREATE,
    name: 'authoriseExpensesCreate',
    exact: true,
    pageTitle: 'Create A New Expense',
    component: AuthoriseExpensesDetail,
  },
  {
    path: ROUTE_PATH.AUTHORISE_EXPENSES,
    name: 'authoriseExpenses',
    exact: true,
    pageTitle: 'Authorise Expenses',
    component: AuthoriseExpenses,
  },
  {
    path: ROUTE_PATH.INVOICES,
    name: 'invoices',
    exact: true,
    pageTitle: 'Invoices',
    component: Invoices,
    permission: 'invoices'
  },
  {
    path: ROUTE_PATH.MESSAGES,
    name: 'sendMessage',
    exact: true,
    pageTitle: 'Messages',
    component: SendMessage,
    permission: 'messages'
  },
  {
    path: ROUTE_PATH.MESSAGES_DETAIL,
    name: 'sendMessage',
    pageTitle: 'Messages',
    component: SendMessage,
  },
  {
    path: ROUTE_PATH.DOCUMENT_SUITE,
    name: 'documentSuite',
    exact: true,
    pageTitle: 'Documents & Guides',
    component: DocumentSuite,
    permission: 'documents_and_guides'
  },
  {
    path: ROUTE_PATH.IMPORT,
    name: 'import',
    exact: true,
    pageTitle: 'Import',
    component: Import,
  },
  {
    path: ROUTE_PATH.REPORTS,
    name: 'reports',
    exact: true,
    pageTitle: 'Reports',
    component: Reports,
    permission: 'reports'
  },
  {
    path: ROUTE_PATH.PERMISSIONS,
    name: 'permissions',
    exact: true,
    pageTitle: 'Permissions',
    component: Permissions,
    permission: 'permissions'
  },
  // {
  //   path: ROUTE_PATH.USER_MANAGEMENT,
  //   name: "clientUsers",
  //   exact: true,
  //   pageTitle: "Users",
  //   component: ClientUsersManagement,
  // },
  // {
  //   path: ROUTE_PATH.USER_MANAGEMENT_CREATE,
  //   name: "clientUserManagementCreate",
  //   exact: true,
  //   pageTitle: "Client User Create",
  //   component: ClientUserManagementForm,
  // },
  // {
  //   path: ROUTE_PATH.USER_MANAGEMENT_DETAIL,
  //   name: "clientUserManagementDetail",
  //   pageTitle: "Client User Detail",
  //   component: ClientUserManagementDetail,
  // },
];

export const EMPLOYEE_ROUTES: RouteType[] = [
  {
    path: ROUTE_PATH.EMPLOYEE_PORTAL,
    name: 'employeePortal',
    exact: true,
    pageTitle: 'Employee Profile',
    component: EmployeePortal,
  },
];
