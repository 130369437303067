import './App.css';
import { BrowserRouter as Router, Routes, Route, Outlet, Navigate } from 'react-router-dom';
import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from './stores';
import { appActions } from './stores/app';
import { ADMIN_ROUTES, EMPLOYEE_ROUTES, ROUTES, ROUTE_PATH, RouteType } from './common/constants/routes';
import Layout from './components/Shared/Layout';
import Signup from './pages/authentication/Signup';
import Login from './pages/authentication/Login';
import { ConfigProvider } from 'antd';
import NotFound from './components/Shared/NotFound';
import { UserRole } from './common/constants/enums';
import PersonalInformation from './pages/authentication/PersonalInfomation';
import ResetPassword from './pages/authentication/ResetPassword';
import ForgotPassword from './pages/authentication/ForgotPassword';
import { messageRoomActions } from './stores/messageRooms';
import { isAdmin, isEmployee, isRootAdmin } from './utils/auth';
import { lightTheme } from './config/ThemeConfig';
import PersonalInformationUpdate from './pages/authentication/PersonalInfomation/update';
import { openNotification, pathMatchRegexp } from './utils';
import { authActions } from './stores/auth';

const App = (props: any) => {
  const { isAuthenticated, userInfo } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  useEffect(() => {
    if (userInfo?.role) {
      dispatch(appActions?.loadApp());
      dispatch(appActions?.getConstants());

      if (!isEmployee()) {
        dispatch(appActions?.getSettings());
        dispatch(appActions?.getCountries({}));
        dispatch(messageRoomActions.getMessageRoomStatistics({}));
      }
    }
  }, []);

  const EmployeeRoute = useCallback(
    ({ Component }: { Component?: JSX.Element }): JSX.Element => {
      return isAuthenticated && isEmployee() ? Component ? Component : <Outlet /> : <Navigate to={ROUTE_PATH.LOGIN} replace={true} />;
    },
    [userInfo],
  );

  const AdminRoute = useCallback(
    ({ Component }: { Component?: JSX.Element }): JSX.Element => {
      return isAuthenticated && isRootAdmin() ? Component ? Component : <Outlet /> : <Navigate to={ROUTE_PATH.LOGIN} replace={true} />;
    },
    [userInfo],
  );

  ConfigProvider.config({ theme: { primaryColor: '#597ef7' } });
  const themeConfig = { ...lightTheme };

  return (
    <div>
      <ConfigProvider theme={themeConfig}>
        <Router>
          <Routes>
            <Route path={'*'} element={<NotFound />} />

            <Route path={ROUTE_PATH.SIGN_UP} element={<Signup />} />
            <Route path={ROUTE_PATH.LOGIN} element={<Login />} />
            <Route path={ROUTE_PATH.RESET_PASSWORD} element={<ResetPassword />} />
            <Route path={ROUTE_PATH.FORGOT_PASSWORD} element={<ForgotPassword />} />
            <Route path={ROUTE_PATH.PERSONAL_INFO} Component={() => <Layout />}>
              <Route index element={<PersonalInformation />} />
              <Route path={ROUTE_PATH.PERSONAL_INFO_UPDATE} element={<PersonalInformationUpdate />} />
            </Route>

            {/* <Route path="/forgotpassword" Component={ForgotPassword} />
            <Route path="/notfound" Component={NotFound} />
            <Route path="/internalserver" Component={InternalServer} /> */}

            <Route path="/" Component={() => AuthenticatedRoute({ Component: <Layout />, isAuthenticated })}>
              <Route index element={<Navigate to={ROUTE_PATH.DASHBOARD_LIST} />} />
              {ROUTES.map((layout: RouteType, i: number) => {
                return <Route key={i} path={layout.path} element={<layout.component />}></Route>;
              })}
            </Route>
            <Route path="/" Component={() => AdminRoute({ Component: <Layout /> })}>
              {ADMIN_ROUTES.map((layout: RouteType, i: number) => {
                return <Route key={i} path={layout.path} element={<AdminRoute Component={<layout.component />} />}></Route>;
              })}
            </Route>
            <Route path="/" Component={() => EmployeeRoute({ Component: <Layout /> })}>
              {EMPLOYEE_ROUTES.map((layout: RouteType, i: number) => {
                return <Route key={i} path={layout.path} element={<EmployeeRoute Component={<layout.component />} />}></Route>;
              })}
            </Route>
          </Routes>
        </Router>
      </ConfigProvider>
    </div>
  );
};

const AuthenticatedRoute = ({ Component, isAuthenticated }: { Component?: JSX.Element; isAuthenticated: boolean }): JSX.Element => {
  const dispatch = useDispatch();
  const { user: userProfile } = useSelector((state) => state.profile);

  const backToRootAccount = () => {
    openNotification('error', 'You do not have permission to access this page!');
    dispatch(authActions.backRootAccount());
  };

  if (!isAuthenticated) {
    window.location.assign(ROUTE_PATH.LOGIN);
    return <></>;
  }
  if (isAdmin()) {
    window.location.assign(ROUTE_PATH.ADMIN_USER_MANAGEMENT);
  } else if (userProfile?.role === UserRole.client) {
    if (!userProfile.userPermission) {
      backToRootAccount();
      return <></>;
    }

    const destinationRoute = ROUTES.find((route) => pathMatchRegexp(route.path, window.location.pathname));
    if (!destinationRoute?.permission || userProfile?.userPermission?.permissionGroup?.features?.includes(destinationRoute.permission))
      return Component ? Component : <Outlet />;

    const newRoute = ROUTES.find(
      (route) => route.permission && userProfile?.userPermission?.permissionGroup?.features?.includes(route.permission),
    );
    if (newRoute) {
      window.location.assign(newRoute.path);
    } else {
      backToRootAccount();
      return <></>;
    }
  } else return Component ? Component : <Outlet />;

  return <></>;

  // return isAuthenticated === true || isRootAdmin() ? (
  //   Component ? (
  //     Component
  //   ) : (
  //     <Outlet />
  //   )
  // ) : (
  //   <Navigate to={ROUTE_PATH.LOGIN} replace={true} />
  // );
};
export default App;
