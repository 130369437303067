import AdditionalInfoDetail from "@/components/Details/AdditionalInfoDetail";
import PersonalInfoDetail from "@/components/Details/PersonalInfoDetail"

const GeneralInfoTab = (props: any): JSX.Element => {
    const {detail} = props;
    return (
        <>
            <PersonalInfoDetail detail={detail}/>
            <AdditionalInfoDetail detail={detail}/>
        </>
    )
}

export default GeneralInfoTab;