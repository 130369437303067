import { dateFormat, dateFormat2, pageSizeOptions } from '@/common/constants/format';
import { BriefCaseIcon, GlobeIcon, PlusIcon, Users3Icon } from '@/components/common/IconComponents';
import ViewDetailAzureGlobalModal from '@/components/Modals/ViewDetailAzureGlobalModal';
import TableWithScrollButtons from '@/components/Shared/TableWithScrollButtons';
import { useDispatch, useSelector } from '@/stores';
import { insightActions } from '@/stores/insight';
import { IConstants, ICountryConstant, IDashboardTopEmployee, IEmployee, IInsight, IObject } from '@/types/app';
import { matchCountryCodeToFlag, matchCountryCodeToLabel, matchValueToLabel, sortedByCountryName } from '@/utils';
import { MoreOutlined } from '@ant-design/icons';
import { Col, Dropdown, List, MenuProps, Row, Space, Spin, Tag } from 'antd';
import { ColumnsType, TableProps } from 'antd/es/table';
import Paragraph from 'antd/es/typography/Paragraph';
import axios from 'axios';
import dayjs from 'dayjs';
import { isArray } from 'lodash';
import { useEffect, useState } from 'react';

interface DashboardAzureGlobalInsightProps {
  show: boolean;
  setIsShow: (value: boolean) => void; // Ensure the prop is typed as a function
  details: any;
  setIdDetails: (value: any) => void;
  currentPage: number;
  setCurrentPage: (value: number) => void;
  pageSize: number;
  setPageSize: (value: number) => void;
}

const DashboardAzureGlobalInsight: React.FC<DashboardAzureGlobalInsightProps> = ({
  show,
  setIsShow,
  details,
  setIdDetails,
  currentPage,
  setCurrentPage,
  pageSize,
  setPageSize,
}) => {
  const { data, pagination, loading, detail } = useSelector((state) => state.insight);

  const dispatch = useDispatch();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [insightID, setInsightID] = useState<string>();
  const actionMenu: (id: any) => MenuProps['items'] = (id: any) => [
    {
      key: 'View',
      label: (
        <p className="mb-0" onClick={() => {}}>
          View
        </p>
      ),
    },
  ];

  const handlePaginationChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setPageSize(pageSize);
    dispatch(
      insightActions.getInsights({
        page: page,
        limit: pageSize,
      }),
    );
  };

  useEffect(() => {
    insightID && dispatch(insightActions.getInsight({ id: insightID }));
  }, [insightID]);

  useEffect(() => {
    dispatch(insightActions.getInsights({ limit: 5 }));
  }, []);

  useEffect(() => {
    console.log('show o shub', isModalOpen);
  }, [isModalOpen]);

  return (
    <Spin tip="Loading" size="large" spinning={loading[insightActions.getInsights.typePrefix]}>
      <List
        // style={{minHeight: "238px"}}
        className="card-fixed-height"
        itemLayout="horizontal"
        dataSource={data}
        pagination={{
          // align: 'center',
          onChange: (page, pageSize) => {
            handlePaginationChange(page, pageSize);
          },
          size: 'small',
          showSizeChanger: true,
          defaultCurrent: 1,
          total: pagination.total,
          pageSizeOptions: pageSizeOptions,
          pageSize: pageSize,
          current: currentPage,
          // defaultPageSize: 5,
          showTotal: (total: number, range: number[]) => 'Showing ' + range[0] + ' to ' + range[1] + ' of ' + total + ' entries',
        }}
        renderItem={(item: IInsight, index) => (
          <List.Item>
            <div className="new-list-item-border-bottom">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <div className="d-flex align-items-center">
                    {item?.seenAt ? null : (
                      <Tag className="rounded" color="#424141">
                        New
                      </Tag>
                    )}
                    <p className="new-list-item-subtitle-small  mb-0 d-flex align-items-center">
                      {dayjs(item?.createdAt || 0).format(dateFormat2)}
                    </p>
                  </div>
                  <p
                    onClick={async () => {
                      setIsModalOpen(true);
                      setIsShow(true);
                      setInsightID(item?.id);
                      setIdDetails(item);
                      console.log('item?.seenAt', item?.seenAt, !item?.seenAt);
                      if (!item?.seenAt) {
                        await dispatch(insightActions.markAsSeenInsight({ id: item?.id }));
                        await dispatch(insightActions.getInsights({ limit: 5 }));
                      }

                      // setIsAddDocumentDrawerOpen(true);
                      // setIsDetailDocument(true);
                      // setDocumentID(item?.id);
                      // handleNoteDetailClick(item?.id || -1);
                    }}
                    className="new-list-item-title mb-1 cursor-pointer"
                  >
                    {item?.title}
                  </p>
                </div>
                {/* <div className="d-flex align-items-center">
                  <Dropdown placement="bottomRight" menu={{ items: actionMenu(item?.id) }} trigger={['click']} className="">
                    <MoreOutlined className="more-icon" />
                  </Dropdown>
                </div> */}
              </div>
              <Paragraph
                className="new-list-item-subtitle mb-0"
                // title={item?.content}
                ellipsis={{
                  rows: 3,
                  expandable: false,
                  // tooltip: item?.content,
                }}
              >
                {item?.content}
              </Paragraph>
            </div>
          </List.Item>
        )}
      />
      {/* {isModalOpen && <ViewDetailAzureGlobalModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} detail={detail} />} */}
    </Spin>
  );
};

export default DashboardAzureGlobalInsight;
