import axios, { AxiosRequestConfig } from "axios";
import { compile, parse } from "path-to-regexp";
import cloneDeep from "lodash/cloneDeep";
import { stringify } from "qs";
import { store } from "@/stores";
import { ROUTE_PATH } from "@/common/constants/routes";
import config from "@/config";
import { ILoginResponse } from "@/types/app";
import { getRefreshToken, getToken, navigateTo } from "@/utils/auth";
import history from "../history";
import { authActions } from "@/stores/auth";
import { openNotification } from "@/utils";

export type IApiResponse<T> = Promise<{
  success: boolean;
  message: string;
  statusCode: number;
  data: T;
}>;

const request = (
  url: string,
  options: AxiosRequestConfig & { isAuthorized?: boolean }
) => {
  const {
    data,
    baseURL,
    isAuthorized = true,
    headers = { "Content-Type": "application/json" },
  } = options;

  // const navigate = useNavigate();

  const cloneData = cloneDeep(data);

  try {
    let domain = "";
    const urlMatch = url.match(/[a-zA-z]+:\/\/[^/]*/);
    if (urlMatch) {
      [domain] = urlMatch;
      url = url.slice(domain.length);
    }

    const match = parse(url);
    url = compile(url)(data);

    for (const item of match) {
      if (item instanceof Object && item.name in cloneData) {
        delete cloneData[item.name];
      }
    }
    url = domain + url; //why do we need split the URL and then join it back together?
  } catch (e: any) {
    console.error(e?.message);
  }

  options.headers = {
    ...headers,
    version: process.env.VERSION,
    build_date: process.env.BUILD_DATE,
    isAuthorized,
  };
  options.url = url;
  options.baseURL = baseURL;
  if (options.method === "GET") {
    options.params = cloneData;
  } else {
    options.data = cloneData;
  }
  options.paramsSerializer = (params) => {
    return stringify(params, { arrayFormat: "repeat" });
  };

  return axios(options)
    .then((response) => {
      const { statusText, status, data } = response;

      const result = {
        success: true,
        message: statusText,
        statusCode: status,
        data,
      };

      return Promise.resolve(result);
    })
    .catch((error) => {
      const { status } = error.response || {};

      if (status === 401 || status === 403) {
        // store.dispatch(appActions.onLogout());
        // store.dispatch(profileActions.onLogout());
        // TODO

        store.dispatch(authActions.onLogout());
        history.push(ROUTE_PATH.UN_AUTHENTICATED);
        return Promise.reject(error);
      }

      if (status <= 504 && status >= 500) {
        // history.push("/500");
      }
      if (status >= 404 && status < 422) {
      }

      /* eslint-disable */
      return Promise.reject(error);
    });
};

axios.interceptors.request.use(
  function (config) {
    const token = getToken();

    if (
      config.headers.isAuthorized &&
      typeof token === "string" &&
      token !== ""
    ) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    delete config.headers["isAuthorized"];
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    const _config = error?.config;

    // if (error?.response?.status === 401 && !_config?.sent) {
    //   _config.sent = true;
    //   const refreshToken = getRefreshToken();

    //   axios
    //     .post(`${config.BASE_URL}/api/v1/auth/refresh-token`, {
    //       refreshToken,
    //     })
    //     ?.then((resp) => {
    //       if (resp?.data?.data?.accessToken) {
    //         _config.headers = {
    //           ..._config.headers,
    //           authorization: `Bearer ${resp?.data?.data?.accessToken}`,
    //         };

    //         store.dispatch(authActions.saveToken(resp));
    //         _config.sent = false;
    //       }
    //     })
    //     ?.catch((error) => {
    //       if (
    //         error?.response?.data?.message &&
    //         error?.response?.data?.message !== ""
    //       ) {
    //         message = error?.response?.data?.message;
    //       }

    //       store.dispatch(authActions.onLogout());
    //       history.push(ROUTE_PATH.UN_AUTHENTICATED);

    //       return Promise.reject(error);
    //     });
    // }

    let message = error?.response?.data?.statusText;

    if (
      error?.response?.data?.message &&
      error?.response?.data?.message !== ""
    ) {
      message = error?.response?.data?.message;
    }

    openNotification("error", message);

    return Promise.reject(error);
  }
);
export default request;
