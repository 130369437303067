import { useDebouncedSearch } from '@/utils/hooks';
import { Select, Spin } from 'antd';
import { DefaultOptionType, SelectProps } from 'antd/es/select';
import { useEffect, useState } from 'react';

type IProps<OptionType extends DefaultOptionType = DefaultOptionType> = {
  defaultValue?: string[] | string | null;
  value?: string[] | string | number | null;
  placeholder?: string;
  showSuggest?: boolean;
  disabled?: boolean;
  onChange?: (newValue?: string[] | string, option?: OptionType | OptionType[]) => void;
  onBlur?: (e?: any) => void;
  getOptionsCallback: (text?: string) => Promise<OptionType[]>;
  creatable?: boolean;
  createOption?: (newOption: string) => Promise<OptionType>;
} & Omit<SelectProps<string, OptionType>, 'value' | 'onChange' | 'onBlur' | 'disabled' | 'defaultValue'>;

const DebouncedSearchSelect = <OptionType extends DefaultOptionType = DefaultOptionType>({
  defaultValue,
  value,
  showSuggest,
  placeholder = 'Search',
  disabled,
  onChange,
  onBlur,
  getOptionsCallback,
  mode,
  creatable = false,
  createOption,
  loading,
  ...restSelectProps
}: IProps<OptionType>) => {
  const { searchResults, setInputText, inputText } = useDebouncedSearch(getOptionsCallback);

  const [creatingNewOption, setCreatingNewOption] = useState(false);
  const [options, setOptions] = useState<OptionType[]>([]);

  useEffect(() => {
    if (showSuggest) {
      setInputText('');
    }
  }, [setInputText, showSuggest]);

  useEffect(() => {
    setOptions(
      creatable && !searchResults?.result?.length && inputText.length
        ? [{ label: `Add "${inputText}"`, value: inputText }]
        : searchResults?.result,
    );
  }, [creatable, searchResults?.result, inputText]);

  const handleChange = (newValue?: string[] | string, option?: OptionType | OptionType[]) => {
    const newOption = Array.isArray(option) ? option[0] : option;

    if (typeof newOption?.label === 'string' && newOption.label.startsWith('Add "')) {
      // Extract the value from "Add "value""
      setCreatingNewOption(true);
      createOption?.(newOption.value as string)
        .then((opt) => {
          console.log('🚀 ~ file: index.tsx:52 ~ .then ~ opt:', opt, opt.label?.toString() ?? '');
          setOptions([opt]);
          onChange?.(opt.value?.toString(), opt);
          return;
        })
        .finally(() => setCreatingNewOption(false));

      return;
    }

    onChange?.(newValue, option);
  };

  return (
    <Spin spinning={(!!value && !options?.length) || creatingNewOption}>
      <Select
        mode={mode}
        size="middle"
        allowClear
        showSearch
        filterOption={false}
        placeholder={<div className="search-placeholder-text">{placeholder}</div>}
        options={options}
        value={value ? value.toString() : mode === 'multiple' ? [] : undefined}
        defaultValue={defaultValue}
        onSearch={setInputText}
        onChange={handleChange}
        disabled={disabled}
        onBlur={onBlur}
        className="w-100"
        {...restSelectProps}
      />
    </Spin>
  );
};

export default DebouncedSearchSelect;
