import { ICountryConstant, IDocument } from '@/types/app';
import { Button, Form, Input, Modal, Select } from 'antd';
import dayjs from 'dayjs';
import { useRef, useState, DragEvent, ChangeEvent, useEffect } from 'react';
import type { InputRef, ModalProps } from 'antd';
import { useSelector } from '@/stores';
import { constantsToOptions } from '@/utils';
import SearchEmployees from '../SearchEmployees';
import { ReactComponent as IconUpload } from '@/assets/images/new-icons/icon-upload.svg';
import { ReactComponent as IconAttachment } from '@/assets/images/new-icons/attachment-icon.svg';
import { ReactComponent as IconChecked } from '@/assets/images/new-icons/checked-icon.svg';
import SearchUsers from '../SearchUsers';
import { UserRole } from '@/common/constants/enums';

interface IProps extends ModalProps {
  data?: IDocument | null;
  onSubmit: (data: IDocument) => void;
  countries?: ICountryConstant[];
  hideCountryCode?: boolean;
  hideDocumentType?: boolean;
  isChangeDescriptionToTitle?: boolean;
  isShowInputClient?: boolean;
}

const UploadDocumentModal = (props: IProps) => {
  const {
    data,
    onSubmit,
    title = 'Upload Document',
    hideCountryCode,
    hideDocumentType,
    isChangeDescriptionToTitle,
    isShowInputClient,
    ...rest
  } = props;
  const [form] = Form.useForm();
  const [fileName, setFileName] = useState('');
  const [attachmentUpload, setAttachmentUpload] = useState<File | null>(null);
  const { constants, countries } = useSelector((state) => state?.app);
  const defaultCountryID = countries.find((item) => item?.countryCode === 'GB')?.id;

  const initState = {
    countryID: defaultCountryID,
  };

  useEffect(() => {
    if (data) {
      form.setFieldsValue(data);
    } else {
      form.setFieldsValue(initState);
      setAttachmentUpload(null);
      setFileName('');
    }
  }, [data]);

  const [dragActive, setDragActive] = useState(false);
  // ref
  const inputRef = useRef<InputRef>(null);

  // handle drag events
  const handleDrag = function (e: DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();

    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = function (e: DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();

    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFile(e.dataTransfer.files);
    }
  };

  // triggers when file is selected with click
  const handleChange = function (e: ChangeEvent<HTMLInputElement>) {
    e.preventDefault();

    if (e.target.files && e.target.files[0]) {
      handleFile(e.target.files);
    }
  };

  // triggers the input when the button is clicked
  const onButtonClick = () => {
    inputRef?.current?.input?.click?.();
  };

  function handleFile(files: any) {
    setAttachmentUpload(files[0]);
    setFileName(files[0].name);
  }

  const handleSubmitForm = (value: IDocument) => {
    let newValue = { ...value };
    if (attachmentUpload) {
      newValue = { ...newValue, attachmentUpload };
    }
    onSubmit(newValue);
    form.resetFields();
  };

  return (
    <Modal {...rest} title={title} onOk={form.submit} okText="Create" width={600} centered>
      <Form labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} layout="vertical" onFinish={handleSubmitForm} form={form} preserve={false}>
        <div className="row">
          {/* <div className="col-lg-6">
            <Form.Item
              label="Department"
              name="department"
              rules={[{ required: true, message: "This field is required!" }]}
            >
              <Select
                className="w-100"
                placeholder="Select Client"
                options={constantsToOptions(constants?.departments || [])}
              />
            </Form.Item>
          </div> */}

          {!hideCountryCode && (
            <div className={`${hideDocumentType ? 'col-lg-12' : 'col-lg-6'}`}>
              <Form.Item
                label="Country"
                name="countryID"
                rules={[
                  {
                    required: true,
                    message: 'This field is required!',
                  },
                ]}
              >
                <Select
                  placeholder="Select country"
                  filterOption={(input: string, option: any) => {
                    return option?.name?.toLowerCase?.()?.indexOf(input?.toLowerCase()) >= 0;
                  }}
                  showSearch
                >
                  {countries?.map((item: any, index: number) => {
                    return (
                      <Select.Option key={item?.id} value={item?.id} name={item?.name}>
                        <img src={item?.flag} width="16" height="12" alt={`${item}`} className="mr-2" />
                        {item?.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </div>
          )}
          {!hideDocumentType && (
            <div className={`${hideCountryCode ? 'col-lg-12' : 'col-lg-6'}`}>
              <Form.Item label="Document Type" name="type" rules={[{ required: true, message: 'This field is required!' }]}>
                <Select
                  className="w-100"
                  placeholder="Select document type"
                  options={constantsToOptions(constants?.documentTypes || [])}
                ></Select>
              </Form.Item>
            </div>
          )}
          {/* <div className="col-lg-12">
            <Form.Item name="employeeID" labelAlign="left" label="Employee">
              <SearchEmployees
                showSuggest
                // user={item?.user}
                onSelect={(value) => {
                  form.setFieldValue("employeeID", value);
                }}
              />
            </Form.Item>
          </div> */}

          {isShowInputClient && (
            <div className="col-lg-12">
              <Form.Item label="Client" name="userID" rules={[{ required: true, message: 'This field is required!' }]}>
                <SearchUsers
                  showSuggest
                  onSelect={(value) => {
                    form.setFieldValue('userID', value);
                  }}
                  role={UserRole.client}
                />
              </Form.Item>
            </div>
          )}

          {!isChangeDescriptionToTitle ? (
            <div className="col-lg-12">
              <Form.Item name="description" label="Description">
                <Input.TextArea size="large" maxLength={5000} />
              </Form.Item>
            </div>
          ) : (
            <div className="col-lg-12">
              <Form.Item
                name="title"
                label="Document Title"
                rules={[
                  {
                    required: true,
                    message: 'This field is required!',
                  },
                ]}
              >
                <Input className="w-100" />
              </Form.Item>
            </div>
          )}

          <div className="col-lg-12 mb-4">
            <Form.Item
              label="Document"
              name="fileName"
              // rules={[{ required: true, message: 'This field is required!' }]}
            >
              <div id="form-file-upload" onDragEnter={handleDrag}>
                <Input ref={inputRef} type="file" id="input-file-upload" multiple={false} onChange={handleChange} />
                <label
                  style={{ backgroundColor: '#FBFAF8' }}
                  id="label-file-upload"
                  htmlFor="input-file-upload"
                  className={dragActive ? 'drag-active' : ''}
                >
                  <div>
                    <IconUpload />
                    <p style={{ color: '#8A8988', fontSize: '14px', lineHeight: '20px', fontWeight: 500 }}>
                      Drag and drop your file here or
                    </p>
                    <Button className="upload-button" onClick={onButtonClick}>
                      Click here
                    </Button>
                  </div>
                </label>
                {dragActive && (
                  <div
                    id="drag-file-element"
                    onDrop={handleDrop}
                    onDragEnter={handleDrag}
                    onDragLeave={handleDrag}
                    onDragOver={handleDrag}
                  ></div>
                )}
              </div>
              {fileName && (
                <div className="d-flex align-items-center justify-content-between" style={{ marginTop: '16px' }}>
                  <div className="d-flex align-items-center" style={{ gap: '6px' }}>
                    <IconAttachment />
                    <p style={{ margin: 0, fontSize: '14px', fontWeight: 500, lineHeight: '20px', color: '#666564' }}>{fileName}</p>
                  </div>
                  <IconChecked />
                </div>
              )}
            </Form.Item>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default UploadDocumentModal;
