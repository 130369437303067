import api from "@/common/api";
import { SLICE_NAME } from "@/common/constants/stores";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  IUser,
  IUserResponse,
  IPagination,
  IChangePasswordForm,
} from "@/types/app";
import { navigateTo } from "@/utils/auth";
import { openNotification } from "@/utils";
import { ROUTE_PATH } from "@/common/constants/routes";

export interface IUserState {
  data?: IUser[];
  loading: { [key: string]: boolean };
  pagination: IPagination;
  detail?: IUser;
}

export const initialState: IUserState = {
  pagination: {
    total: 0,
    page: 0,
    limit: 10,
    totalPage: 0,
  },
  data: [],
  loading: {},
};

const getEndClients = createAsyncThunk(
  `${SLICE_NAME.CLIENT_USERS}/getEndClients`,
  async (query: any | undefined, { rejectWithValue }) => {
    try {
      const data = await api.getEndClients<IUserResponse>(query);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const getEndClient = createAsyncThunk(
  `${SLICE_NAME.CLIENT_USERS}/getEndClient`,
  async (query: any | undefined, { rejectWithValue }) => {
    try {
      const data = await api.getEndClient<{ data: IUser }>(query);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const createEndClient = createAsyncThunk(
  `${SLICE_NAME.CLIENT_USERS}/createEndClient`,
  async (form: IUser, { rejectWithValue }) => {
    try {
      const data = await api.createEndClient<IUser>(form);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const updateEndClient = createAsyncThunk(
  `${SLICE_NAME.CLIENT_USERS}/updateEndClient`,
  async (form: IUser, { rejectWithValue }) => {
    try {
      const data = await api.updateEndClient<IUser>(form);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const deleteEndClient = createAsyncThunk(
  `${SLICE_NAME.CLIENT_USERS}/deleteEndClient`,
  async (query: any | undefined, { rejectWithValue }) => {
    try {
      const data = await api.deleteEndClient<{ data: IUser }>(query);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const mapUserFromResponse = (response: IUser) => {
  return {
    ...response,
    createdAt: response.createdAt ? response.createdAt * 1000 : 0,
  };
};

const endClientsSlice = createSlice({
  name: SLICE_NAME.CLIENT_USERS,
  initialState: initialState,
  reducers: {
    onResetDetail(state) {
      state.detail = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getEndClients.fulfilled, (state, { payload }) => {
      state.loading[getEndClients.typePrefix] = false;
      if (payload?.data && payload?.data?.data) {
        state.data = payload?.data?.data.map((item) =>
          mapUserFromResponse(item)
        );
        state.pagination = payload?.data?.pagination;
      }
    });
    builder.addCase(getEndClients.pending, (state, { payload }) => {
      state.loading[getEndClients.typePrefix] = true;
    });
    builder.addCase(getEndClients.rejected, (state, { payload }) => {
      state.loading[getEndClients.typePrefix] = false;
    });
    builder.addCase(getEndClient.fulfilled, (state, { payload }) => {
      state.loading[getEndClient.typePrefix] = false;
      state.detail = mapUserFromResponse(payload.data.data);
    });
    builder.addCase(getEndClient.pending, (state, { payload }) => {
      state.loading[getEndClient.typePrefix] = true;
    });
    builder.addCase(getEndClient.rejected, (state, { payload }) => {
      state.loading[getEndClient.typePrefix] = false;
    });
    builder.addCase(createEndClient.fulfilled, (state, { payload }) => {
      state.loading[createEndClient.typePrefix] = false;
      // navigateTo(ROUTE_PATH.CLIENT_USERS_MANAGEMENT);
      openNotification("success", "Create new end client successfully!");
    });
    builder.addCase(createEndClient.pending, (state, { payload }) => {
      state.loading[createEndClient.typePrefix] = true;
    });
    builder.addCase(createEndClient.rejected, (state, { payload }) => {
      state.loading[createEndClient.typePrefix] = false;
    });
    builder.addCase(updateEndClient.fulfilled, (state, { payload }) => {
      state.loading[updateEndClient.typePrefix] = false;
      openNotification("success", "Update successfully!");
    });
    builder.addCase(updateEndClient.pending, (state, { payload }) => {
      state.loading[updateEndClient.typePrefix] = true;
    });
    builder.addCase(updateEndClient.rejected, (state, { payload }) => {
      state.loading[updateEndClient.typePrefix] = false;
    });
    builder.addCase(deleteEndClient.fulfilled, (state, { payload }) => {
      state.loading[deleteEndClient.typePrefix] = false;
      openNotification(
        "success",
        `Deleted successfully!`
      );
    });
    builder.addCase(deleteEndClient.pending, (state, { payload }) => {
      state.loading[deleteEndClient.typePrefix] = true;
    });
    builder.addCase(deleteEndClient.rejected, (state, { payload }) => {
      state.loading[deleteEndClient.typePrefix] = false;
    });
  },
});

export const endClientsReducer = endClientsSlice.reducer;
export const endClientsCaseReducers = endClientsSlice.caseReducers;
export const endClientsActions = {
  ...endClientsSlice.actions,
  getEndClients,
  getEndClient,
  createEndClient,
  updateEndClient,
  deleteEndClient,
};
