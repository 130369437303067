import { pageSizeOptions } from '@/common/constants/format';
import { SearchIcon } from '@/components/common/IconComponents';
import CreateUserPermissionModal from '@/components/Modals/CreateUserPermissionModal';
import SetPermissionGroupModal from '@/components/Modals/SetPermissionGroupModal';
import SetPermissionOnProfileModal from '@/components/Modals/SetPermissionOnProfileModal';
import TableWithScrollButtons from '@/components/Shared/TableWithScrollButtons';
import { useDispatch, useSelector } from '@/stores';
import { userPermissionActions } from '@/stores/userPermission';
import { IDocument, IUser } from '@/types/app';
import { Button, Input, Spin } from 'antd';
import { TablePaginationConfig } from 'antd/es/table';
import { SorterResult } from 'antd/es/table/interface';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { getColumns } from './columns';

const UserPermissionTable = () => {
  const dispatch = useDispatch();
  const { data, pagination, loading } = useSelector((state) => state?.userPermission);
  const { userInfo } = useSelector((state) => state.auth);

  const [isModalAddUserOpen, setIsModalAddUserOpen] = useState<boolean>(false);
  const [isModalSetPermissionGroupOpen, setIsModalSetPermissionGroupOpen] = useState<boolean>(false);
  const [isModalSetPermissionOnProfileOpen, setIsModalSetPermissionOnProfileOpen] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<IUser | null>(null);

  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    dispatch(
      userPermissionActions.getUserPermissions({
        role: userInfo?.role!,
      }),
    );
  }, [userInfo]);

  const handleSortAndPaginationChange = (
    pagination: TablePaginationConfig,
    sorter: SorterResult<IDocument> | SorterResult<IDocument>[],
  ) => {
    dispatch(
      userPermissionActions.getUserPermissions({
        page: pagination.current,
        limit: pagination.pageSize,
        keyword: searchText,
        sortBy: !Array.isArray(sorter) && sorter.order ? `${sorter.field} ${sorter.order === 'ascend' ? 'ASC' : 'DESC'}` : undefined,
        role: userInfo?.role!,
      }),
    );
  };

  const handleSearchSubmit = (e: string) => {
    const keyword = e.trim();
    setSearchText(keyword);
    dispatch(userPermissionActions.getUserPermissions({ keyword, role: userInfo?.role! }));
  };

  const handleOpenModalSetPermissionGroupEdit = useCallback((item: IUser) => {
    setIsModalSetPermissionGroupOpen(true);
    setSelectedItem(item);
  }, []);

  const handleOpenModalSetPermissionOnProfile = useCallback(
    (item: IUser) => {
      setIsModalSetPermissionOnProfileOpen(true);
      setSelectedItem(item);
    },
    [dispatch, searchText, userInfo?.role],
  );

  const tableColumns = useMemo(
    () =>
      getColumns({
        onSetPermissionGroup: handleOpenModalSetPermissionGroupEdit,
        onSetPermissionOnProfile: handleOpenModalSetPermissionOnProfile,
      }),
    [handleOpenModalSetPermissionGroupEdit, handleOpenModalSetPermissionOnProfile],
  );

  return (
    <>
      <div className="bg-white rounded">
        <div className="card-header d-flex justify-content-between">
          <Input
            prefix={<SearchIcon className="mr-0" />}
            placeholder="Search"
            onPressEnter={(e: any) => handleSearchSubmit(e?.target?.value)}
            className="w-100 mr-2 page-listing-search-input"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
          <Button className="d-flex align-items-center" type="primary" onClick={() => setIsModalAddUserOpen(true)}>
            Add User
          </Button>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <Spin
              spinning={
                loading[userPermissionActions.getUserPermissions.typePrefix] ||
                loading[userPermissionActions.deleteUserPermission.typePrefix] ||
                false
              }
            >
              <TableWithScrollButtons
                columns={tableColumns}
                dataSource={data}
                size="small"
                scroll={{ x: 800 }}
                onChange={(pagination, filter, sorter) => {
                  handleSortAndPaginationChange(pagination, sorter);
                }}
                pagination={{
                  showSizeChanger: true,
                  defaultCurrent: 1,
                  total: pagination.total,
                  position: ['bottomLeft'],
                  pageSizeOptions: pageSizeOptions,
                  showTotal: (total: number, range: number[]) => 'Showing ' + range[0] + ' to ' + range[1] + ' of ' + total + ' entries',
                }}
                // onChange={(pagination) => {
                //   onChangeFilter({
                //     page: pagination?.current,
                //     limit: pagination?.pageSize,
                //   });
                // }}
              />
            </Spin>
          </div>
        </div>
      </div>

      <CreateUserPermissionModal
        open={isModalAddUserOpen}
        destroyOnClose
        onClose={() => {
          setIsModalAddUserOpen(false);
        }}
        onSubmitSuccess={() => {
          dispatch(
            userPermissionActions.getUserPermissions({
              keyword: searchText,
              role: userInfo?.role!,
            }),
          );
        }}
      />
      <SetPermissionGroupModal
        data={selectedItem}
        open={isModalSetPermissionGroupOpen}
        destroyOnClose
        onClose={() => {
          setIsModalSetPermissionGroupOpen(false);
          setSelectedItem(null);
        }}
        onSubmitSuccess={() => {
          dispatch(
            userPermissionActions.getUserPermissions({
              keyword: searchText,
              role: userInfo?.role!,
            }),
          );
        }}
      />
      <SetPermissionOnProfileModal
        data={selectedItem}
        open={isModalSetPermissionOnProfileOpen}
        destroyOnClose
        onClose={() => {
          setIsModalSetPermissionOnProfileOpen(false);
          setSelectedItem(null);
        }}
        onSubmitSuccess={() => {
          dispatch(
            userPermissionActions.getUserPermissions({
              keyword: searchText,
              role: userInfo?.role!,
            }),
          );
        }}
      />
    </>
  );
};

export default UserPermissionTable;
