import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { IAuthoriseExpensesListTable } from "@/types/app";
import { Button, DatePicker, Form, Input, Select, Upload } from "antd";
import dayjs from "dayjs";
import { dateFormat } from "@/common/constants/format";
import { enumOptions } from "@/common/constants/enums";
import { UploadOutlined } from "@ant-design/icons";

const AuthoriseExpensesForm = () => {
  const fakeData: IAuthoriseExpensesListTable[] = [
    {
      id: "1",
      reference: "AG-001",
      forename: "Marshall",
      surname: "Nichols",
      dateOfExpense: "18/08/2023",
      expenseType: "mileage",
      currency: "VND",
      amount: 100,
      tax: 10,
      filePath:
        "https://production-storage-cis.s3.eu-west-2.amazonaws.com/uploads/zoho/pdf/433dd7d2-0a42-4c95-b89f-9d712a6df163-signed_zoho.pdf",
      status: "rejected",
      updatedDate: "18/08/2023",
      rejectReason: "abcd",
    },
    {
      id: "2",
      reference: "AG-002",
      forename: "Susie",
      surname: "Willis",
      dateOfExpense: "18/08/2023",
      expenseType: "travel",
      currency: "VND",
      amount: 100,
      tax: 10,
      filePath:
        "https://production-storage-cis.s3.eu-west-2.amazonaws.com/uploads/zoho/pdf/866141ce-67dc-4ae0-9bde-bf4d1f455084-signed_zoho.pdf",
      status: "awaitingApproval",
      updatedDate: "18/08/2023",
    },
    {
      id: "3",
      reference: "AG-003",
      forename: "Debra",
      surname: "Stewart",
      dateOfExpense: "18/08/2023",
      expenseType: "subsistence",
      currency: "VND",
      amount: 100,
      tax: 10,
      filePath:
        "https://production-storage-cis.s3.eu-west-2.amazonaws.com/uploads/zoho/pdf/433dd7d2-0a42-4c95-b89f-9d712a6df163-signed_zoho.pdf",
      status: "approved",
      updatedDate: "18/08/2023",
    },
    {
      id: "4",
      reference: "AG-004",
      forename: "Debra",
      surname: "Stewart",
      dateOfExpense: "18/08/2023",
      expenseType: "subsistence",
      currency: "VND",
      amount: 100,
      tax: 10,
      filePath:
        "https://production-storage-cis.s3.eu-west-2.amazonaws.com/uploads/zoho/pdf/433dd7d2-0a42-4c95-b89f-9d712a6df163-signed_zoho.pdf",
      status: "approved",
      updatedDate: "18/08/2023",
    },
    {
      id: "5",
      reference: "AG-005",
      forename: "Debra",
      surname: "Stewart",
      dateOfExpense: "18/08/2023",
      expenseType: "subsistence",
      currency: "VND",
      amount: 100,
      tax: 10,
      filePath:
        "https://production-storage-cis.s3.eu-west-2.amazonaws.com/uploads/zoho/pdf/433dd7d2-0a42-4c95-b89f-9d712a6df163-signed_zoho.pdf",
      status: "approved",
      updatedDate: "18/08/2023",
    },
    {
      id: "6",
      reference: "AG-006",
      forename: "Debra",
      surname: "Stewart",
      dateOfExpense: "18/08/2023",
      expenseType: "subsistence",
      currency: "VND",
      amount: 100,
      tax: 10,
      filePath:
        "https://production-storage-cis.s3.eu-west-2.amazonaws.com/uploads/zoho/pdf/433dd7d2-0a42-4c95-b89f-9d712a6df163-signed_zoho.pdf",
      status: "approved",
      updatedDate: "18/08/2023",
    },
    {
      id: "7",
      reference: "AG-007",
      forename: "Debra",
      surname: "Stewart",
      dateOfExpense: "18/08/2023",
      expenseType: "subsistence",
      currency: "VND",
      amount: 100,
      tax: 10,
      filePath:
        "https://production-storage-cis.s3.eu-west-2.amazonaws.com/uploads/zoho/pdf/433dd7d2-0a42-4c95-b89f-9d712a6df163-signed_zoho.pdf",
      status: "approved",
      updatedDate: "18/08/2023",
    },
    {
      id: "8",
      reference: "AG-008",
      forename: "Debra",
      surname: "Stewart",
      dateOfExpense: "18/08/2023",
      expenseType: "subsistence",
      currency: "VND",
      amount: 100,
      tax: 10,
      filePath:
        "https://production-storage-cis.s3.eu-west-2.amazonaws.com/uploads/zoho/pdf/433dd7d2-0a42-4c95-b89f-9d712a6df163-signed_zoho.pdf",
      status: "approved",
      updatedDate: "18/08/2023",
    },
    {
      id: "9",
      reference: "AG-009",
      forename: "Debra",
      surname: "Stewart",
      dateOfExpense: "18/08/2023",
      expenseType: "subsistence",
      currency: "VND",
      amount: 100,
      tax: 10,
      filePath:
        "https://production-storage-cis.s3.eu-west-2.amazonaws.com/uploads/zoho/pdf/433dd7d2-0a42-4c95-b89f-9d712a6df163-signed_zoho.pdf",
      status: "approved",
      updatedDate: "18/08/2023",
    },
    {
      id: "10",
      reference: "AG-0010",
      forename: "Debra",
      surname: "Stewart",
      dateOfExpense: "18/08/2023",
      expenseType: "subsistence",
      currency: "VND",
      amount: 100,
      tax: 10,
      filePath:
        "https://production-storage-cis.s3.eu-west-2.amazonaws.com/uploads/zoho/pdf/433dd7d2-0a42-4c95-b89f-9d712a6df163-signed_zoho.pdf",
      status: "approved",
      updatedDate: "18/08/2023",
    },
    {
      id: "11",
      reference: "AG-0011",
      forename: "Debra",
      surname: "Stewart",
      dateOfExpense: "18/08/2023",
      expenseType: "subsistence",
      currency: "VND",
      amount: 100,
      tax: 10,
      filePath:
        "https://production-storage-cis.s3.eu-west-2.amazonaws.com/uploads/zoho/pdf/433dd7d2-0a42-4c95-b89f-9d712a6df163-signed_zoho.pdf",
      status: "approved",
      updatedDate: "18/08/2023",
    },
  ];

  const initialState: IAuthoriseExpensesListTable = {
    id: "",
    reference: "",
    forename: "",
    surname: "",
    dateOfExpense: "",
    expenseType: "mileage",
    currency: "VND",
    filePath: "",
    status: "awaitingApproval",
  };

  const currencyList = [
    {
      id: 1,
      value: "VND",
      label: "VND",
    },
    {
      id: 2,
      value: "USD",
      label: "USD",
    },
  ];

  const [requestData, setRequestData] =
    useState<IAuthoriseExpensesListTable>(initialState);

  const [form] = Form.useForm();

  const { id } = useParams();

  useEffect(
    function () {
      const result = fakeData.find((item) => item.id === id);
      if (result) setRequestData(result);
      else setRequestData(requestData);
    },
    [id]
  );

  const transformBeforeSubmit = (e: IAuthoriseExpensesListTable) => {
    return {
      ...e,
      id,
      forename: e.forename?.trim?.(),
      surname: e.surname?.trim?.(),
      dateOfExpense: e.dateOfExpense
        ? dayjs(e.dateOfExpense, dateFormat).unix()
        : 0,
    };
  };

  const handleSubmitForm = (e: IAuthoriseExpensesListTable) => {
    const result = transformBeforeSubmit(e);
  };

  useEffect(() => {
    form.resetFields();
  }, [requestData]);

  return (
    <Form
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      layout="vertical"
      initialValues={requestData}
      onFinish={handleSubmitForm}
      form={form}
    >
      <div className="container-fluid mt-3">
        <div className="row clearfix">
          <div className="col-lg-12">
            <div className="bg-white shadow-lg rounded">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6">
                    <Form.Item label="Forename" name="forename">
                      <Input
                        className="w-100"
                        pattern="[a-zA-Z ]+"
                        title="Please enter on alphabets only."
                        placeholder="Enter forename"
                        name="forename"
                        required
                      />
                    </Form.Item>
                  </div>
                  <div className="col-md-6">
                    <Form.Item label="Surname" name="surname">
                      <Input
                        pattern="[a-zA-Z ]+"
                        title="Please enter on alphabets only."
                        placeholder="Enter surname"
                        required
                      />
                    </Form.Item>
                  </div>
                  {/* <div className="col-md-6">
                    <Form.Item label="Client Name" name="clientName">
                      <Input
                        className="w-100"
                        placeholder="Enter client name"
                      />
                    </Form.Item>
                  </div> */}
                  <div className="col-md-6">
                    <Form.Item
                      label="Date of Expense"
                      name="dateOfExpense"
                      getValueProps={(i) => ({
                        value: i ? dayjs(i, dateFormat) : "",
                      })}
                    >
                      <DatePicker format={dateFormat} className="w-100 p-12" />
                    </Form.Item>
                  </div>
                  <div className="col-md-6">
                    <Form.Item label="Expense Type" name="expenseType">
                      <Select
                        placeholder="Select expense type"
                        options={enumOptions.ExpenseType}
                      />
                    </Form.Item>
                  </div>
                  <div className="col-md-6">
                    <Form.Item label="Currency" name="currency">
                      <Select placeholder="Select currency">
                        {currencyList.map((item) => {
                          return (
                            <Select.Option key={item.id} value={item.value}>
                              {item.label}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </div>
                  <div className="col-md-6">
                    <Form.Item label="Amount" name="amount">
                      <Input
                        className="w-100"
                        // prefix="€"
                        step="100"
                        placeholder="Enter amount"
                      />
                    </Form.Item>
                  </div>
                  <div className="col-md-6">
                    <Form.Item label="Tax" name="tax">
                      <Input
                        className="w-100"
                        suffix="%"
                        step="10"
                        placeholder="Enter tax"
                      />
                    </Form.Item>
                  </div>
                  <div className="col-md-6">
                    <Form.Item label="Receipt File" name="pathFile">
                      <Upload>
                        <Button icon={<UploadOutlined />}>Upload</Button>
                      </Upload>
                    </Form.Item>
                  </div>
                </div>
              </div>
              <div className="card-footer col-md-12 text-right">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="btn btn-primary"
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
};

export default AuthoriseExpensesForm;
