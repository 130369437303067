import api from '@/common/api';
import { EndpointsParams } from '@/common/api/endpoints';
import { SLICE_NAME } from '@/common/constants/stores';
import {
  IActivityLog,
  IActivityLogResponse,
  IPagination
} from '@/types/app';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export interface IActivityLogState {
  data?: IActivityLog[];
  loading: Record<string, boolean>;
  pagination: IPagination;
  detail?: IActivityLog[];
  notePagination?: IPagination;
}

export const initialState: IActivityLogState = {
  pagination: {
    total: 0,
    page: 0,
    limit: 10,
    totalPage: 0,
  },
  data: [],
  loading: {},
};

const getActivityLogs = createAsyncThunk(
  `${SLICE_NAME.ACTIVITY_LOG}/getActivityLogs`,
  async (query: any | undefined, { rejectWithValue }) => {
    try {
      const data = await api.getActivityLogs<IActivityLogResponse>(query);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const getActivityLog = createAsyncThunk(
  `${SLICE_NAME.ACTIVITY_LOG}/getActivityLog`,
  async (query: EndpointsParams['getActivityLog'], { rejectWithValue }) => {
    try {
      const data = await api.getActivityLog<{ data: IActivityLog }>(query);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const mapFromResponse = (response: IActivityLog) => {
  return {
    ...response,
  };
};

const activityLogSlice = createSlice({
  name: SLICE_NAME.ACTIVITY_LOG,
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getActivityLogs.fulfilled, (state, { payload }) => {
      state.loading[getActivityLogs.typePrefix] = false;
      state.data = payload.data.data.map((item) => mapFromResponse(item));
      state.pagination = payload.data.pagination;
    });
    builder.addCase(getActivityLogs.pending, (state, { payload }) => {
      state.loading[getActivityLogs.typePrefix] = true;
    });
    builder.addCase(getActivityLogs.rejected, (state, { payload }) => {
      state.loading[getActivityLogs.typePrefix] = false;
    });
    builder.addCase(getActivityLog.fulfilled, (state, { payload }) => {
      state.loading[getActivityLog.typePrefix] = false;
      state.detail = mapFromResponse(payload.data.data);
    });
    builder.addCase(getActivityLog.pending, (state, { payload }) => {
      state.loading[getActivityLog.typePrefix] = true;
    });
    builder.addCase(getActivityLog.rejected, (state, { payload }) => {
      state.loading[getActivityLog.typePrefix] = false;
    });
  },
});

export const activityLogReducer = activityLogSlice.reducer;
export const activityLogCaseReducers = activityLogSlice.caseReducers;
export const activityLogActions = {
  ...activityLogSlice.actions,
  getActivityLogs,
  getActivityLog,
};
