import { dateFormat } from '@/common/constants/format';
import { useDispatch, useSelector } from '@/stores';
import { employeeActions } from '@/stores/employee';
import { offboardingActions } from '@/stores/offboarding';
import { onboardingActions } from '@/stores/onboarding';
import { IEmployee, IEmployeeRequestTermination } from '@/types/app';
import { constantsToOptions } from '@/utils';
import { Alert, DatePicker, Form, Input, Modal, Popconfirm, Select } from 'antd';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

const UpdateStatusModal = (props: any) => {
  const { employeeId, isOpen, setIsOpen, statusType, detail } = props;
  const { userInfo } = useSelector((state) => state.auth);
  const { constants } = useSelector((state) => state.app);

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { id } = useParams();

  const handleCloseModal = () => {
    form.resetFields();
    setIsOpen(false);
  };

  const transformBeforeSubmit = (e: IEmployee) => {
    return {
      ...detail,
      ...e,
      id,
      role: userInfo?.role,
      startDate: detail?.startDate ? dayjs(detail?.startDate).unix() : 0,
      endDate: detail?.endDate ? dayjs(detail?.endDate).unix() : 0,
      dateOfBirth: detail?.dateOfBirth ? dayjs(detail?.dateOfBirth).unix() : 0,
    };
  };

  const handleSubmitForm = async (e: IEmployee) => {
    const result = transformBeforeSubmit(e);
    if (statusType === 'onboarding') {
      await dispatch(
        onboardingActions.updateOnboardingStatus({
          id: employeeId,
          registrationStatus: result.registrationStatus,
          role: userInfo?.role!,
          redirectURL: `${window?.location?.origin}/reset-password`,
          loginURL: `${window?.location?.origin}/login`,
        }),
      );
    } else {
      await dispatch(
        offboardingActions.updateOffboardingStatus({
          id: employeeId,
          offBoardingStatus: result.offBoardingStatus,
          role: userInfo?.role!,
        }),
      );
    }

    await dispatch(employeeActions.getEmployee({ id: id, role: userInfo?.role }));


    form.resetFields();
    handleCloseModal();
  };

  useEffect(() => {
    form.resetFields();
  }, []);

  useEffect(() => {
    if (detail) form.setFieldsValue(detail);
  }, [detail]);

  return (
    <Modal title="Update Status" open={isOpen} okText="Save" onCancel={() => handleCloseModal()} onOk={form.submit} centered>
      <Form labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} layout="vertical" onFinish={handleSubmitForm} form={form}>
        <div className="row">
          {statusType === 'onboarding' ? (
            <div className="col-md-12">
              <Form.Item name="registrationStatus" rules={[{ required: true, message: 'This field is required!' }]}>
                <Select placeholder="Select status" options={constantsToOptions(constants?.registrationStatuses || [])} />
              </Form.Item>
            </div>
          ) : (
            <div className="col-md-12">
              <Form.Item name="offBoardingStatus" rules={[{ required: true, message: 'This field is required!' }]}>
                <Select placeholder="Select status" options={constantsToOptions(constants?.offBoardingStatuses || [])} />
              </Form.Item>
            </div>
          )}
        </div>
      </Form>
    </Modal>
  );
};

export default UpdateStatusModal;
