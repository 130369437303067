import { IMenus, PermissionFeature, UserRole } from '@/types/app';
import { ROUTE_PATH } from './routes';
import {
  ChatIcon,
  DashboardIcon,
  DocumentGuideIcon,
  InvoiceIcon,
  ReportIcon,
  SettingIcon,
  UsersIcon,
} from '@/components/common/IconComponents';
const menus: IMenus = [
  {
    id: '1',
    icon: 'dashboard',
    iconDetails: 'home',
    name: 'Dashboard',
    route: '/dashboard',
  },
  {
    id: '_11',
    menuParentId: '-1',
    breadcrumbParentId: '1',
    name: 'Websocket',
    route: '/ws',
  },

  {
    id: '2',
    breadcrumbParentId: '1',
    name: 'All Projects',
    nameDetails: 'All Projects',
    icon: 'folder',
    route: '/projects',
  },
  {
    id: '_21',
    menuParentId: '-1',
    breadcrumbParentId: '2',
    name: 'Project Detail',
    nameDetails: 'Project Detail',
    route: '/projects/:id',
  },
  {
    id: '_211',
    menuParentId: '-1',
    breadcrumbParentId: '_21',
    name: 'View conversation',
    route: '/projects/:id/conversations',
  },

  {
    id: '3',
    breadcrumbParentId: '1',
    name: 'Users',
    icon: 'users',
    route: '/users',
  },
  {
    id: '_31',
    breadcrumbParentId: '1',
    menuParentId: '3',
    name: 'Freelancers',
    route: '/users/freelancers',
  },
  {
    id: '_311',
    menuParentId: '-1',
    breadcrumbParentId: '_31',
    name: 'Freelancers',
    route: '/users/freelancers/:id',
  },

  {
    id: '_32',
    breadcrumbParentId: '1',
    menuParentId: '3',
    name: 'Clients',
    route: '/users/clients',
  },
  {
    id: '_321',
    menuParentId: '-1',
    breadcrumbParentId: '_32',
    name: 'Clients',
    route: '/users/clients/:id',
  },

  {
    id: '4',
    menuParentId: '-1',
    name: 'Profile',
    icon: 'user',
    route: '/profile',
  },
  {
    id: '_41',
    menuParentId: '-1',
    breadcrumbParentId: '4',
    name: 'Settings',
    route: '/profile/setting',
  },
  {
    id: '_411',
    menuParentId: '-1',
    breadcrumbParentId: '_41',
    name: 'Add new Team Member',
    route: '/profile/setting/new',
  },
];

export const content: {
  id: number;
  icon?: JSX.Element | string;
  label: string;
  to: string;
  roles?: UserRole[];
  onlyParent?: boolean;
  permission?: PermissionFeature;
  children?: {
    id: number;
    icon?: JSX.Element | string;
    label?: string;
    to: string;
    roles?: UserRole[];
    permission?: PermissionFeature;
  }[];
}[] = [
  {
    id: 11,
    icon: <DashboardIcon />,
    label: 'Dashboard',
    to: ROUTE_PATH.DASHBOARD,
    roles: ['client', 'company'],
    permission: 'dashboard',
    children: [
      {
        id: 111,
        label: 'Dashboard',
        to: ROUTE_PATH.DASHBOARD_LIST,
        roles: ['client', 'company'],
        permission: 'dashboard',
      },
      {
        id: 112,
        label: 'All Countries',
        to: ROUTE_PATH.DASHBOARD_ALL_COUNTRIES,
        roles: ['client', 'company'],
        permission: 'dashboard',
      },
      {
        id: 113,
        label: 'All Clients',
        to: ROUTE_PATH.DASHBOARD_ALL_CLIENTS,
        roles: ['company'],
        permission: 'dashboard',
      },
      {
        id: 113,
        label: 'All Clients',
        to: ROUTE_PATH.DASHBOARD_ALL_END_CLIENTS,
        roles: ['client'],
        permission: 'dashboard',
      },
    ],
  },
  {
    id: 12,
    icon: <UsersIcon />,
    label: 'Employees',
    to: ROUTE_PATH.EMPLOYEE,
    roles: ['client', 'company'],
    permission: 'employees',
    children: [
      {
        id: 121,
        label: 'Manage Employees',
        to: ROUTE_PATH.EMPLOYEE_LIST,
        roles: ['client', 'company'],
      },
      // {
      //   id: 122,
      //   label: 'New Hire Request',
      //   to: ROUTE_PATH.NEW_HIRE,
      //   roles: ['company'],
      // },
      {
        id: 122,
        label: 'Request New Hires',
        to: ROUTE_PATH.NEW_HIRE,
        roles: ['client', 'company'],
      },
      {
        id: 124,
        label: 'Onboardings',
        to: ROUTE_PATH.ONBOARDING,
        roles: ['client', 'company'],
      },
      {
        id: 125,
        label: 'Offboardings',
        to: ROUTE_PATH.OFFBOARDING,
        roles: ['client', 'company'],
      },
      {
        id: 126,
        label: 'Activity Logs',
        to: ROUTE_PATH.EMPLOYEE_ACTIVITY_LOGS,
        roles: ['client', 'company'],
      },
    ],
  },

  // {
  //   id: 6,
  //   icon: "icon-grid",
  //   label: "Reports",
  //   to: ROUTE_PATH.REPORT,
  //   roles: ["client", "company"],
  // },
  // {
  //   "id": 7,
  //   "icon": "icon-clock",
  //   "label": "Authorise Hours",
  //   "to": ROUTE_PATH.AUTHORISE_HOURS,
  // },
  // {
  //   "id": 8,
  //   "icon": "icon-credit-card",
  //   "label": "Authorise Expenses",
  //   "to": ROUTE_PATH.AUTHORISE_EXPENSES,
  // },
  {
    id: 17,
    icon: <InvoiceIcon />,
    label: 'Invoices',
    to: ROUTE_PATH.INVOICES,
    roles: ['client', 'company'],
    permission: 'invoices',
  },
  {
    id: 18,
    icon: <DocumentGuideIcon />,
    label: 'Documents & Guides',
    to: ROUTE_PATH.DOCUMENT_SUITE,
    roles: ['client', 'company'],
    permission: 'documents_and_guides',
  },
  // {
  //   id: 11,
  //   icon: "icon-cloud-upload",
  //   label: "Imports",
  //   to: ROUTE_PATH.IMPORT,
  // },
  {
    id: 19,
    icon: <ChatIcon />,
    label: 'Messages',
    to: ROUTE_PATH.MESSAGES,
    roles: ['client', 'company'],
    permission: 'messages',
  },

  {
    id: 20,
    icon: <ReportIcon />,
    label: 'Reports',
    to: ROUTE_PATH.REPORTS,
    roles: ['client', 'company'],
    permission: 'reports',
  },

  {
    id: 22,
    icon: <SettingIcon />,
    label: 'Permissions',
    to: ROUTE_PATH.PERMISSIONS,
    roles: ['client', 'company'],
    permission: 'permissions',
  },

  {
    id: 21,
    icon: <UsersIcon />,
    label: 'Users',
    to: ROUTE_PATH.USER_MANAGEMENT,
    // roles: ["client"],
    onlyParent: true,
  },

  // Admin
  {
    id: 31,
    icon: <UsersIcon />,
    label: 'Users',
    to: ROUTE_PATH.ADMIN_USER_MANAGEMENT,
    roles: ['admin'],
  },
  {
    id: 32,
    icon: <SettingIcon />,
    label: 'Settings',
    to: ROUTE_PATH.SETTINGS,
    roles: ['admin'],
    children: [
      {
        id: 321,
        label: 'Payrolls',
        to: ROUTE_PATH.PAYROLLS,
        roles: ['admin'],
      },
      {
        id: 322,
        label: 'SMTP',
        to: ROUTE_PATH.SMTPS,
        roles: ['admin'],
      },
    ],
  },

  // Employee Portal
  {
    id: 31,
    icon: <UsersIcon />,
    label: 'Employee Profile',
    to: ROUTE_PATH.EMPLOYEE_PORTAL,
    roles: ['employee'],
  },
];

export default {
  menus,
};
