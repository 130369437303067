import api from "@/common/api";
import { SLICE_NAME } from "@/common/constants/stores";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ISmtp, IPagination, IResponsePagination } from "@/types/app";
import { navigateTo } from "@/utils/auth";
import { openNotification } from "@/utils";
import { ROUTE_PATH } from "@/common/constants/routes";

export interface ISmtps {
  data?: ISmtp[];
  loading: { [key: string]: boolean };
  pagination: IPagination;
  detail?: ISmtp;
  modalSmtpVisibility: boolean;
}

export const initialState: ISmtps = {
  pagination: {
    total: 0,
    page: 0,
    limit: 10,
    totalPage: 0,
  },
  data: [],
  loading: {},
  detail: undefined,
  modalSmtpVisibility: false,
};

const getSmtps = createAsyncThunk(
  `${SLICE_NAME.SMTPS}/getSmtps`,
  async (query: any | undefined, { rejectWithValue }) => {
    try {
      const data = (await api.getSmtps)<IResponsePagination<ISmtp>>(query);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const getSmtp = createAsyncThunk(
  `${SLICE_NAME.SMTPS}/getSmtp`,
  async (query: any | undefined, { rejectWithValue }) => {
    try {
      const data = await api.getSmtp<{ data: ISmtp }>(query);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const createSmtp = createAsyncThunk(
  `${SLICE_NAME.SMTPS}/createSmtp`,
  async (form: ISmtp, { rejectWithValue }) => {
    try {
      const data = await api.createSmtp<ISmtp>(form);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const updateSmtp = createAsyncThunk(
  `${SLICE_NAME.SMTPS}/updateSmtp`,
  async (form: ISmtp, { rejectWithValue }) => {
    try {
      const data = await api.updateSmtp<ISmtp>(form);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const deleteSmtp = createAsyncThunk(
  `${SLICE_NAME.SMTPS}/deleteSmtp`,
  async (query: any | undefined, { rejectWithValue }) => {
    try {
      const data = await api.deleteSmtp<{ data: ISmtp }>(query);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const mapSmtpFromResponse = (response: ISmtp) => {
  return {
    ...response,
    createdAt: response.createdAt ? response.createdAt * 1000 : 0,
  };
};

const smtpSlice = createSlice({
  name: SLICE_NAME.SMTPS,
  initialState: initialState,
  reducers: {
    onSetSmtpCurrent(state, { payload }) {
      state.detail = payload;
    },
    onOpenModalSmtp(state, { payload }) {
      state.modalSmtpVisibility = true;
      state.detail = payload;
    },
    onCloseModalSmtp(state) {
      state.modalSmtpVisibility = false;
      state.detail = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSmtps.fulfilled, (state, { payload }) => {
      state.loading[getSmtps.typePrefix] = false;
      state.data = payload.data?.data.map((item) => mapSmtpFromResponse(item));
      state.pagination = payload.data?.pagination;
    });
    builder.addCase(getSmtps.pending, (state, { payload }) => {
      state.loading[getSmtps.typePrefix] = true;
    });
    builder.addCase(getSmtps.rejected, (state, { payload }) => {
      state.loading[getSmtps.typePrefix] = false;
    });
    builder.addCase(getSmtp.fulfilled, (state, { payload }) => {
      state.loading[getSmtp.typePrefix] = false;
      state.detail = mapSmtpFromResponse(payload.data.data);
    });
    builder.addCase(getSmtp.pending, (state, { payload }) => {
      state.loading[getSmtp.typePrefix] = true;
    });
    builder.addCase(getSmtp.rejected, (state, { payload }) => {
      state.loading[getSmtp.typePrefix] = false;
    });
    builder.addCase(createSmtp.fulfilled, (state, { payload }) => {
      state.loading[createSmtp.typePrefix] = false;
      openNotification("success", "Create new Smtp successfully!");
    });
    builder.addCase(createSmtp.pending, (state, { payload }) => {
      state.loading[createSmtp.typePrefix] = true;
    });
    builder.addCase(createSmtp.rejected, (state, { payload }) => {
      openNotification("error", "Create new Smtp failed!");
      state.loading[createSmtp.typePrefix] = false;
    });
    builder.addCase(updateSmtp.fulfilled, (state, { payload }) => {
      state.loading[updateSmtp.typePrefix] = false;
      openNotification("success", "Update successfully!");
    });
    builder.addCase(updateSmtp.pending, (state, { payload }) => {
      state.loading[updateSmtp.typePrefix] = true;
    });
    builder.addCase(updateSmtp.rejected, (state, { payload }) => {
      state.loading[updateSmtp.typePrefix] = false;
    });
    builder.addCase(deleteSmtp.fulfilled, (state, { payload }) => {
      state.loading[deleteSmtp.typePrefix] = false;
      openNotification("success", `Delete successfully!`);
    });
    builder.addCase(deleteSmtp.pending, (state, { payload }) => {
      state.loading[deleteSmtp.typePrefix] = true;
    });
    builder.addCase(deleteSmtp.rejected, (state, { payload }) => {
      openNotification("error", "Delete failed!");
      state.loading[deleteSmtp.typePrefix] = false;
    });
  },
});

export const smtpsReducer = smtpSlice.reducer;
export const smtpsCaseReducers = smtpSlice.caseReducers;
export const smtpsActions = {
  ...smtpSlice.actions,
  getSmtps,
  getSmtp,
  createSmtp,
  updateSmtp,
  deleteSmtp,
};
