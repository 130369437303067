export const dateFormat = 'DD/MM/YYYY';
export const dateFormat2 = 'DD MMM YYYY';
export const dateTimeFormat = 'DD/MM/YYYY HH:mm:ss';
export const dateTimeFormatNotSecond = 'DD/MM/YYYY HH:mm';

export const pageSizeOptions = [5, 10, 20, 30, 40, 50, 100, 500];
export const currencyFormat = (value: number) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'EUR',
  }).format(value);
};
export const taxFormat = (value: number | string) => {
  return value + ' %';
};
