import api from "@/common/api";
import { FILTER_DEFAULT, SLICE_NAME } from "@/common/constants/stores";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  IDocument,
  IDocumentQuery,
  IPagination,
  IResponsePagination,
} from "@/types/app";
import { navigateTo } from "@/utils/auth";
import { openNotification } from "@/utils";
import { ROUTE_PATH } from "@/common/constants/routes";
import { uploadS3 } from "@/common/api/s3";

export interface IDocumentSuitsState {
  data: IDocument[];
  loading: { [key: string]: boolean };
  pagination: IPagination;
  detail: IDocument | null;
  filter: IDocumentQuery;
}

export const initialState: IDocumentSuitsState = {
  pagination: {
    total: 0,
    page: 0,
    limit: 10,
    totalPage: 0,
  },
  data: [],
  loading: {},
  detail: null,
  filter: { ...FILTER_DEFAULT },
};

const getDocuments = createAsyncThunk(
  `${SLICE_NAME.DOCUMENT_SUITES}/getDocuments`,
  async (query: IDocumentQuery, { rejectWithValue }) => {
    try {
      const data = (await api.getDocuments)<IResponsePagination<IDocument>>(
        query
      );
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const getDocument = createAsyncThunk(
  `${SLICE_NAME.DOCUMENT_SUITES}/getDocument`,
  async (query: any | undefined, { rejectWithValue }) => {
    try {
      const data = await api.getDocument<{ data: IDocument }>(query);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const createDocument = createAsyncThunk(
  `${SLICE_NAME.DOCUMENT_SUITES}/createDocument`,
  async (form: IDocument, { rejectWithValue }) => {
    try {
      if (form?.attachmentUpload && form?.attachmentUpload instanceof File) {
        const { success, data: attachment } = await uploadS3(
          form?.attachmentUpload,
          "document_suites"
        );
        if (success) {
          form = {
            ...form,
            attachment,
          };
        }
      }
      const data = await api.createDocument<{ data: IDocument }>(form);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const updateDocument = createAsyncThunk(
  `${SLICE_NAME.DOCUMENT_SUITES}/updateDocument`,
  async (form: IDocument, { rejectWithValue }) => {
    try {
      const data = await api.updateDocument<{ data: IDocument }>(form);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const deleteDocument = createAsyncThunk(
  `${SLICE_NAME.DOCUMENT_SUITES}/deleteDocument`,
  async (query: any, { rejectWithValue }) => {
    try {
      const data = await api.deleteDocument<{ data: IDocument }>(query);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const documentSuitesSlice = createSlice({
  name: SLICE_NAME.DOCUMENT_SUITES,
  initialState: initialState,
  reducers: {
    setFilter(state, { payload }) {
      let newFilter = { ...payload };
      Object.keys(payload)?.filter((key) => {
        if (!payload?.[key]) {
          delete newFilter?.[key];
        }
      });
      state.filter = newFilter;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDocuments.fulfilled, (state, { payload }) => {
      state.loading[getDocuments.typePrefix] = false;
      state.data = payload.data.data;
      state.pagination = payload.data.pagination;
    });
    builder.addCase(getDocuments.pending, (state, { payload }) => {
      state.loading[getDocuments.typePrefix] = true;
    });
    builder.addCase(getDocuments.rejected, (state, { payload }) => {
      state.loading[getDocuments.typePrefix] = false;
    });
    builder.addCase(getDocument.fulfilled, (state, { payload }) => {
      state.loading[getDocument.typePrefix] = false;
      state.detail = payload.data.data;
    });
    builder.addCase(getDocument.pending, (state, { payload }) => {
      state.loading[getDocument.typePrefix] = true;
    });
    builder.addCase(getDocument.rejected, (state, { payload }) => {
      state.loading[getDocument.typePrefix] = false;
    });
    builder.addCase(updateDocument.fulfilled, (state, { payload }) => {
      // navigateTo(ROUTE_PATH.DOCUMENT_SUITE);
      openNotification("success", "Update successfully!");
    });
    builder.addCase(updateDocument.pending, (state, { payload }) => {
      state.loading[updateDocument.typePrefix] = true;
    });
    builder.addCase(updateDocument.rejected, (state, { payload }) => {
      state.loading[updateDocument.typePrefix] = false;
    });

    builder.addCase(createDocument.fulfilled, (state, { payload }) => {
      state.loading[createDocument.typePrefix] = false;
      openNotification("success", "Upload successfully!");
    });
    builder.addCase(createDocument.pending, (state, { payload }) => {
      state.loading[createDocument.typePrefix] = true;
    });
    builder.addCase(createDocument.rejected, (state, { payload }) => {
      openNotification("error", "Create failed!");
      state.loading[createDocument.typePrefix] = false;
    });

    builder.addCase(deleteDocument.fulfilled, (state, { payload }) => {
      openNotification(
        "success",
        `Document & Suites ${payload?.data?.data?.id} delete successfully!`
      );
      state.loading[deleteDocument.typePrefix] = false;
    });
    builder.addCase(deleteDocument.pending, (state, { payload }) => {
      state.loading[deleteDocument.typePrefix] = true;
    });
    builder.addCase(deleteDocument.rejected, (state, { payload }) => {
      openNotification("error", "Delete failed!");
      state.loading[deleteDocument.typePrefix] = false;
    });
  },
});

export const documentSuitesReducer = documentSuitesSlice.reducer;
export const documentSuitesCaseReducers = documentSuitesSlice.caseReducers;
export const documentSuitesActions = {
  ...documentSuitesSlice.actions,
  getDocuments,
  getDocument,
  updateDocument,
  deleteDocument,
  createDocument,
};
