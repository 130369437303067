import { Link } from 'react-router-dom';
import { ILoginForm } from '@/types/app';
import { Button, Checkbox, Form, Input } from 'antd';
import { ROUTE_PATH } from '@/common/constants/routes';
import { useDispatch } from '@/stores';
import { authActions } from '@/stores/auth';
import { ReactComponent as IconStart } from '@/assets/images/new-icons/icon-start-sign-in.svg';
const Login = () => {
  const dispatch = useDispatch();

  const transformBeforeSubmit = (e: ILoginForm) => {
    return {
      ...e,
      emailOrUsername: e?.emailOrUsername?.toLowerCase?.()?.trim?.()
    }
  }

  const handleSubmitForm = (e: ILoginForm) => {
    const result = transformBeforeSubmit(e);
    dispatch(authActions.login(result));
  };

  return (
    <Form
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      layout="vertical"
      onFinish={(e) => handleSubmitForm(e)}
      className="auth bg-white"
      style={{
        display: 'flex',
      }}
    >
      <div style={{ height: '100%', width: '50%', backgroundColor: '#F7F5F3', display: 'flex', flexDirection: 'column' }}>
        <div className="login-img"></div>
        <div className="branding" style={{ padding: 50 }}>
          <div style={{ fontSize: '20px', fontWeight: 600, color: '#1B1B1B', display: 'flex', alignItems: 'center' }}>
            <IconStart className="mr-1" />
            <p className="m-0">AZURE GLOBAL</p>
          </div>
          <div style={{ fontSize: '32px', lineHeight: '48px', fontWeight: 700, color: '#1B1B1B', marginTop: 24 }}>
            Let’s empower your employees today.
          </div>
        </div>
      </div>
      <div
        style={{ height: '100%', width: '50%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}
      >
        <div className="text-center" style={{ fontSize: '24px', lineHeight: '32px', fontWeight: 700, marginBottom: '32px' }}>
          Login first to your account
        </div>
        <Form.Item
          style={{ fontSize: '14px', lineHeight: '20px', fontWeight: 500, color: '#666564', width: '50%' }}
          label="Username"
          name="emailOrUsername"
          rules={[{ required: true, message: 'This field is required!' }]}
        >
          <Input placeholder="Enter username" className="form-control w-100" />
        </Form.Item>
        <Form.Item
          style={{ fontSize: '14px', lineHeight: '20px', fontWeight: 500, color: '#666564', width: '50%' }}
          label="Password"
          name="password"
          rules={[{ required: true, message: 'This field is required!' }]}
        >
          <Input placeholder="Enter password" type="password" className="form-control w-100" />
        </Form.Item>
        <Link
          style={{
            fontSize: '14px',
            lineHeight: '20px',
            fontWeight: 600,
            width: '50%',
            textDecoration: 'underline',
          }}
          className="small float-right"
          to={ROUTE_PATH.FORGOT_PASSWORD}
        >
          Forgot Password
        </Link>
        <div className="form-footer" style={{ width: '50%' }}>
          <Button type="primary" htmlType="submit" className="btn-block" style={{}}>
            Login
          </Button>
        </div>
      </div>
    </Form>
  );
};

export default Login;
