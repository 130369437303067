import api from "@/common/api";
import { SLICE_NAME } from "@/common/constants/stores";
import {
  IDashboardClient,
  IDashboardCountry,
  IDashboardOffBoardingEmployee,
  IDashboardOnboardingEmployee,
  IDashboardStaticsEmployee,
  IDashboardStaticsOffBoardingEmployee,
  IDashboardStatistic,
  IDashboardSummaryEmployee,
  IDashboardTopEmployee,
  IPagination,
} from "@/types/app";

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export interface IDashboardState {
  loading: { [x: string]: boolean };
  summaryEmployees?: IDashboardSummaryEmployee[];
  topEmployees?: IDashboardTopEmployee[];
  staticsEmployees?: IDashboardStaticsEmployee[];
  staticsOffBoardingEmployees?: IDashboardStaticsOffBoardingEmployee[];
  onboardingEmployees?: IDashboardOnboardingEmployee;
  offBoardingEmployees?: IDashboardOffBoardingEmployee;
  dashboardAllCountries: {data: IDashboardCountry[], pagination: IPagination}
  dashboardAllClients: {data: IDashboardClient[], pagination: IPagination}
  dashboardAllEndClients: {data: IDashboardClient[], pagination: IPagination}
  dashboardStatistics?: IDashboardStatistic; 
}

export const initialState: IDashboardState = {
  loading: {},
  summaryEmployees: undefined,
  topEmployees: undefined,
  staticsEmployees: undefined,
  staticsOffBoardingEmployees: undefined,
  dashboardAllCountries: { data: [], pagination: { total: 0, page: 0, limit: 10, totalPage: 0 } },
  dashboardAllClients: { data: [], pagination: { total: 0, page: 0, limit: 10, totalPage: 0 } },
  dashboardAllEndClients: { data: [], pagination: { total: 0, page: 0, limit: 10, totalPage: 0 } },
};

const getDashboardSummaryEmployees = createAsyncThunk(
  `${SLICE_NAME.DASHBOARD}/getDashboardSummaryEmployees`,
  async () => {
    const response = await api.getDashboardSummaryEmployees<{
      data: IDashboardSummaryEmployee[];
    }>();
    return response;
  }
);

const getDashboardTopEmployees = createAsyncThunk(
  `${SLICE_NAME.DASHBOARD}/getDashboardTopEmployees`,
  async () => {
    const response = await api.getDashboardTopEmployees<{
      data: IDashboardTopEmployee[];
    }>();
    return response;
  }
);

const getDashboardStaticsOnboardingEmployees = createAsyncThunk(
  `${SLICE_NAME.DASHBOARD}/getDashboardStaticsOnboardingEmployees`,
  async () => {
    const response = await api.getDashboardStaticsOnboardingEmployees<{
      data: IDashboardStaticsEmployee[];
    }>();
    return response;
  }
);

const getDashboardStaticsOffBoardingEmployees = createAsyncThunk(
  `${SLICE_NAME.DASHBOARD}/getDashboardStaticsOffBoardingEmployees`,
  async () => {
    const response = await api.getDashboardStaticsOffBoardingEmployees<{
      data: IDashboardStaticsOffBoardingEmployee[];
    }>();
    return response;
  }
);

const getDashboardOnboardingEmployees = createAsyncThunk(
  `${SLICE_NAME.DASHBOARD}/getDashboardOnboardingEmployees`,
  async () => {
    const response = await api.getDashboardOnboardingEmployees<{
      data: IDashboardOnboardingEmployee;
    }>();
    return response;
  }
);

const getDashboardOffBoardingEmployees = createAsyncThunk(
  `${SLICE_NAME.DASHBOARD}/getDashboardOffBoardingEmployees`,
  async () => {
    const response = await api.getDashboardOffBoardingEmployees<{
      data: IDashboardOffBoardingEmployee;
    }>();
    return response;
  }
);

const getDashboardAllCountries = createAsyncThunk(
  `${SLICE_NAME.DASHBOARD}/getDashboardAllCountries`,
  async (query?: any) => {
    const response = await api.getDashboardAllCountries<{
      data: IDashboardCountry[];
      pagination: IPagination
    }>(query);
    return response;
  }
);

const getDashboardAllClients = createAsyncThunk(`${SLICE_NAME.DASHBOARD}/getDashboardAllClients`, async (query?: any) => {
  const response = await api.getDashboardAllClients<{
    data: IDashboardClient[];
    pagination: IPagination;
  }>(query);
  return response;
});

const getDashboardAllEndClients = createAsyncThunk(`${SLICE_NAME.DASHBOARD}/getDashboardAllEndClients`, async (query?: any) => {
  const response = await api.getDashboardAllEndClients<{
    data: IDashboardClient[];
    pagination: IPagination;
  }>(query);
  return response;
});

const getDashboardStatistic = createAsyncThunk(
  `${SLICE_NAME.DASHBOARD}/getDashboardStatistic
`,
  async (query?: any) => {
    const response = await api.getDashboardStatistic<{
      data: IDashboardStatistic;
    }>(query);
    return response;
  },
);



const dashboardSlice = createSlice({
  name: SLICE_NAME.DASHBOARD,
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getDashboardSummaryEmployees.pending,
      (state, { payload }) => {
        state.loading[getDashboardSummaryEmployees.typePrefix] = true;
      }
    );
    builder.addCase(
      getDashboardSummaryEmployees.rejected,
      (state, { payload }) => {
        state.loading[getDashboardSummaryEmployees.typePrefix] = false;
      }
    );
    builder.addCase(
      getDashboardSummaryEmployees.fulfilled,
      (state, { payload }) => {
        state.loading[getDashboardSummaryEmployees.typePrefix] = false;
        state.summaryEmployees = payload?.data?.data;
      }
    );

    builder.addCase(getDashboardTopEmployees.pending, (state, { payload }) => {
      state.loading[getDashboardTopEmployees.typePrefix] = true;
    });
    builder.addCase(getDashboardTopEmployees.rejected, (state, { payload }) => {
      state.loading[getDashboardTopEmployees.typePrefix] = false;
    });
    builder.addCase(
      getDashboardTopEmployees.fulfilled,
      (state, { payload }) => {
        state.loading[getDashboardTopEmployees.typePrefix] = false;
        state.topEmployees = payload?.data?.data;
      }
    );

    builder.addCase(
      getDashboardStaticsOnboardingEmployees.pending,
      (state, { payload }) => {
        state.loading[getDashboardStaticsOnboardingEmployees.typePrefix] = true;
      }
    );
    builder.addCase(
      getDashboardStaticsOnboardingEmployees.rejected,
      (state, { payload }) => {
        state.loading[getDashboardStaticsOnboardingEmployees.typePrefix] =
          false;
      }
    );
    builder.addCase(
      getDashboardStaticsOnboardingEmployees.fulfilled,
      (state, { payload }) => {
        state.loading[getDashboardStaticsOnboardingEmployees.typePrefix] =
          false;
        state.staticsEmployees = payload?.data?.data;
      }
    );

    builder.addCase(
      getDashboardStaticsOffBoardingEmployees.pending,
      (state, { payload }) => {
        state.loading[getDashboardStaticsOffBoardingEmployees.typePrefix] =
          true;
      }
    );
    builder.addCase(
      getDashboardStaticsOffBoardingEmployees.rejected,
      (state, { payload }) => {
        state.loading[getDashboardStaticsOffBoardingEmployees.typePrefix] =
          false;
      }
    );
    builder.addCase(
      getDashboardStaticsOffBoardingEmployees.fulfilled,
      (state, { payload }) => {
        state.loading[getDashboardStaticsOffBoardingEmployees.typePrefix] =
          false;
        state.staticsOffBoardingEmployees = payload?.data?.data;
      }
    );
    builder.addCase(
      getDashboardOnboardingEmployees.pending,
      (state, { payload }) => {
        state.loading[getDashboardOnboardingEmployees.typePrefix] =
          true;
      }
    );
    builder.addCase(
      getDashboardOnboardingEmployees.rejected,
      (state, { payload }) => {
        state.loading[getDashboardOnboardingEmployees.typePrefix] =
          false;
      }
    );
    builder.addCase(getDashboardOnboardingEmployees.fulfilled, (state, { payload }) => {
      state.loading[getDashboardOnboardingEmployees.typePrefix] = false;
      state.onboardingEmployees = payload?.data?.data;
    });
    builder.addCase(getDashboardOffBoardingEmployees.pending, (state, { payload }) => {
      state.loading[getDashboardOffBoardingEmployees.typePrefix] = true;
    });
    builder.addCase(getDashboardOffBoardingEmployees.rejected, (state, { payload }) => {
      state.loading[getDashboardOffBoardingEmployees.typePrefix] = false;
    });
    builder.addCase(getDashboardOffBoardingEmployees.fulfilled, (state, { payload }) => {
      state.loading[getDashboardOffBoardingEmployees.typePrefix] = false;
      state.offBoardingEmployees = payload?.data?.data;
    });
    builder.addCase(getDashboardAllCountries.pending, (state, { payload }) => {
      state.loading[getDashboardAllCountries.typePrefix] = true;
    });
    builder.addCase(getDashboardAllCountries.rejected, (state, { payload }) => {
      state.loading[getDashboardAllCountries.typePrefix] = false;
    });
    builder.addCase(getDashboardAllCountries.fulfilled, (state, { payload }) => {
      state.loading[getDashboardAllCountries.typePrefix] = false;
      state.dashboardAllCountries.data = payload?.data?.data;
      state.dashboardAllCountries.pagination = payload?.data?.pagination;

    });
    builder.addCase(getDashboardAllClients.pending, (state, { payload }) => {
      state.loading[getDashboardAllClients.typePrefix] = true;
    });
    builder.addCase(getDashboardAllClients.rejected, (state, { payload }) => {
      state.loading[getDashboardAllClients.typePrefix] = false;
    });
    builder.addCase(getDashboardAllClients.fulfilled, (state, { payload }) => {
      state.loading[getDashboardAllClients.typePrefix] = false;
      state.dashboardAllClients.data = payload?.data?.data;
      state.dashboardAllClients.pagination = payload?.data?.pagination;
    });
    builder.addCase(getDashboardAllEndClients.pending, (state, { payload }) => {
      state.loading[getDashboardAllEndClients.typePrefix] = true;
    });
    builder.addCase(getDashboardAllEndClients.rejected, (state, { payload }) => {
      state.loading[getDashboardAllEndClients.typePrefix] = false;
    });
    builder.addCase(getDashboardAllEndClients.fulfilled, (state, { payload }) => {
      state.loading[getDashboardAllEndClients.typePrefix] = false;
      state.dashboardAllEndClients.data = payload?.data?.data;
      state.dashboardAllEndClients.pagination = payload?.data?.pagination;
    });
    builder.addCase(getDashboardStatistic.pending, (state, { payload }) => {
      state.loading[getDashboardStatistic.typePrefix] = true;
    });
    builder.addCase(getDashboardStatistic.rejected, (state, { payload }) => {
      state.loading[getDashboardStatistic.typePrefix] = false;
    });
    builder.addCase(getDashboardStatistic.fulfilled, (state, { payload }) => {
      state.loading[getDashboardStatistic.typePrefix] = false;
      state.dashboardStatistics= payload?.data?.data;
      // state.dashboardAllClients.data = payload?.data?.data;
      // state.dashboardAllClients.pagination = payload?.data?.pagination;
    });
    
  },
});

export const dashboardReducer = dashboardSlice.reducer;
export const dashboardActions = {
  ...dashboardSlice.actions,
  getDashboardSummaryEmployees,
  getDashboardTopEmployees,
  getDashboardStaticsOnboardingEmployees,
  getDashboardStaticsOffBoardingEmployees,
  getDashboardOnboardingEmployees,
  getDashboardOffBoardingEmployees,
  getDashboardAllCountries,
  getDashboardAllClients,
  getDashboardAllEndClients,
  getDashboardStatistic,
};
