import * as React from 'react';
import { SVGProps } from 'react';
const IconArrowRight = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={13} height={8} fill="none" {...props}>
    <path
      fill="currentColor"
      d="M12.354 4.354a.5.5 0 0 0 0-.708L9.172.464a.5.5 0 1 0-.708.708L11.293 4 8.464 6.828a.5.5 0 1 0 .708.708l3.182-3.182ZM0 4.5h12v-1H0v1Z"
    />
  </svg>
);
export default IconArrowRight;
