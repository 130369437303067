import { stringify } from 'qs';
import { REFRESH_TOKEN_KEY, TOKEN_KEY, USER_INFO, ROOT_TOKEN_KEY, ROOT_REFRESH_TOKEN_KEY, ROOT_PROFILE } from '@/common/constants/auth';
import { IUser } from '@/types/app';
import { UserRole } from '@/common/constants/enums';

export const getToken = () => {
  return typeof window !== 'undefined' ? (localStorage.getItem(TOKEN_KEY) ?? undefined) : undefined;
};

export const setToken = (token?: string) => {
  return localStorage.setItem(TOKEN_KEY, token ?? '');
};

export const getRefreshToken = () => {
  return localStorage.getItem(REFRESH_TOKEN_KEY) ?? undefined;
};

export const setRefreshToken = (token?: string) => {
  return localStorage.setItem(REFRESH_TOKEN_KEY, token ?? '');
};

export const getUserInfo = () => {
  return JSON.parse(localStorage.getItem(USER_INFO) || JSON.stringify('')) ?? undefined;
};

export const setUserInfo = (info?: IUser) => {
  return localStorage.setItem(USER_INFO, JSON.stringify(info) ?? '');
};

export const isTokenValid = (token?: string) => {
  return typeof token === 'string' && token !== '';
};

export const isRefreshTokenValid = (token?: string) => {
  return typeof token === 'string' && token !== '';
};

export const navigateTo = (routePath?: string) => {
  if (routePath && window) {
    window.location.href = routePath;
  }
};

export const getRootToken = () => {
  return typeof window !== 'undefined' ? (localStorage.getItem(ROOT_TOKEN_KEY) ?? undefined) : undefined;
};

export const setRootToken = (token?: string) => {
  return localStorage.setItem(ROOT_TOKEN_KEY, token ?? '');
};

export const getRootRefreshToken = () => {
  return localStorage.getItem(ROOT_REFRESH_TOKEN_KEY) ?? undefined;
};

export const setRootRefreshToken = (token?: string) => {
  return localStorage.setItem(ROOT_REFRESH_TOKEN_KEY, token ?? '');
};

export const getRootProfile = () => {
  return JSON.parse(localStorage.getItem(ROOT_PROFILE) || JSON.stringify('')) ?? undefined;
};

export const setRootProfile = (info?: IUser) => {
  return localStorage.setItem(ROOT_PROFILE, JSON.stringify(info) ?? '');
};

export const isAdmin = () => {
  const profile = JSON.parse(localStorage.getItem(USER_INFO) || JSON.stringify('')) ?? undefined;

  return profile?.role === 'admin' ? true : false;
};

export const isRootAdmin = () => {
  const rootProfile = JSON.parse(localStorage.getItem(ROOT_PROFILE) || JSON.stringify('')) ?? undefined;

  return rootProfile?.role === 'admin' ? true : false;
};

export const isEmployee = () => {
  const profile = JSON.parse(localStorage.getItem(USER_INFO) || JSON.stringify('')) ?? undefined;

  return profile?.role === 'employee' ? true : false;
};

export const isLoginAs = () => {
  const rootTokenKey = localStorage.getItem(ROOT_TOKEN_KEY) ?? undefined;
  const role = JSON.parse(localStorage.getItem(USER_INFO) || JSON.stringify('')) ?? undefined;

  return rootTokenKey && role?.role !== 'admin' ? true : false;
};
