
import { dateFormat } from '@/common/constants/format';
import { useSelector } from '@/stores';
import { IEmployee } from '@/types/app';
import { matchValueToLabel } from '@/utils';
import { Card, Col, Row } from 'antd';
import dayjs from 'dayjs';
const PersonalInfoDetail = (props: { detail?: IEmployee}) => {
  const { detail } = props;
  const { constants } = useSelector((state) => state.app);

  return (
    <Card className="mb-3">
      <Row gutter={[16, 8]} className="general-information">
        <Col xs={24}>
          <p className="title">Personal Info</p>
        </Col>
        <Col xs={24}>
          <hr className="my-2" />
        </Col>
        <Col xs={8}>
          <p className="label">Title</p>
        </Col>
        <Col xs={16} className="value">
          {detail?.title ? matchValueToLabel(constants?.employeeTitles || [], detail?.title) : <p className="none">none</p>}
        </Col>
        <Col xs={8}>
          <p className="label">Forename</p>
        </Col>
        <Col xs={16} className="value">
          {detail?.forename || <p className="none">none</p>}
        </Col>
        <Col xs={8}>
          <p className="label">Surname</p>
        </Col>
        <Col xs={16} className="value">
          {detail?.surname || <p className="none">none</p>}
        </Col>
        <Col xs={8}>
          <p className="label">Username</p>
        </Col>
        <Col xs={16} className="value">
          {detail?.username || <p className="none">none</p>}
        </Col>
        <Col xs={8}>
          <p className="label">Email</p>
        </Col>
        <Col xs={16} className="value">
          {detail?.email || <p className="none">none</p>}
        </Col>
        <Col xs={8}>
          <p className="label">Phone Number</p>
        </Col>
        <Col xs={16} className="value">
          {detail?.phoneNumber || <p className="none">none</p>}
        </Col>
        <Col xs={8}>
          <p className="label">Date of Birth</p>
        </Col>
        <Col xs={16} className="value">
          {detail?.dateOfBirth ? dayjs(detail?.dateOfBirth).format(dateFormat) : <p className="none">none</p>}
        </Col>
        <Col xs={8}>
          <p className="label">Nationality</p>
        </Col>
        <Col xs={16} className="value">
          {detail?.employeeNationality?.name || <p className="none">none</p>}
        </Col>
        <Col xs={8}>
          <p className="label">Marital Status</p>
        </Col>
        <Col xs={16} className="value">
          {detail?.maritalStatus ? (
            matchValueToLabel(constants?.maritalStatuses || [], detail?.maritalStatus)
          ) : (
            <p className="none">none</p>
          )}
        </Col>
      </Row>
    </Card>
  );
};

export default PersonalInfoDetail;
