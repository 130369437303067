import { IDocument, IDocumentQuery } from "@/types/app";
import { Button, Form, Input, Space, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import { FileTwoTone, DownloadOutlined } from "@ant-design/icons";
import { downloadFileFromURL, matchValueToLabel } from "@/utils";
import { dateFormat, pageSizeOptions } from "@/common/constants/format";
import TableWithScrollButtons from "@/components/Shared/TableWithScrollButtons";
import UploadDocumentModal from "@/components/Modals/UploadDocumentModal";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { enumOptions } from "@/common/constants/enums";
import { useDispatch, useSelector } from "@/stores";
import { importsActions } from "@/stores/imports";

const Import = () => {
  const { constants, countries } = useSelector((state) => state?.app);
  const { data, pagination, filter, loading } = useSelector(
    (state) => state?.imports
  );
  const dispatch = useDispatch();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  useEffect(() => {
    dispatch(importsActions.getDocuments(filter));
  }, [filter]);

  const onChangeFilter = (value: IDocumentQuery) => {
    dispatch(
      importsActions.setFilter({
        ...filter,
        ...value,
      })
    );
  };

  const columns: ColumnsType<IDocument> = [
    {
      title: "Actions",
      key: "action",
      fixed: "left",
      width: 120,
      align: "center",
      render: (_, record) => (
        <Space size="middle">
          <Tooltip title="Open Document">
            <Button
              htmlType="button"
              className="btn btn-icon d-flex align-items-center"
              onClick={() => {
                window.open(record?.attachment?.fileURL);
              }}
            >
              <FileTwoTone />
            </Button>
          </Tooltip>
          <Tooltip title="Download">
            <Button
              htmlType="button"
              className="btn btn-icon d-flex align-items-center"
              onClick={() => {
                downloadFileFromURL(
                  record?.attachment?.fileURL,
                  record?.attachment?.metadata?.name
                );
              }}
            >
              <DownloadOutlined />
            </Button>
          </Tooltip>
        </Space>
      ),
    },
    {
      title: "Document Title",
      dataIndex: "fileName",
      key: "fileName",
      align: "center",
      render: (val, record) => record?.attachment?.metadata?.name,
    },
    {
      title: "Imported On",
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      render: (val) => dayjs.unix(val).format(dateFormat),
    },
    // {
    //   title: "Imported By",
    //   dataIndex: "createdBy",
    //   key: "createdBy",
    //   align: "center",
    // },
    {
      title: "Document Type",
      dataIndex: "type",
      key: "type",
      align: "center",
      render: (val) => matchValueToLabel(constants?.documentTypes || [], val),
    },

    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      align: "center",
      render: (val) => val,
    },
  ];

  const handleOpenModal = (e: boolean) => {
    setIsModalOpen(e);
  };

  const handleSearchSubmit = (e: string) => {
    onChangeFilter({
      keyword: e,
    });
  };

  const handleSubmitDocument = (values: IDocument) => {
    const newValue: IDocument = { ...values, type: "guide" };
    dispatch(importsActions.createDocument(newValue)).then(() => {
      dispatch(importsActions.getDocuments(filter));
      setIsModalOpen(false);
    });
  };

  return (
    <div className="container-fluid">
      <div className="d-flex justify-content-end p-2">
        <Button type="primary" onClick={() => handleOpenModal(true)}>
          Import File
        </Button>
      </div>
      <div className="bg-white shadow-lg rounded">
        <div className="card-header">
          <h3 className="card-title font-weight-bold">Import</h3>
          <div className="card-options">
            <Form onFinish={(e) => handleSearchSubmit(e)}>
              <div className="input-group">
                <Input.Search
                  placeholder="Input search text"
                  onSearch={(e) => handleSearchSubmit(e)}
                  allowClear
                  className="w-100"
                />
              </div>
            </Form>
          </div>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <TableWithScrollButtons
              columns={columns}
              dataSource={data}
              size="small"
              scroll={{ x: 800, y: 480 }}
              pagination={{
                showSizeChanger: true,
                defaultCurrent: 1,
                total: pagination.total,
                position: ["bottomLeft"],
                pageSizeOptions: pageSizeOptions,
                showTotal: (total: number, range: number[]) =>
                  'Showing ' + range[0] + ' to ' + range[1] + ' of ' + total + ' entries',
              }}
              onChange={(pagination) => {
                onChangeFilter({
                  page: pagination?.current,
                  limit: pagination?.pageSize,
                });
              }}
            />
          </div>
        </div>
      </div>
      {isModalOpen && (
        <UploadDocumentModal
          open={isModalOpen}
          countries={countries}
          destroyOnClose={true}
          onCancel={() => setIsModalOpen(false)}
          onSubmit={handleSubmitDocument}
          confirmLoading={loading[importsActions?.createDocument.typePrefix]}
        />
      )}
    </div>
  );
};

export default Import;
