import { useDispatch, useSelector } from '@/stores';
import { activityLogActions } from '@/stores/activityLogs';
import { Spin } from 'antd';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowLeftIcon } from '@/components/common/IconComponents';
import { ROUTE_PATH } from '@/common/constants/routes';
import groupBy from 'lodash/groupBy';
import dayjs from 'dayjs';
import { dateTimeFormat } from '@/common/constants/format';
import { IActivityLog, IEmployee } from '@/types/app';
import { matchValueToLabel } from '@/utils';
import { IconArrowRight } from '@/components/Icons';

function getFieldValue(field: any) {
  if (typeof field === 'string' || typeof field === 'number' || typeof field === 'boolean') {
    if (typeof field === 'number' && isValidUnixTimestamp(`${field}`)) {
      return dayjs.unix(field).format(dateTimeFormat);
    }
    return `${field}`;
  } else if (field instanceof Date) {
    return field.toISOString(); // Returns date as an ISO string
  } else if (typeof field === 'object' && field !== null) {
    if ('forename' in field) {
      return `${field.forename} ${field.surname}`;
    }

    return field.name || field.id || `${field}`;
  }

  return null; // Handles undefined, null, or any unexpected types
}

function isValidUnixTimestamp(input: string): boolean {
  const unixTimestampRegex = /^[0-9]{10,13}$/;

  if (!unixTimestampRegex.test(input)) {
    return false;
  }

  const timestamp = parseInt(input, 10);
  const date = new Date(input.length === 10 ? timestamp * 1000 : timestamp);

  // Check if the timestamp is within a reasonable range (e.g., between 1970 and 2100)
  return date.getTime() > 0 && date.getFullYear() >= 1970 && date.getFullYear() <= 2100;
}

const ActivityLogDetails = () => {
  const dispatch = useDispatch();
  const { detail, loading } = useSelector((state) => state.activityLog);
  const { constants } = useSelector((state) => state.app);

  const groupedLogs = groupBy(detail, 'createdAt');
  const timestamps = Object.keys(groupedLogs);

  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      dispatch(activityLogActions.getActivityLog({ employeeID: id }));
    }
  }, [id]);

  const navigateBack = () => {
    navigate(ROUTE_PATH.EMPLOYEE_ACTIVITY_LOGS || '-1');
  };

  const renderItemTitle = (item: IActivityLog) => {
    switch (item.type) {
      case 'created_document_guides':
      case 'created_document_others':
      case 'created_document_time_sheet':
      case 'created_document_invoice':
      case 'created_document_payslips':
      case 'created_employee':
      case 'deleted_employee':
        return (
          <>
            {item.updatedByUser?.name}{' '}
            <span className="activity-type">{matchValueToLabel(constants?.activityLogTypes || [], item.type)}</span>
          </>
        );

      case 'created_note_employee':
        return (
          <>
            {item.updatedByUser?.name} <span className="activity-type">added</span> new note{' '}
            <span className="activity-type">{item.entityType}</span>
          </>
        );
      case 'off_boarding_employee':
        return (
          <>
            {item.updatedByUser?.name} <span className="activity-type">updated status</span> to{' '}
            <span className="activity-type">Offboarding In Progress</span>
          </>
        );
      case 'on_boarding_employee':
        return (
          <>
            {item.updatedByUser?.name} <span className="activity-type">updated status</span> to{' '}
            <span className="activity-type">Onboarding In Progress</span>
          </>
        );
      case 'request_terminated_employee':
        return (
          <>
            {item.updatedByUser?.name} <span className="activity-type">request terminated</span>
          </>
        );
      case 'sent_employee_to_company':
        return (
          <>
            {item.updatedByUser?.name} <span className="activity-type">sent profile</span> for Onboarding review
          </>
        );
      case 'updated_employee':
        return (
          <>
            {item.updatedByUser?.name} <span className="activity-type">edited</span> general info
          </>
        );

      default:
        return null;
    }
  };

  const renderItemContent = (item: IActivityLog) => {
    switch (item.type) {
      case 'created_document_guides':
      case 'created_document_others':
      case 'created_document_time_sheet':
      case 'created_document_invoice':
      case 'created_document_payslips':
      case 'created_employee':
      case 'created_note_employee':
      case 'deleted_employee':
      case 'off_boarding_employee':
      case 'on_boarding_employee':
      case 'request_terminated_employee':
      case 'sent_employee_to_company':
        return null;
      case 'updated_employee':
        return (
          <div
            className="mt-3 rounded-lg border-[1px] border-neutral-100 bg-neutral-50 p-4"
            style={{ marginTop: 3, borderRadius: 8, border: '1px solid #E4E3E2', background: '#FBFAF8', padding: 12 }}
          >
            {renderDataChangeRows(item)}
          </div>
        );

      default:
        return null;
    }
  };

  const renderDataChangeRows = (log: IActivityLog) => {
    const infoChanges = log?.newData;

    return Object.keys(infoChanges)?.map((item, index) => {
      const oldValue = log.previousData[item as keyof IEmployee];
      const newValue = infoChanges[item as keyof IEmployee];

      if (oldValue === newValue) {
        return null;
      }

      return (
        <div className="d-flex flex-nowrap" key={index} style={{ gap: 28, marginTop: 8 }}>
          <div
            className={`min-w-[136px] text-neutral-500 overflow-hidden`}
            style={{ minWidth: 136, color: '#AFADAC', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
          >
            {item}
          </div>
          <div
            className="overflow-hidden"
            style={{ minWidth: 152, color: '#666564' }}
            title={getFieldValue(oldValue)} // Add tooltip for full text on hover
          >{`${getFieldValue(oldValue)}`}</div>
          <div
            className={`d-flex items-center text-neutral-900 overflow-hidden`}
            style={{ minWidth: 152, color: '#666564', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
          >
            <div className={`flex-shrink-0`} style={{ width: 40 }}>
              <IconArrowRight />
            </div>
            <div
              className="overflow-hidden"
              style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
              title={getFieldValue(newValue)}
            >
              {getFieldValue(newValue)}
            </div>
          </div>
        </div>
      );
    });
  };
  return (
    <div className="container-fluid pt-4">
      <Spin tip="Loading" size="large" spinning={loading[activityLogActions.getActivityLog.typePrefix] || false}>
        <div className="d-flex justify-content-between align-items-center py-3">
          <div className="d-flex justify-content-between align-items-center page-listing-title mb-0">
            <ArrowLeftIcon className="mr-2" onClick={navigateBack} />
            <span>Log Details</span>
          </div>
        </div>

        <div className="bg-white rounded">
          <div className="p-4">
            {timestamps?.reverse()?.map((timestamp, kIndex) => {
              const list = groupedLogs[timestamp] || [];

              return (
                <div key={kIndex}>
                  <div className="d-flex" style={{ marginBottom: 4 }}>
                    <div className="d-flex flex-column" style={{ width: 20, paddingTop: 2 }}>
                      <div style={{ height: 16, width: 16, borderRadius: '50%', border: '3px solid #E4E3E2' }}></div>
                      <div
                        className="ml-[6px] mt-2 w-[3px] flex-grow-1 rounded-md bg-neutral-300"
                        style={{
                          marginLeft: 6,
                          marginTop: 8,
                          width: 3,
                          flex: 1,
                          borderRadius: 8,
                          backgroundColor: '#E4E3E2',
                        }}
                      />
                    </div>
                    <div className="w-[160px]" style={{ width: 180 }}>
                      <div
                        className="text-neutral-700"
                        style={{
                          fontWeight: 500,
                          fontSize: 14,
                          color: '#8A8988',
                        }}
                      >
                        {dayjs.unix(list[0]?.createdAt).format(dateTimeFormat)}
                      </div>
                    </div>
                    <div className="flex-grow-1" style={{ marginLeft: 40 }}>
                      {list?.map((item, index) => {
                        return (
                          <div key={index} className="flex-grow-1" style={{ marginBottom: 20 }}>
                            <div className="activity-log-item">
                              <div
                                className="title"
                                style={{
                                  color: '#8a8988',
                                  fontSize: 14,
                                  lineHeight: 1.42,
                                }}
                              >
                                {renderItemTitle(item)}
                              </div>
                              <div>{renderItemContent(item)}</div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Spin>
    </div>
  );
};

export default ActivityLogDetails;
