import api from "@/common/api";
import { SLICE_NAME } from "@/common/constants/stores";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { IPayroll, IPagination, IResponsePagination } from "@/types/app";
import { navigateTo } from "@/utils/auth";
import { openNotification } from "@/utils";
import { ROUTE_PATH } from "@/common/constants/routes";

export interface IPayrolls {
  data?: IPayroll[];
  loading: { [key: string]: boolean };
  pagination: IPagination;
  detail?: IPayroll;
  modalPayrollVisibility: boolean;
}

export const initialState: IPayrolls = {
  pagination: {
    total: 0,
    page: 0,
    limit: 10,
    totalPage: 0,
  },
  data: [],
  loading: {},
  detail: undefined,
  modalPayrollVisibility: false,
};

const getPayrolls = createAsyncThunk(
  `${SLICE_NAME.PAYROLLS}/getPayrolls`,
  async (query: any | undefined, { rejectWithValue }) => {
    try {
      const data = (await api.getPayrolls)<IResponsePagination<IPayroll>>(
        query
      );
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const getPayroll = createAsyncThunk(
  `${SLICE_NAME.PAYROLLS}/getPayroll`,
  async (query: any | undefined, { rejectWithValue }) => {
    try {
      const data = await api.getPayroll<{ data: IPayroll }>(query);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const createPayroll = createAsyncThunk(
  `${SLICE_NAME.PAYROLLS}/createPayroll`,
  async (form: IPayroll, { rejectWithValue }) => {
    try {
      const data = await api.createPayroll<IPayroll>(form);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const updatePayroll = createAsyncThunk(
  `${SLICE_NAME.PAYROLLS}/updatePayroll`,
  async (form: IPayroll, { rejectWithValue }) => {
    try {
      const data = await api.updatePayroll<IPayroll>(form);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const deletePayroll = createAsyncThunk(
  `${SLICE_NAME.PAYROLLS}/deletePayroll`,
  async (query: any | undefined, { rejectWithValue }) => {
    try {
      const data = await api.deletePayroll<{ data: IPayroll }>(query);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const mapPayrollFromResponse = (response: IPayroll) => {
  return {
    ...response,
    createdAt: response.createdAt ? response.createdAt * 1000 : 0,
  };
};

const payrollSlice = createSlice({
  name: SLICE_NAME.PAYROLLS,
  initialState: initialState,
  reducers: {
    onSetPayrollCurrent(state, { payload }) {
      state.detail = payload;
    },
    onOpenModalPayroll(state, { payload }) {
      state.modalPayrollVisibility = true;
      state.detail = payload;
    },
    onCloseModalPayroll(state) {
      state.modalPayrollVisibility = false;
      state.detail = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPayrolls.fulfilled, (state, { payload }) => {
      state.loading[getPayrolls.typePrefix] = false;
      state.data = payload.data?.data.map((item) =>
        mapPayrollFromResponse(item)
      );
      state.pagination = payload.data?.pagination;
    });
    builder.addCase(getPayrolls.pending, (state, { payload }) => {
      state.loading[getPayrolls.typePrefix] = true;
    });
    builder.addCase(getPayrolls.rejected, (state, { payload }) => {
      state.loading[getPayrolls.typePrefix] = false;
    });
    builder.addCase(getPayroll.fulfilled, (state, { payload }) => {
      state.loading[getPayroll.typePrefix] = false;
      state.detail = mapPayrollFromResponse(payload.data.data);
    });
    builder.addCase(getPayroll.pending, (state, { payload }) => {
      state.loading[getPayroll.typePrefix] = true;
    });
    builder.addCase(getPayroll.rejected, (state, { payload }) => {
      state.loading[getPayroll.typePrefix] = false;
    });
    builder.addCase(createPayroll.fulfilled, (state, { payload }) => {
      state.loading[createPayroll.typePrefix] = false;
      openNotification("success", "Create new payroll successfully!");
    });
    builder.addCase(createPayroll.pending, (state, { payload }) => {
      state.loading[createPayroll.typePrefix] = true;
    });
    builder.addCase(createPayroll.rejected, (state, { payload }) => {
      openNotification("error", "Create new payroll failed!");
      state.loading[createPayroll.typePrefix] = false;
    });
    builder.addCase(updatePayroll.fulfilled, (state, { payload }) => {
      state.loading[updatePayroll.typePrefix] = false;
      openNotification("success", "Update successfully!");
    });
    builder.addCase(updatePayroll.pending, (state, { payload }) => {
      state.loading[updatePayroll.typePrefix] = true;
    });
    builder.addCase(updatePayroll.rejected, (state, { payload }) => {
      state.loading[updatePayroll.typePrefix] = false;
    });
    builder.addCase(deletePayroll.fulfilled, (state, { payload }) => {
      state.loading[deletePayroll.typePrefix] = false;
      openNotification(
        "success",
        `${payload?.data?.data?.name} delete successfully!`
      );
    });
    builder.addCase(deletePayroll.pending, (state, { payload }) => {
      state.loading[deletePayroll.typePrefix] = true;
    });
    builder.addCase(deletePayroll.rejected, (state, { payload }) => {
      openNotification("error", "Delete failed!");
      state.loading[deletePayroll.typePrefix] = false;
    });
  },
});

export const payrollsReducer = payrollSlice.reducer;
export const payrollsCaseReducers = payrollSlice.caseReducers;
export const payrollsActions = {
  ...payrollSlice.actions,
  getPayrolls,
  getPayroll,
  createPayroll,
  updatePayroll,
  deletePayroll,
};
