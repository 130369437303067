import styled from "@emotion/styled";
import { HTMLAttributes } from "react";

interface IProps extends HTMLAttributes<HTMLElement> {
  justifyContent?: string;
  alignItems?: string;
  flexDirection?: string;
  gap?: any;
  padding?: any;
  margin?: any;
  href?: string;
}

const Flex = styled.div((props: IProps) => {
  const { justifyContent, alignItems, flexDirection, gap, padding, margin } =
    props;

  const baseStyle: any = {
    display: "flex",
    justifyContent: justifyContent,
    alignItems: alignItems,
    gap: typeof gap === "number" ? `${gap}px` : gap,
  };

  if (flexDirection) {
    baseStyle.flexDirection = flexDirection;
  }

  if (padding) {
    baseStyle.padding = padding;
  }

  if (margin) {
    baseStyle.margin = margin;
  }

  return { ...baseStyle };
});

export default Flex;
