import EmployeePersonalInfoForm from "@/components/Forms/EmployeePersonalInfoForm";
import EmployeeRegistrationInfoForm from "@/components/Forms/EmployeeRegistrationInfoForm";
import EmployeeRoleInfoForm from "@/components/Forms/EmployeeRoleInfoForm";
import UploadDocumentModal from "@/components/Modals/UploadDocumentModal";
import EmployeeDocuments from "@/components/Tables/EmployeeDocuments";
import EmployeeNotes from "@/components/Tables/EmployeeNotes";
import { useDispatch, useSelector } from "@/stores";
import { employeeActions } from "@/stores/employee";
import { IDocument, IEmployee } from "@/types/app";
import { Button, Form, Spin, Tabs, TabsProps } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";

export const initialState: IEmployee = {
  title: "mr",
  forename: "",
  surname: "",
  email: "",
  dateOfBirth: 0,
  // employeeNationality: "GB",
  // countryOfHire: "GB",
  needVisa: true,
  jobTitle: "",
  jobDescription: "",
  currency: "EUR",
  payFrequency: "hourly",
  startDate: 0,
  contractType: "ongoing",
  requireHealthInsurance: true,
  address: "",
  phoneNumber: "",
  status: "in_review",
  registrationStatus: "in_review",
  siteLocation: "",
};

const EmployeeCreateTabs = (props: any): JSX.Element => {
  const {create, form , activeKey, setActiveKey, } = props;
  const {
    detail,
    timesheetDocuments,
    payslipDocuments,
    othersDocuments,
    guideDocuments,
  } = useSelector((state) => state.employee);

  const { id } = useParams();

  const { countries } = useSelector((state) => state.app);
  const { userInfo } = useSelector((state) => state.auth);

  const [employee, setEmployee] = useState<IEmployee>(initialState);
  // const [activeKey, setActiveKey] = useState<number>(1);
  // const [showSaveBtn, setShowSaveBtn] = useState<boolean>(true);

  useEffect(
    function () {
      dispatch(employeeActions.getEmployee({ id: id, role: userInfo?.role }));
      dispatch(
        employeeActions.getEmployeeTimesheets({
          id: id,
          role: userInfo?.role,
        })
      );
      dispatch(
        employeeActions.getEmployeePayslips({ id: id, role: userInfo?.role })
      );
      dispatch(
        employeeActions.getEmployeeOthers({ id: id, role: userInfo?.role })
      );
      dispatch(
        employeeActions.getEmployeeGuides({ id: id, role: userInfo?.role })
      );
    },
    [id]
  );

  useEffect(
    ()=>{
      if(!create){
        if (detail) setEmployee(detail);
      }
      else setEmployee(initialState);
    },
    [detail]
  );


  const items: TabsProps["items"] = [
    {
      key: "1",
      label: (
        <Link to={`?tab=1`} className="py-4">
          Personal Info
        </Link>
      ),
      children: <EmployeePersonalInfoForm />,
    },
    {
      key: "2",
      label: (
        <Link to={`?tab=2`} className="py-4">
          Registration Info
        </Link>
      ),
      children: <EmployeeRegistrationInfoForm form={form} employee={true} />,
    },
    {
      key: "3",
      label: (
        <Link to={`?tab=3`} className="py-4">
          Role Information
        </Link>
      ),
      children: <EmployeeRoleInfoForm />,
    },
    {
      key: "4",
      label: (
        <Link to={`?tab=4`} className="py-4">
          Guides
        </Link>
      ),
      children: (
        <EmployeeDocuments data={guideDocuments} documentType="guides" />
      ),
    },
    {
      key: "5",
      label: (
        <Link to={`?tab=5`} className="py-4">
          Timesheets
        </Link>
      ),
      children: (
        <EmployeeDocuments
          documentType="timesheets"
          data={timesheetDocuments}
          onImport={async () => {
            await dispatch(
              employeeActions.onOpenModalUploadDocument("timesheet")
            );
            await dispatch(
              employeeActions.getEmployeeTimesheets({
                id,
                role: userInfo?.role,
              })
            );
          }}
        />
      ),
    },
    {
      key: "6",
      label: (
        <Link to={`?tab=6`} className="py-4">
          Payslips
        </Link>
      ),
      children: (
        <EmployeeDocuments
          documentType="payslips"
          data={payslipDocuments}
          onImport={async () => {
            await dispatch(
              employeeActions.onOpenModalUploadDocument("payslip")
            );
            await dispatch(
              employeeActions.getEmployeePayslips({ id, role: userInfo?.role })
            );
          }}
        />
      ),
    },
    {
      key: "7",
      label: (
        <Link to={`?tab=7`} className="py-4">
          Others
        </Link>
      ),
      children: (
        <EmployeeDocuments
          documentType="others"
          data={othersDocuments}
          onImport={async () => {
            await dispatch(employeeActions.onOpenModalUploadDocument("others"));
            await dispatch(
              employeeActions.getEmployeeOthers({ id, role: userInfo?.role })
            );
          }}
        />
      ),
    },
    {
      key: "8",
      label: (
        <Link to={`?tab=8`} className="py-4">
          Notes
        </Link>
      ),
      children: <EmployeeNotes />,
    },
  ];

  const dispatch = useDispatch();

  const defaultActiveTab = items?.filter((item) => window?.location?.search.includes(item.key))?.[0]?.key || '1';

  const transformBeforeSubmit = (e: IEmployee) => {
    return {
      ...e,
      id,
      forename: e.forename?.trim?.(),
      surname: e.surname?.trim?.(),
      name: e.forename?.trim?.() + " " + e.surname?.trim?.(),
      role: userInfo?.role,
      startDate: e.startDate ? dayjs(e.startDate).unix() : 0,
      endDate: e.endDate ? dayjs(e.endDate).unix() : 0,
      dateOfBirth: e.dateOfBirth ? dayjs(e.dateOfBirth).unix() : 0,
    };
  };

  const handleSubmitForm = (e: IEmployee) => {
    const result = transformBeforeSubmit({ ...employee, ...e });
    dispatch(employeeActions.updateEmployee(result));
  };


  useEffect(() => {
    form.resetFields();
  }, [employee]);


  return (
    <Form
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      layout="vertical"
      initialValues={employee}
      onFinish={handleSubmitForm}
      form={form}
    >
      <Tabs
        defaultActiveKey={defaultActiveTab}
        items={items}
        onChange={(e) => setActiveKey(Number(e))}
        className="bg-white rounded p-3"
      />
    </Form>
  );
};

export default EmployeeCreateTabs;
