import { useDispatch, useSelector } from "@/stores";
import { messageRoomActions } from "@/stores/messageRooms";
import { IEmployee, IEmployeeNote, IMessageRoom, UserRole } from "@/types/app";
import { constantsToOptions } from "@/utils";
import { Form, Input, Modal, Select, message } from "antd";
import SearchUsers from "../SearchUsers";
import { useEffect, useState } from "react";
import Dragger from "antd/es/upload/Dragger";
import { RcFile, UploadFile } from "antd/es/upload";
import { InboxOutlined } from "@ant-design/icons";
import { onboardingActions } from "@/stores/onboarding";
import { useParams } from "react-router-dom";
import { offboardingActions } from "@/stores/offboarding";
import { employeeActions } from "@/stores/employee";
const MAX_FILE_SIZE = 50;
interface IProps {
  isOpen?: boolean;
  setIsOpen: (value: boolean) => void;
  hideTo?: boolean;
  role?: UserRole;
  detail?: IEmployee;
  type?: string;
}

const NewNoteModal = (props: IProps) => {
  const { constants } = useSelector((state) => state.app);
  const { loading: onboardingLoading } = useSelector(
    (state) => state.onboarding
  );
  const { loading: offboardingLoading } = useSelector(
    (state) => state.offboarding
  );

  const dispatch = useDispatch();
  const { isOpen, setIsOpen, hideTo, role, detail, type } = props;
  const [form] = Form.useForm();
  const { id } = useParams();

  const [fileList, setFileList] = useState<UploadFile[]>([]);

  // ref

  // handle drag events
  function beforeUpload(file: RcFile) {
    const isLt2M = file.size / 1024 / 1024 < MAX_FILE_SIZE;
    if (!isLt2M) {
      message.error(`File must smaller than ${MAX_FILE_SIZE}MB!`);
    }
    return false;
  }

  const handleChangeFileUpload = (info: {
    file: UploadFile;
    fileList: UploadFile[];
  }) => {
    console.log("***** test handleChangeFileUpload", info);
    if (
      info.fileList.length &&
      (info?.file?.size || 0) / 1024 / 1024 < MAX_FILE_SIZE
    )
      setFileList([info?.file]);
  };

  const handleRemoveFileUpload = (info: any) => {
    setFileList([]);
  };

  const handleCloseModal = () => {
    form.resetFields();
    setIsOpen(false);
  };

  const transformBeforeSubmit = (e: IEmployeeNote) => {
    let result = {
      // ...detail,
      ...e,
      employeeID: id,
    };

    if (fileList?.length > 0 && fileList?.[0] instanceof File) {
      result = { ...result, attachmentUpload: fileList?.[0] };
    }
    return { ...result };
  };

  const handleSubmitForm = async (e: IEmployeeNote) => {
    const result = transformBeforeSubmit(e);
    if (type === "onboarding") {
      await dispatch(
        onboardingActions.createOnboardingNote({ ...result, role })
      ).then(() => {
        dispatch(onboardingActions.getOnboardingNotes({ id, role }));
      });
    } else if (type === "offboarding") {
      await dispatch(
        offboardingActions.createOffboardingNote({ ...result, role })
      ).then(() => {
        dispatch(offboardingActions.getOffboardingNotes({ id, role }));
      });
    } else if (type === "employee") {
      await dispatch(
        employeeActions.createEmployeeNote({ ...result, role })
      ).then(() => {
        dispatch(employeeActions.getEmployeeNotes({ id, role }));
      });
    }
    form.resetFields();
    setFileList([]);
    handleCloseModal();
  };

  useEffect(() => {
    if (detail) {
      form.setFieldsValue(detail?.notes);
      if (detail?.notes?.[0]?.attachment) {
        setFileList([
          {
            // fileName: detail?.attachment?.metadata?.name || "",
            uid: detail?.notes?.[0]?.attachment?.fileKey || "",
            name: detail?.notes?.[0]?.attachment?.metadata?.name || "",
            // url: getFileURL(detail?.notes?.[0]?.attachment),
          },
        ]);
      }
    } else {
      setFileList([]);
    }
  }, [detail]);

  return (
    <Modal
      title="New Note"
      open={isOpen}
      onOk={form.submit}
      okText="Create"
      onCancel={() => handleCloseModal()}
      width={600}
      okButtonProps={{
        disabled:
          (onboardingLoading[
            onboardingActions.createOnboardingNote.typePrefix
          ] !== undefined &&
            onboardingLoading[
              onboardingActions.createOnboardingNote.typePrefix
            ]) ||
          (offboardingLoading[
            offboardingActions.createOffboardingNote.typePrefix
          ] &&
            offboardingLoading[
              offboardingActions.createOffboardingNote.typePrefix
            ]),
      }}
      centered
    >
      <Form
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        layout="vertical"
        // initialValues={initialState}
        onFinish={handleSubmitForm}
        form={form}
      >
        <div className="row">
          <div className="col-md-12">
            <Form.Item
              label="Content"
              name="content"
              // rules={[{ required: true, message: "This field is required!" }]}
            >
              <Input.TextArea
                rows={4}
                className="w-100"
                placeholder="Enter content"
                maxLength={5000}
              />
            </Form.Item>
          </div>
          <div className="col-md-12">
            <Form.Item
              label="Attachment"
              name="attachment"
              // rules={[{ required: true, message: "This field is required!" }]}
            >
              <Dragger
                fileList={fileList}
                beforeUpload={beforeUpload}
                onChange={handleChangeFileUpload}
                onRemove={handleRemoveFileUpload}
                multiple={false}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
                <p className="ant-upload-hint">
                  Support for a single or bulk upload. Strictly prohibited from
                  uploading company data or other banned files. Max:{" "}
                  {MAX_FILE_SIZE}MB
                </p>
              </Dragger>
            </Form.Item>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default NewNoteModal;
