import { IEmployee } from "@/types/app";

export interface IEmployeeColumnAndName {
  value: keyof IEmployee;
  name: string;
}

export const EMPLOYEE_COLUMNS_AND_NAMES: IEmployeeColumnAndName[] = [
  { value: "id", name: "ID" },
  { value: "createdAt", name: "Created At" },
  { value: "updatedAt", name: "Updated At" },
  // { value: "deleted_at", name: "DeletedAt" },
  { value: "referenceID", name: "Reference ID" },
  { value: "email", name: "Email" },
  { value: "title", name: "Title" },
  { value: "forename", name: "Forename" },
  { value: "surname", name: "Surname" },
  { value: "dateOfBirth", name: "Date Of Birth" },
  { value: "employeeNationality", name: "Employee Nationality" },
  { value: "countryOfHire", name: "Country Of Hire" },
  { value: "needVisa", name: "Need Visa" },
  { value: "jobTitle", name: "Job Title" },
  { value: "jobDescription", name: "Job Description" },
  { value: "currency", name: "Currency" },
  { value: "payRate", name: "Pay Rate" },
  { value: "payFrequency", name: "Pay Frequency" },
  { value: "startDate", name: "Start Date" },
  { value: "contractType", name: "Contract Type" },
  { value: "endDate", name: "End Date" },
  { value: "requireHealthInsurance", name: "Require Health Insurance" },
  { value: "address", name: "Address" },
  { value: "phoneNumber", name: "Phone Number" },
  { value: "status", name: "Status" },
  { value: "registrationStatus", name: "Registration Status" },
  { value: "notes", name: "Notes" },
  { value: "siteLocation", name: "Site Location" },
  { value: "offBoardingStatus", name: "Off Boarding Status" },
  { value: "reasonForTermination", name: "Reason For Termination" },
  { value: "anticipatedEndDate", name: "Anticipated End Date" },
  { value: "payrollID", name: "Payroll ID" },
  //   { value: "payroll", name: "Payroll" },
  { value: "clientID", name: "Client ID" },
  //   { value: "client", name: "Client" },
];
