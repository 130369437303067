import api from "@/common/api";
import { SLICE_NAME } from "@/common/constants/stores";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  IUser,
  IUserResponse,
  IPagination,
  IChangePasswordForm,
} from "@/types/app";
import { navigateTo } from "@/utils/auth";
import { openNotification } from "@/utils";
import { ROUTE_PATH } from "@/common/constants/routes";
import { uploadS3 } from "@/common/api/s3";

export interface IUserState {
  data?: IUser[];
  loading: { [key: string]: boolean };
  pagination: IPagination;
  detail?: IUser;
}

export const initialState: IUserState = {
  pagination: {
    total: 0,
    page: 0,
    limit: 10,
    totalPage: 0,
  },
  data: [],
  loading: {},
};

const getCompanyUsers = createAsyncThunk(
  `${SLICE_NAME.CLIENT_USERS}/getCompanyUsers`,
  async (query: any | undefined, { rejectWithValue }) => {
    try {
      const data = await api.getCompanyUsers<IUserResponse>(query);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const getCompanyUser = createAsyncThunk(
  `${SLICE_NAME.CLIENT_USERS}/getCompanyUser`,
  async (query: any | undefined, { rejectWithValue }) => {
    try {
      const data = await api.getCompanyUser<{ data: IUser }>(query);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const createCompanyUser = createAsyncThunk(
  `${SLICE_NAME.CLIENT_USERS}/createCompanyUser`,
  async (form: IUser, { rejectWithValue }) => {
    try {
      if (form?.attachmentUpload && form?.attachmentUpload instanceof File) {
        const { success, data: attachment } = await uploadS3(
          form?.attachmentUpload,
          "companyClients"
        );
        if (success) {
          form = {
            ...form,
            logo: attachment,
          };
        }
      }
      const data = await api.createCompanyUser<IUser>(form);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const updateCompanyUser = createAsyncThunk(
  `${SLICE_NAME.CLIENT_USERS}/updateCompanyUser`,
  async (form: IUser, { rejectWithValue }) => {
    try {
      if (form?.attachmentUpload && form?.attachmentUpload instanceof File) {
        const { success, data: attachment } = await uploadS3(
          form?.attachmentUpload,
          "companyClients"
        );
        if (success) {
          form = {
            ...form,
            logo: attachment,
          };
        }
      }
      const data = await api.updateCompanyUser<IUser>(form);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const deleteCompanyUser = createAsyncThunk(
  `${SLICE_NAME.CLIENT_USERS}/deleteCompanyUser`,
  async (query: any | undefined, { rejectWithValue }) => {
    try {
      const data = await api.deleteCompanyUser<{ data: IUser }>(query);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const mapUserFromResponse = (response: IUser) => {
  return {
    ...response,
    createdAt: response.createdAt ? response.createdAt * 1000 : 0,
  };
};

const companyUsersSlice = createSlice({
  name: SLICE_NAME.CLIENT_USERS,
  initialState: initialState,
  reducers: {
    onResetDetail(state) {
      state.detail = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCompanyUsers.fulfilled, (state, { payload }) => {
      state.loading[getCompanyUsers.typePrefix] = false;
      if (payload?.data && payload?.data?.data) {
        state.data = payload?.data?.data.map((item) =>
          mapUserFromResponse(item)
        );
        state.pagination = payload?.data?.pagination;
      }
    });
    builder.addCase(getCompanyUsers.pending, (state, { payload }) => {
      state.loading[getCompanyUsers.typePrefix] = true;
    });
    builder.addCase(getCompanyUsers.rejected, (state, { payload }) => {
      state.loading[getCompanyUsers.typePrefix] = false;
    });
    builder.addCase(getCompanyUser.fulfilled, (state, { payload }) => {
      state.loading[getCompanyUser.typePrefix] = false;
      state.detail = mapUserFromResponse(payload.data.data);
    });
    builder.addCase(getCompanyUser.pending, (state, { payload }) => {
      state.loading[getCompanyUser.typePrefix] = true;
    });
    builder.addCase(getCompanyUser.rejected, (state, { payload }) => {
      state.loading[getCompanyUser.typePrefix] = false;
    });
    builder.addCase(createCompanyUser.fulfilled, (state, { payload }) => {
      state.loading[createCompanyUser.typePrefix] = false;
      // navigateTo(ROUTE_PATH.CLIENT_USERS_MANAGEMENT);
      openNotification("success", "Create new user successfully!");
    });
    builder.addCase(createCompanyUser.pending, (state, { payload }) => {
      state.loading[createCompanyUser.typePrefix] = true;
    });
    builder.addCase(createCompanyUser.rejected, (state, { payload }) => {
      state.loading[createCompanyUser.typePrefix] = false;
    });
    builder.addCase(updateCompanyUser.fulfilled, (state, { payload }) => {
      state.loading[updateCompanyUser.typePrefix] = false;
      openNotification("success", "Update successfully!");
    });
    builder.addCase(updateCompanyUser.pending, (state, { payload }) => {
      state.loading[updateCompanyUser.typePrefix] = true;
    });
    builder.addCase(updateCompanyUser.rejected, (state, { payload }) => {
      state.loading[updateCompanyUser.typePrefix] = false;
    });
    builder.addCase(deleteCompanyUser.fulfilled, (state, { payload }) => {
      state.loading[deleteCompanyUser.typePrefix] = false;
      openNotification(
        "success",
        `${payload.data.data?.forename} ${payload.data.data?.surname} deleted successfully!`
      );
    });
    builder.addCase(deleteCompanyUser.pending, (state, { payload }) => {
      state.loading[deleteCompanyUser.typePrefix] = true;
    });
    builder.addCase(deleteCompanyUser.rejected, (state, { payload }) => {
      state.loading[deleteCompanyUser.typePrefix] = false;
    });
  },
});

export const companyUsersReducer = companyUsersSlice.reducer;
export const companyUsersCaseReducers = companyUsersSlice.caseReducers;
export const companyUsersActions = {
  ...companyUsersSlice.actions,
  getCompanyUsers,
  getCompanyUser,
  createCompanyUser,
  updateCompanyUser,
  deleteCompanyUser,
};
