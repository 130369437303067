import { dateFormat } from "@/common/constants/format";
import { IEmployeeTimesheets } from "@/types/app";
import { DatePicker, Form, Input, Modal, Select } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from "react-hook-form";

const ViewTimesheetsModal = (props: any) => {
  const { timesheetId, isOpen, setIsOpen } = props;
  const [form] = Form.useForm();

  const initialState = {
    id: "",
    startDate: "",
    endDate: "",
    siteId: "",
    rates: [
      {
        rateId: "",
        rateCode: "PRSAP",
        rateType: "",
      },
    ],
  };

  const methods = useForm<IEmployeeTimesheets>({
    defaultValues: initialState,
  });

  const { control, handleSubmit, setValue } = methods;

  const {
    fields: rateFields,
    append: addRate,
    remove: removeRate,
  } = useFieldArray({
    control,
    name: "rates",
    keyName: "field_id",
  });

  const rateCodeList = [
    {
      id: 1,
      label: "PRHP",
      value: "PRHP",
    },
    {
      id: 2,
      label: "PRSAP",
      value: "PRSAP",
    },
    {
      id: 3,
      label: "PRSSP",
      value: "PRSSP",
    },
  ];

  const [timesheet, setTimesheet] = useState<IEmployeeTimesheets>(initialState);

  const handleCloseModal = () => {
    form.resetFields();
    setIsOpen(false);
  };

  const transformBeforeSubmit = (e: IEmployeeTimesheets) => {
    return {
      ...e,
      id: timesheetId,
      startDate: e.startDate ? dayjs(e.startDate).unix() : 0,
      endDate: e.endDate ? dayjs(e.endDate).unix() : 0,
    };
  };

  const submit = handleSubmit(
    async (values) => {
      const result = transformBeforeSubmit(values);
      form.resetFields();
      handleCloseModal();
    },
    () => {
      return window?.scrollTo(0, 0);
    }
  );

  useEffect(
    function () {
      setValue("id", timesheetId);
    },
    [timesheetId]
  );

  return (
    <Modal
      title="View Timesheets"
      open={isOpen}
      onOk={form.submit}
      okText="Save"
      onCancel={() => handleCloseModal()}
      width={1000}
      centered
    >
      <FormProvider {...methods}>
        <Form
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          layout="vertical"
          initialValues={timesheet}
          onFinish={submit}
          form={form}
        >
          <div className="row mt-4">
            <div className="col-md-3">
              <Form.Item label="Start date" name="startDate">
                <Controller
                  control={control}
                  name="startDate"
                  render={({
                    field: { onChange, value, ref },
                    fieldState: { error },
                  }) => (
                    <DatePicker
                      format={dateFormat}
                      onChange={onChange}
                      className="w-100 p-12"
                    />
                  )}
                />
              </Form.Item>
            </div>
            <div className="col-md-3">
              <Form.Item label="End date" name="endDate">
                <Controller
                  control={control}
                  name="endDate"
                  render={({
                    field: { onChange, value, ref },
                    fieldState: { error },
                  }) => (
                    <DatePicker
                      format={dateFormat}
                      onChange={onChange}
                      className="w-100 p-12"
                    />
                  )}
                />
              </Form.Item>
            </div>
            <div className="col-md-6">
              <Form.Item label="Site Id" name="siteId">
                <Controller
                  control={control}
                  name="siteId"
                  render={({
                    field: { onChange, value, ref },
                    fieldState: { error },
                  }) => (
                    <Input
                      className="w-100"
                      value={value}
                      onChange={onChange}
                      placeholder="Enter site id"
                    />
                  )}
                />
              </Form.Item>
            </div>
            <div className="col-md-12">
              <div>
                <h6>Rates</h6>
                {rateFields.map((field, index) => (
                  <div className="row" key={index}>
                    <div className="col-md-3">
                      <Form.Item
                        label="Rate Code"
                        name={`rates.${index}.rateCode`}
                      >
                        <Controller
                          control={control}
                          name={`rates.${index}.rateCode`}
                          render={({
                            field: { onChange, value, ref },
                            fieldState: { error },
                          }) => (
                            <Select
                              value={value}
                              onChange={onChange}
                              className="w-100"
                            >
                              {rateCodeList.map((item) => {
                                return (
                                  <Select.Option
                                    className="w-100"
                                    key={item.id}
                                    value={item.value}
                                  >
                                    {item.label}
                                  </Select.Option>
                                );
                              })}
                            </Select>
                          )}
                        />
                      </Form.Item>
                    </div>
                    <div className="col-md-2">
                      <Form.Item
                        label="Rate Type"
                        name={`rates.${index}.rateType`}
                      >
                        <Controller
                          control={control}
                          name={`rates.${index}.rateType`}
                          render={({
                            field: { onChange, value, ref },
                            fieldState: { error },
                          }) => (
                            <Input
                              className="w-100"
                              value={value}
                              onChange={onChange}
                              placeholder="Enter rate type"
                            />
                          )}
                        />
                      </Form.Item>
                    </div>
                    <div className="col-md-2">
                      <Form.Item
                        label="Description"
                        name={`rates.${index}.description`}
                      >
                        <Controller
                          control={control}
                          name={`rates.${index}.description`}
                          render={({
                            field: { onChange, value, ref },
                            fieldState: { error },
                          }) => (
                            <Input
                              className="w-100"
                              value={value}
                              onChange={onChange}
                              placeholder="Enter description"
                              maxLength={5000}
                            />
                          )}
                        />
                      </Form.Item>
                    </div>
                    <div className="col-md-2">
                      <Form.Item label="Rate" name={`rates.${index}.rate`}>
                        <Controller
                          control={control}
                          name={`rates.${index}.rate`}
                          render={({
                            field: { onChange, value, ref },
                            fieldState: { error },
                          }) => (
                            <Input
                              className="w-100"
                              value={value}
                              onChange={onChange}
                              placeholder="Enter rate"
                            />
                          )}
                        />
                      </Form.Item>
                    </div>
                    <div className="col-md-2">
                      <Form.Item label="Units" name={`rates.${index}.units`}>
                        <Controller
                          control={control}
                          name={`rates.${index}.units`}
                          render={({
                            field: { onChange, value, ref },
                            fieldState: { error },
                          }) => (
                            <Input
                              className="w-100"
                              value={value}
                              onChange={onChange}
                              placeholder="Enter units"
                            />
                          )}
                        />
                      </Form.Item>
                    </div>
                    <div className="col-md-1 d-flex align-items-center justify-content-center">
                      <PlusCircleOutlined
                        onClick={() => {
                          addRate({});
                        }}
                        className="h5"
                      />
                      <MinusCircleOutlined
                        onClick={() => {
                          removeRate(index);
                        }}
                        className="h5 ml-2"
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Form>
      </FormProvider>
    </Modal>
  );
};

export default ViewTimesheetsModal;
