import { useState } from 'react';
import useConstant from 'use-constant';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { useAsync } from 'react-async-hook';

export const useDebouncedSearch = (searchFunction: (text: string) => Promise<any>) => {
  // Handle the input text state
  const [inputText, setInputText] = useState('');
  // Debounce the original search async function
  const debouncedSearchFunction = useConstant(() => AwesomeDebouncePromise(searchFunction, 300));

  // The async callback is run each time the text changes,
  // but as the search function is debounced, it does not
  // fire a new request on each keystroke
  const searchResults = useAsync(
    async () => {
      return debouncedSearchFunction(inputText);
    },
    [debouncedSearchFunction, inputText],
    { executeOnMount: true },
  );

  // Return everything needed for the hook consumer
  return {
    inputText,
    setInputText,
    searchResults,
  };
};
