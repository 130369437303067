import AdditionalInfoDetail from "@/components/Details/AdditionalInfoDetail";
import ContractInfoDetail from "@/components/Details/ContractInfoDetail";
import PaymentDetail from "@/components/Details/PaymentDetail";
import PersonalInfoDetail from "@/components/Details/PersonalInfoDetail"
import PositionInfoDetail from "@/components/Details/PositionInfoDetail";

const PayrollTab = (props: any): JSX.Element => {
    const {detail} = props;
    return (
        <>
            <PaymentDetail detail={detail}/>
        </>
    )
}

export default PayrollTab;