
import { dateFormat } from '@/common/constants/format';
import { useSelector } from '@/stores';
import { IEmployee } from '@/types/app';
import { matchValueFromSettingToLabel, matchValueToLabel } from '@/utils';
import { Card, Col, Row } from 'antd';
import dayjs from 'dayjs';
const OnboardingStatusDetail = (props: { detail?: IEmployee}) => {
  const { detail } = props;
  const { constants, settings } = useSelector((state) => state.app);

  return (
    <Card className="mb-3">
      <Row gutter={[16, 8]} className="general-information">
        <Col xs={24}>
          <p className="title">Onboarding Status</p>
        </Col>
        <Col xs={24}>
          <hr className="my-2" />
        </Col>
        <Col xs={8}>
          <p className="label">Status</p>
        </Col>
        <Col xs={16} className="value">
          {detail?.registrationStatus ? matchValueToLabel(constants?.registrationStatuses || [], detail?.registrationStatus) : <p className="none">none</p>}
        </Col>
        <Col xs={8}>
          <p className="label">Start Date</p>
        </Col>
        <Col xs={16} className="value">
          {detail?.startDate ? dayjs(detail?.startDate).format(dateFormat) : <p className="none">none</p>}
        </Col>
        <Col xs={8}>
          <p className="label">Payroll System</p>
        </Col>
        <Col xs={16} className="value">
        {detail?.payrollID ? matchValueFromSettingToLabel(settings?.payrolls || [], detail?.payrollID) : <p className="none">none</p>}
        </Col>

      </Row>
    </Card>
  );
};

export default OnboardingStatusDetail;
