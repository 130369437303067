import {
  IConstant,
  IConstants,
  IDocument,
  IEmployeeTimesheetsListTable,
  IResponsePagination,
} from "@/types/app";
import { Button, Form, Input, Space, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import { EyeTwoTone } from "@ant-design/icons";
import { useEffect, useState } from "react";
import ViewTimesheetsModal from "../Modals/ViewTimesheetsModal";
import {
  currencyFormat,
  dateFormat,
  pageSizeOptions,
} from "@/common/constants/format";
import TableWithScrollButtons from "../Shared/TableWithScrollButtons";
import { downloadFileFromURL, exportToXLSX, matchValueToLabel } from "@/utils";
import dayjs from "dayjs";
import { FileTwoTone, DownloadOutlined } from "@ant-design/icons";
import UploadDocumentModal from "../Modals/UploadDocumentModal";
import { useDispatch, useSelector } from "@/stores";
import { useParams } from "react-router-dom";
import { employeeActions } from "@/stores/employee";
import { SearchIcon } from "../common/IconComponents";
import { isEmployee } from "@/utils/auth";

interface IProps {
  data?: IResponsePagination<IDocument>;
  constants?: IConstants;
  onImport?: () => void;
  documentType?: string;
}

const EmployeeDocuments = (props: IProps) => {
  const { data, onImport, documentType } = props;

  const { constants } = useSelector((state) => state?.app);
  const { userInfo } = useSelector((state) => state.auth);
  const { uploadDocumentType } = useSelector((state) => state.employee);

  const { id } = useParams();

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const [isViewModalOpen, setIsViewModalOpen] = useState<boolean>(false);
  const [searchText, setSearchText] = useState('');

  const dispatch = useDispatch();

  const handleSearchSubmit = (e: string) => {
    const keyword = e.trim();
    setSearchText(keyword);
    // dispatch(userActions.getUsers({ keyword }));
  };

  const columns: ColumnsType<IDocument> = [
    {
      title: "File Name",
      dataIndex: ["attachment", "metadata", "name"],
      key: "fileName",
      align: "center",
    },
    {
      title: "Date Uploaded",
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      render: (val) => dayjs.unix(val).format(dateFormat),
    },
    // {
    //   title: "Document Type",
    //   dataIndex: "type",
    //   key: "type",
    //   align: "center",
    //   render: (val) => matchValueToLabel(constants?.documentTypes || [], val),
    // },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      align: "center",
      render: (val) => val,
    },
    {
      title: "Actions",
      key: "action",
      fixed: 'right',
      width: 120,
      align: "center",
      render: (_, record) => (
        <Space size="middle">
          <Tooltip title="Open Document">
            <Button
              htmlType="button"
              className="btn btn-icon d-flex align-items-center"
              onClick={() => {
                window.open(record?.attachment?.fileURL);
              }}
            >
              <FileTwoTone />
            </Button>
          </Tooltip>
          <Tooltip title="Download">
            <Button
              htmlType="button"
              className="btn btn-icon d-flex align-items-center"
              onClick={() => {
                downloadFileFromURL(
                  record?.attachment?.fileURL,
                  record?.attachment?.metadata?.name
                );
              }}
            >
              <DownloadOutlined />
            </Button>
          </Tooltip>
        </Space>
      ),
    },
  ];

  const onSelectChange = (
    newSelectedRowKeys: React.Key[],
    selectedRows: IEmployeeTimesheetsListTable[]
  ) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleOpenModal = (e: boolean) => {
    onImport && onImport();
  };

  // useEffect(() => {
  //   if (id)
  //     if (uploadDocumentType === "timesheet")
  //       dispatch(
  //         employeeActions.getEmployeeTimesheets({
  //           role: userInfo?.role,
  //           id,
  //         })
  //       );
  //     else if (uploadDocumentType === "payslip")
  //       dispatch(
  //         employeeActions.getEmployeePayslips({
  //           role: userInfo?.role,
  //           id,
  //         })
  //       );
  //     else if (uploadDocumentType === "others")
  //       dispatch(
  //         employeeActions.getEmployeeOthers({
  //           role: userInfo?.role,
  //           id,
  //         })
  //       );
  //     else
  //       dispatch(
  //         employeeActions.getEmployeeGuides({
  //           role: userInfo?.role,
  //           id,
  //         })
  //       );
  // }, [id, uploadDocumentType]);

  return (
    <div>
      <div className="d-flex justify-content-between p-2">
          <Input
            prefix={<SearchIcon className="mr-0" />}
            placeholder="Search"
            onPressEnter={(e: any) => handleSearchSubmit(e?.target?.value)}
            className="w-100 mr-2 page-listing-search-input"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
          {/* <Button onClick={() => handleExport()} type="primary">
            Export
          </Button>
          <Button onClick={() => handleExportAll()} type="primary">
            Export All
          </Button> */}
          {onImport && !isEmployee() && (
            <Button type="primary" onClick={() => handleOpenModal(true)}>
              Import
            </Button>
          )}
      </div>
      <div className="bg-white rounded">
        <div className="card-body">
          <div className="table-responsive">
            <TableWithScrollButtons
              columns={columns}
              dataSource={data?.data}
              size="small"
              rowSelection={rowSelection}
              rowKey={(record: IEmployeeTimesheetsListTable) => record.id || 'random'}
              scroll={{ x: 1200, y: 370 }}
              pagination={{
                showSizeChanger: true,
                defaultCurrent: 1,
                total: data?.pagination?.total,
                position: ['bottomLeft'],
                pageSizeOptions: pageSizeOptions,
                showTotal: (total: number, range: number[]) => 'Showing ' + range[0] + ' to ' + range[1] + ' of ' + total + ' entries',
              }}
            />
          </div>
        </div>
      </div>
      <ViewTimesheetsModal isOpen={isViewModalOpen} setIsOpen={(val: boolean) => setIsViewModalOpen(val)} />
    </div>
  );
};

export default EmployeeDocuments;
