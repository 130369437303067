import { IRejectAuthoriseExpenses } from "@/types/app";
import { Form, Input, Modal } from "antd";

const RejectAuthoriseExpensesModal = (props: any) => {
  const { expenseId, isOpen, setIsOpen } = props;
  const [form] = Form.useForm();

  const initialState: IRejectAuthoriseExpenses = {
    id: expenseId,
    rejectReason: "",
  };

  const handleCloseModal = () => {
    form.resetFields();
    setIsOpen(false);
  };

  const transformBeforeSubmit = (e: IRejectAuthoriseExpenses) => {
    return {
      ...e,
      id: expenseId,
    };
  };

  const handleSubmitForm = (e: IRejectAuthoriseExpenses) => {
    const result = transformBeforeSubmit(e);
    form.resetFields();
    handleCloseModal();
  };

  return (
    <Modal
      title="Reject Expense"
      open={isOpen}
      onOk={form.submit}
      okText="Save"
      onCancel={() => handleCloseModal()}
      centered
    >
      <Form
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        layout="vertical"
        initialValues={initialState}
        onFinish={handleSubmitForm}
        form={form}
      >
        <div className="row">
          <div className="col-md-12">
            <Form.Item
              label="Reason For Termination"
              name="rejectReason"
              rules={[{ required: true, message: "This field is required!" }]}
            >
              <Input.TextArea placeholder="Enter reason for reject" />
            </Form.Item>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default RejectAuthoriseExpensesModal;
