
import { IEmployee } from '@/types/app';
import { Card, Col, Row } from 'antd';
const AdditionalInfoDetail = (props: { detail?: IEmployee}) => {
  const {detail} = props;

  return (
    <Card>
      <Row gutter={[16, 8]} className="general-information">
        <Col xs={24}>
          <p className="title">Personal Info</p>
        </Col>
        <Col xs={24}>
          <hr className="my-2" />
        </Col>
        <Col xs={8}>
          <p className="label">Need Visa?</p>
        </Col>
        <Col xs={16} className="value">
          {detail?.needVisa ? 'Yes' : 'No' || <p className="none">none</p>}
        </Col>
        <Col xs={8}>
          <p className="label">Require Health Insurance?</p>
        </Col>
        <Col xs={16} className="value">
          {detail?.requireHealthInsurance ? 'Yes' : 'No' || <p className="none">none</p>}
        </Col>
        <Col xs={8}>
          <p className="label">Contractor Onboarding</p>
        </Col>
        <Col xs={16} className="value">
          {detail?.contractor || <p className="none">none</p>}
        </Col>
      </Row>
    </Card>
  );
};

export default AdditionalInfoDetail;
