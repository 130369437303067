import { useDispatch, useSelector } from "@/stores";
import { messageRoomActions } from "@/stores/messageRooms";
import { ISmtp } from "@/types/app";
import { constantsToOptions } from "@/utils";
import { Button, Col, Form, Input, Modal, ModalProps, Row, Select, Switch } from "antd";
import { forwardRef, useEffect, useImperativeHandle } from "react";

interface IProps {
  data?: ISmtp;
  onSubmit?: (data: ISmtp) => void;
}

const SmtpForm = (props: IProps, ref: any) => {
  const { onSubmit, data, ...modalProps } = props;

  const [form] = Form.useForm();

  useEffect(() => {
    if (data) {
      form.setFieldsValue(data);
    }
  }, [data]);

  useImperativeHandle(ref, () => {
    return {
      handleSubmit(e: any) {
        form.submit();
      },
    };
  });

  const handleSubmitForm = (e: ISmtp) => {
    let values: ISmtp = { ...e };
    if (data && data?.id && data?.id !== "") {
      values = { ...values, id: data?.id };
    }
    console.log("*** test handleSubmitForm values", values);
    onSubmit && onSubmit(values);
  };

  return (
    <Form
      colon={false}
      labelAlign="left"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      layout="horizontal"
      onFinish={handleSubmitForm}
      form={form}
    >
      {/* <div className="row">
        <div className="col-md-12">
          <Form.Item label="Sender Email" name="senderEmail">
            <Input className="w-100" placeholder="Enter sender email" />
          </Form.Item>
        </div>
      </div> */}
      <Row>
        <Col span={12}>
          <Form.Item label="Host" name="host" rules={[{ required: true, message: 'This field is required!' }]}>
            <Input className="w-100" placeholder="Enter host" required />
          </Form.Item>
        </Col>
      </Row>
      {/* <div className="row">
        <div className="col-md-12">
          <Form.Item label="Port" name="port">
            <Input className="w-100" placeholder="Enter port" />
          </Form.Item>
        </div>
      </div> */}
      <Row>
        <Col span={12}>
          <Form.Item label="Username" name="username" rules={[{ required: true, message: 'This field is required!' }]}>
            <Input className="w-100" placeholder="Enter username" required />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Form.Item label="Password" name="password" rules={[{ required: true, message: 'This field is required!' }]}>
            <Input.Password className="w-100" placeholder="Enter password" required />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Form.Item label="Secure" name="secure" valuePropName="checked">
            <Switch />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default forwardRef(SmtpForm);
