import api from '@/common/api';
import { EndpointsParams } from '@/common/api/endpoints';
import { SLICE_NAME } from '@/common/constants/stores';
import { CreateUserPermissionPayload, IPagination, IPaginationQuery, IUser, IUserResponse, SetUserPermissionPayload } from '@/types/app';
import { openNotification } from '@/utils';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export interface IUserPermissionState {
  data?: IUser[];
  loading: Record<string, boolean>;
  pagination: IPagination;
  detail?: IUser;
  notePagination?: IPagination;
}

export const initialState: IUserPermissionState = {
  pagination: {
    total: 0,
    page: 0,
    limit: 10,
    totalPage: 0,
  },
  data: [],
  loading: {},
};

const getUserPermissions = createAsyncThunk(
  `${SLICE_NAME.USER_PERMISSIONS}/getUserPermissions`,
  async (query: IPaginationQuery & EndpointsParams['getUserPermissions'], { rejectWithValue }) => {
    try {
      const data = await api.getUserPermissions<IUserResponse>(query);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const getUserPermission = createAsyncThunk(
  `${SLICE_NAME.USER_PERMISSIONS}/getUserPermission`,
  async (query: EndpointsParams['getUserPermission'], { rejectWithValue }) => {
    try {
      const data = await api.getUserPermission<{ data: IUser }>(query);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const createUserPermission = createAsyncThunk(
  `${SLICE_NAME.USER_PERMISSIONS}/createUserPermission`,
  async (form: CreateUserPermissionPayload & EndpointsParams['createUserPermission'], { rejectWithValue }) => {
    try {
      const data = await api.createUserPermission<{ data: IUser }>(form);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);
const setUserPermission = createAsyncThunk(
  `${SLICE_NAME.USER_PERMISSIONS}/setUserPermission`,
  async (form: SetUserPermissionPayload & EndpointsParams['setUserPermission'], { rejectWithValue }) => {
    try {
      const data = await api.setUserPermission<{ data: IUser }>(form);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const deleteUserPermission = createAsyncThunk(
  `${SLICE_NAME.USER_PERMISSIONS}/deleteUserPermission`,
  async (query: EndpointsParams['deleteUserPermission'], { rejectWithValue }) => {
    try {
      const data = await api.deleteUserPermission<{ data: IUser }>(query);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const mapFromResponse = (response: IUser) => {
  return {
    ...response,
  };
};

const userPermissionSlice = createSlice({
  name: SLICE_NAME.USER_PERMISSIONS,
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUserPermissions.fulfilled, (state, { payload }) => {
      state.loading[getUserPermissions.typePrefix] = false;
      state.data = payload.data.data.map((item) => mapFromResponse(item));
      state.pagination = payload.data.pagination;
    });
    builder.addCase(getUserPermissions.pending, (state, { payload }) => {
      state.loading[getUserPermissions.typePrefix] = true;
    });
    builder.addCase(getUserPermissions.rejected, (state, { payload }) => {
      state.loading[getUserPermissions.typePrefix] = false;
    });
    builder.addCase(getUserPermission.fulfilled, (state, { payload }) => {
      state.loading[getUserPermission.typePrefix] = false;
      state.detail = mapFromResponse(payload.data.data);
    });
    builder.addCase(getUserPermission.pending, (state, { payload }) => {
      state.loading[getUserPermission.typePrefix] = true;
    });
    builder.addCase(getUserPermission.rejected, (state, { payload }) => {
      state.loading[getUserPermission.typePrefix] = false;
    });
    builder.addCase(createUserPermission.fulfilled, (state, { payload }) => {
      state.loading[createUserPermission.typePrefix] = false;
      openNotification('success', `Create ${payload.data.data?.name} successfully!`);
    });
    builder.addCase(createUserPermission.pending, (state, { payload }) => {
      state.loading[createUserPermission.typePrefix] = true;
    });
    builder.addCase(createUserPermission.rejected, (state, { payload }) => {
      state.loading[createUserPermission.typePrefix] = false;
    });
    builder.addCase(setUserPermission.fulfilled, (state, { payload }) => {
      state.loading[setUserPermission.typePrefix] = false;
      openNotification('success', `Update permission for ${payload.data.data?.name} successfully!`);
    });
    builder.addCase(setUserPermission.pending, (state, { payload }) => {
      state.loading[setUserPermission.typePrefix] = true;
    });
    builder.addCase(setUserPermission.rejected, (state, { payload }) => {
      state.loading[setUserPermission.typePrefix] = false;
    });
    builder.addCase(deleteUserPermission.fulfilled, (state, { payload }) => {
      state.loading[deleteUserPermission.typePrefix] = false;
      openNotification('success', `${payload.data.data?.name} deleted successfully!`);
    });
    builder.addCase(deleteUserPermission.pending, (state, { payload }) => {
      state.loading[deleteUserPermission.typePrefix] = true;
    });
    builder.addCase(deleteUserPermission.rejected, (state, { payload }) => {
      state.loading[deleteUserPermission.typePrefix] = false;
    });
  },
});

export const userPermissionReducer = userPermissionSlice.reducer;
export const userPermissionCaseReducers = userPermissionSlice.caseReducers;
export const userPermissionActions = {
  ...userPermissionSlice.actions,
  getUserPermissions,
  getUserPermission,
  createUserPermission,
  setUserPermission,
  deleteUserPermission,
};
